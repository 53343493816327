import lodash from "lodash";

export class RoundEditorOptions {
    private autoUpdateHand = true;

    copy() {
        return lodash.cloneDeep(this);
    }

    isAutoUpdateHand() {
        return this.autoUpdateHand;
    }

    setAutoUpdateHand(autoUpdate: boolean) {
        this.autoUpdateHand = autoUpdate;
        return this;
    }
}
