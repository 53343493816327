import { Game } from "../games/models/Game";
import { Player } from "../games/models/Player";
import { AverageHandValueCalculator } from "../games/parser/AverageHandValueCalculator";
import { GameParser } from "../games/parser/GameParser";
import { checkNotNull } from "../util/Nullable";
import { GamePlayerStat } from "./GamePlayerStat";

export class AverageHandValueStat extends GamePlayerStat {
    getLabel() {
        return "Average Hand Value";
    }

    getId() {
        return "average-hand-value";
    }

    requiresSeat() {
        return false;
    }

    getStatForGames(games: Game[], player: Player) {
        const results = games.map(game =>
            GameParser.parse(
                game,
                new AverageHandValueCalculator(
                    checkNotNull(game.getSeatForPlayer(player)),
                ),
            ),
        );

        const count = results.map(s => s.count).reduce(this.sumReducer(), 0);
        const total = results
            .map(s => s.totalPoints)
            .reduce(this.sumReducer(), 0);

        return this.createAverageStatistic(total, count);
    }
}
