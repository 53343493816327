export class PathBuilder {
    private segments: string[] = [];

    start(x: number, y: number) {
        this.segments.push(`M ${x} ${y}`);
    }

    to(x: number, y: number) {
        this.segments.push(`L ${x} ${y}`);
    }

    create() {
        return this.segments.join(" ");
    }
}
