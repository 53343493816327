import { equals } from "./Assert";

export class StringHelper {
    static create(klass: any) {
        equals(typeof klass.name, "string");
        return new StringHelper(klass.name);
    }

    private values: {
        field: string;
        value: string | number | boolean | Date;
    }[] = [];
    constructor(private name: string) {}

    addField(field: string, value: string | number | boolean | Date) {
        this.values.push({ field, value });
        return this;
    }

    fieldString() {
        return this.values
            .map((v) => `${v.field}: ${JSON.stringify(v.value)}`)
            .join(", ");
    }

    toString() {
        return `${this.name} { ${this.fieldString()} }`;
    }
}
