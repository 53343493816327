import { Game } from "../games/models/Game";
import { Player } from "../games/models/Player";
import { GameParser } from "../games/parser/GameParser";
import { ParticipationCalculator } from "../games/parser/ParticipationCalculator";
import { checkNotNull } from "../util/Nullable";
import { AbstractRoundPercentStat } from "./AbstractRoundPercentStat";

export class ParticipationStat extends AbstractRoundPercentStat {
    getLabel() {
        return "Participation Rate";
    }

    getId() {
        return "participation-rate";
    }

    getCount(game: Game, player: Player) {
        return GameParser.parse(
            game,
            new ParticipationCalculator(
                checkNotNull(game.getSeatForPlayer(player)),
            ),
        ).getCount();
    }
}
