import React from "react";
import { Loader } from "../components/Loader";
import { Dashboard } from "../dashboard/Dashboard";
import { usePlayers } from "../player/Players";
import { useSeasonGames } from "../seasons/SeasonsContext";

export function DashboardPage() {
    const [season, games] = useSeasonGames(null);
    const players = usePlayers();

    if (games == null || season == null || players == null) {
        return <Loader />;
    }

    return (
        <div className="dashboard">
            <h1>Dashboard</h1>
            <Dashboard games={games} players={players} season={season} />
        </div>
    );
}
