import React from "react";
import { Colors } from "./Colors";
import { PathBuilder } from "./PathBuilder";
import { Scale } from "./Scale";
import { SeriesData } from "./SeriesData";

const CIRCLE_RADIUS = 3;
const LARGE_CIRCLE_RADIUS = 5;

export class LineChartLine extends React.Component<{
    series: SeriesData;
    index: number;
    scale: Scale;
    xScale: Scale;
    highlightIndex?: number | null;
    initalValue: number;
    showPoints: boolean;
}> {
    createPath(series: SeriesData) {
        const builder = new PathBuilder();
        let i = 0;
        builder.start(i, this.valueToY(this.props.initalValue));
        series.getValues().forEach((v) => {
            i++;
            builder.to(this.xPosition(i), this.valueToY(v));
        });
        return builder.create();
    }

    valueToY(value: number) {
        return this.props.scale.scalePosition(value);
    }

    xPosition(index: number) {
        return this.props.xScale.scale(index);
    }

    renderCircle(value: number, index: number) {
        return (
            <circle
                key={index + 1}
                fill={Colors.forIndex(this.props.index)}
                cx={this.xPosition(index + 1)}
                cy={this.valueToY(value)}
                r={
                    this.props.highlightIndex === index + 1
                        ? LARGE_CIRCLE_RADIUS
                        : CIRCLE_RADIUS
                }
            />
        );
    }

    renderText() {
        const values = this.props.series.getValues();
        const lastValue = values[values.length - 1] || this.props.initalValue;
        return (
            <text
                x={this.xPosition(this.props.series.getValues().length)}
                y={this.valueToY(lastValue)}
                dy={3}
                dx={5}
                fill={Colors.forIndex(this.props.index)}
            >
                {this.props.series.getLabel()}
            </text>
        );
    }

    render() {
        const stroke = Colors.forIndex(this.props.index);
        return (
            <g>
                <path
                    className="line-chart__line"
                    key={this.props.series.getLabel()}
                    d={this.createPath(this.props.series)}
                    stroke={stroke}
                />
                {this.props.showPoints &&
                    this.props.series
                        .getValues()
                        .map((value, index) => this.renderCircle(value, index))}
                {this.renderText()}
            </g>
        );
    }
}
