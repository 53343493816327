import React from "react";
import { PageTitle } from "../../common/PageTitle";
const data = { __html: require("../rules.md") };

export function RulesPage() {
    return (
        <div className="rulesPage">
            <PageTitle>Rules</PageTitle>
            <div dangerouslySetInnerHTML={data} />
        </div>
    );
}
