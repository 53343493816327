import { ScoringMethod } from "../seasons/ScoringMethod";
import { Season } from "../seasons/Season";
import { Game } from "./models/Game";
import { GamePlayer } from "./models/GamePlayer";
import { GameParser } from "./parser/GameParser";
import { LastRoundCalculator } from "./parser/LastRoundCalculator";
import { RoundGroupingCalculator } from "./parser/RoundGroupingCalculator";

export class GameInfoService {
    formatFinalScore(season: Season, game: Game, player: GamePlayer) {
        const scoringMethod = ScoringMethod.getById(season.getScoringMethod());
        return scoringMethod.formatScore(game, player, season);
    }

    calculateRounds(game: Game) {
        return GameParser.parse(game, new RoundGroupingCalculator());
    }

    calculateLastRound(game: Game) {
        return GameParser.parse(game, new LastRoundCalculator());
    }
}
