import { RoundType } from "../../round-editor/models/RoundType";
import { PaymentSignType } from "../models/PaymentSign";
import { Round } from "../models/Round";
import { RoundWindCalculator } from "./RoundWindCalculator";

export class HonbaCalculator extends RoundWindCalculator {
    private honba = 0;

    constructor() {
        super(null);
    }

    onRound(round: Round, type: RoundType) {
        if (type.addsHonba()) {
            if (
                round
                    .getPaymentBySeat(this.getSeatWind().getSeat())
                    .getPaymentSign() === PaymentSignType.GAIN
            ) {
                this.honba++;
            } else {
                this.honba = 0;
            }
        }
        super.onRound(round, type);
    }

    getHonba() {
        return this.honba;
    }
}
