import React from "react";
import { Game } from "../../games/models/Game";
import { Round } from "../../games/models/Round";
import { Seat, Seats } from "../../games/models/Seat";
import { GameCalculatorService } from "../../games/parser/GameCalculatorService";
import { PlayerList } from "../../games/PlayerList";
import { Callback } from "../../util/Callback";
import { RiichiButton } from "./RiichiButton";

export class HandTableFooter extends React.Component<{
    players: PlayerList;
    game: Game;
    onRiichi: Callback<Round>;
}> {
    private gameCalculatorService = new GameCalculatorService();

    getPlayerName(seat: Seat) {
        return this.props.players
            .getById(this.props.game.getPlayerBySeat(seat).playerId)
            .getName();
    }

    getPointsForPlayer(seat: Seat) {
        return this.gameCalculatorService.getPlayerPoints(
            this.props.game,
            seat,
        );
    }

    getTotalPoints() {
        return this.gameCalculatorService.getTotalPoints(this.props.game);
    }

    getCurrentRoundWind() {
        const roundWind = this.gameCalculatorService.getRoundWind(
            this.props.game,
            null,
        );
        const seatWind = this.gameCalculatorService.getSeatWind(
            this.props.game,
            null,
        );
        return `${roundWind.getCharacter()} ${seatWind.getNumber()}`;
    }

    getSeats() {
        return Seats.all();
    }

    doRiichi(seat: Seat) {
        this.props.onRiichi(Round.createRiichi(seat));
    }

    render() {
        return (
            <tfoot>
                <tr>
                    <th>{this.getCurrentRoundWind()}</th>
                    {this.getSeats().map((s) => (
                        <th key={s}>{this.getPlayerName(s)}</th>
                    ))}
                    <th />
                </tr>
                <tr>
                    <th />
                    {this.getSeats().map((s) => (
                        <th key={s} data-seat={s}>
                            <RiichiButton onRiichi={() => this.doRiichi(s)} />
                        </th>
                    ))}
                    <th />
                </tr>
                <tr>
                    <th />
                    {this.getSeats().map((s) => (
                        <th key={s}>{this.getPointsForPlayer(s)}</th>
                    ))}
                    <th>{this.getTotalPoints()}</th>
                </tr>
            </tfoot>
        );
    }
}
