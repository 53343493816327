import { isNotNull } from "../util/Nullable";
import { Rule, ruleFromID } from "./Rule";
import {
    AVERAGE,
    ScoringMethod,
    ScoringMethods,
    STANDARD,
} from "./ScoringMethod";

export class Season {
    static OVERALL_ID = 0;

    static createOverall() {
        return Season.create()
            .setId(Season.OVERALL_ID)
            .setName("Overall")
            .setScoringMethod(AVERAGE.getId());
    }

    static create() {
        return new Season(null, "", STANDARD.getId(), 10, 5, -5, -10, []);
    }

    constructor(
        readonly id: number | null,
        readonly name: string,
        readonly scoring_method: number,
        readonly first_bonus: number,
        readonly second_bonus: number,
        readonly third_bonus: number,
        readonly fourth_bonus: number,
        readonly rules: ReadonlyArray<string>,
    ) {}

    private copy(season: Partial<Season>): Season {
        return Object.assign(Season.create(), this, season);
    }

    getId() {
        return this.id;
    }

    setId(id: number | null) {
        return this.copy({ id });
    }

    getName() {
        return this.name;
    }

    setName(name: string) {
        return this.copy({ name });
    }

    getScoringMethod() {
        return this.scoring_method;
    }

    getScoringMethodType(): ScoringMethod {
        const found = ScoringMethods.find(
            (m) => m.getId() == this.scoring_method,
        );
        if (found == null) {
            throw new Error(`Unknown scoring method ${this.scoring_method}`);
        }
        return found;
    }

    setScoringMethod(scoringMethod: number) {
        return this.copy({ scoring_method: scoringMethod });
    }

    setFirstBonus(bonus: number) {
        return this.copy({ first_bonus: bonus });
    }

    getFirstBonus() {
        return this.first_bonus;
    }

    getSecondBonus() {
        return this.second_bonus;
    }

    setSecondBonus(bonus: number) {
        return this.copy({ second_bonus: bonus });
    }

    getThirdBonus() {
        return this.third_bonus;
    }

    setThirdBonus(bonus: number) {
        return this.copy({ third_bonus: bonus });
    }

    getFourthBonus() {
        return this.fourth_bonus;
    }

    setFourthBonus(bonus: number) {
        return this.copy({ fourth_bonus: bonus });
    }

    getBonuses() {
        return [
            this.first_bonus,
            this.second_bonus,
            this.third_bonus,
            this.fourth_bonus,
        ];
    }

    getRules(): Rule[] {
        return this.rules.map(ruleFromID).filter(isNotNull);
    }

    hasRule(rule: Rule): boolean {
        return this.rules.includes(rule);
    }

    addRule(rule: Rule): Season {
        const newRules: string[] = Array.from(new Set([...this.rules, rule]));
        return this.copy({ rules: newRules });
    }

    removeRule(rule: Rule): Season {
        return this.copy({ rules: this.rules.filter((r) => r != rule) });
    }
}
