import classnames from "classnames";
import React from "react";
import { PlayerRanking } from "../games/PlayerRanking";
import { Season } from "../seasons/Season";
import { checkNotNull } from "../util/Nullable";

export function RankingTable({
    rankings,
    season,
    large,
}: {
    rankings: PlayerRanking[];
    season: Season;
    large?: boolean;
}) {
    return (
        <div
            className={classnames("container", "ranking-table", {
                "ranking-table--large": large,
            })}
        >
            <h1>{season.getName()}</h1>
            <table className="table">
                <thead>
                    <tr>
                        <th>Player</th>
                        <th>Score</th>
                        <th>Games Played</th>
                    </tr>
                </thead>
                <tbody>
                    {rankings.map((ranking) => (
                        <tr key={checkNotNull(ranking.getPlayer().getId())}>
                            <td>{ranking.getPlayer().getName()}</td>
                            <td>{ranking.getScore()}</td>
                            <td>{ranking.getCount()}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}
