import lodash from "lodash";
import { GroupedData } from "./GroupedData";

export class GroupDimensions {
    static createDefault() {
        return new GroupDimensions(40, 20, 10);
    }

    private labelAreaHeight: number = 30;

    constructor(
        private margin: number,
        private barWidth: number,
        private barMargin: number,
    ) {}

    getMargin() {
        return this.margin;
    }

    getBarWidth() {
        return this.barWidth;
    }

    getBarMargin() {
        return this.barMargin;
    }

    getLabelAreaHeight() {
        return this.labelAreaHeight;
    }

    getBarsWidth(count: number) {
        return Math.max(
            (this.barWidth + this.barMargin) * count - this.barMargin,
            0,
        );
    }

    getX(index: number) {
        return this.margin + (this.barWidth + this.barMargin) * index;
    }

    getCenterX(count: number) {
        return (
            (this.margin * 2 +
                this.barWidth * count +
                this.barMargin * (count - 1)) /
            2
        );
    }

    getGroupX(groupedData: GroupedData, index: number) {
        return lodash(groupedData.getGroups())
            .slice(0, index)
            .map(g => this.getX(g.getLabels().length))
            .sum();
    }
}
