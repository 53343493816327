import React from "react";

interface NumberInputProps {
    value: number;
    label: string;
    onChange: (newValue: number) => void;
}

export class NumberInput extends React.Component<
    NumberInputProps,
    { value: string }
> {
    constructor(props: NumberInputProps) {
        super(props);

        this.state = {
            value: String(props.value),
        };
    }

    componentDidUpdate(previousProps: NumberInputProps) {
        if (previousProps.value != this.props.value) {
            this.setState({ value: String(this.props.value) });
        }
    }

    onChange(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();
        this.setState({ value: e.target.value });
    }

    onBlur(e: React.FocusEvent<HTMLInputElement>) {
        const value = parseInt(this.state.value, 10);
        if (isNaN(value)) {
            this.setState({ value: String(this.props.value) });
        } else {
            this.props.onChange(value);
        }
    }

    render() {
        return (
            <div className="number-input">
                <label htmlFor="gen" className="number-input__label">
                    {this.props.label}
                </label>
                <input
                    type="number"
                    className="number-input__input"
                    value={this.state.value}
                    onChange={e => this.onChange(e)}
                    onBlur={e => this.onBlur(e)}
                />
            </div>
        );
    }
}
"";
