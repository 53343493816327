import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Loader } from "../../components/Loader";
import { Season } from "../../seasons/Season";
import {
    useGamesService,
    usePlayerService,
    useSeasonService,
} from "../../services/ServiceContext";
import { GameInfoPanel } from "../components/GameInfoPanel";
import { GameScorePanel } from "../components/GameScorePanel";
import { RoundDetails } from "../components/RoundDetails";
import { Game } from "../models/Game";
import { PlayerList } from "../PlayerList";

export function ViewGamePage() {
    const { id } = useParams<{ id: string | undefined }>();
    if (id == null) {
        return <div>No Game ID</div>;
    }

    const [game, setGame] = useState<Game | null>(null);
    const [players, setPlayers] = useState<PlayerList | null>(null);
    const [season, setSeason] = useState<Season | null>(null);

    const gamesService = useGamesService();
    const playersService = usePlayerService();
    const seasonService = useSeasonService();

    useEffect(() => {
        playersService.list().then(setPlayers);
        gamesService.get(id).then((game) => {
            setGame(game);
            seasonService.get(game?.getSeasonId()).then(setSeason);
        });
    }, [id]);

    if (game == null || players == null || season == null) {
        return <Loader></Loader>;
    }

    return (
        <div className="view-game">
            <GameInfoPanel game={game} />
            <GameScorePanel game={game} players={players} season={season} />
            <RoundDetails game={game} players={players} />
        </div>
    );
}
