import React from "react";
import { useQuery } from "../../app/QueryString";
import { Loader } from "../../components/Loader";
import { usePlayers } from "../../player/Players";
import { useSeasonGames, useSeasons } from "../../seasons/SeasonsContext";
import { parseOptional } from "../../util/Numbers";
import { GamesList } from "../components/GamesList";

export function GamesListPage() {
    const match = useQuery<{ season?: string; page?: string }>();

    const seasonID = parseOptional(String(match.season));
    const page = parseOptional(String(match.page)) || 1;
    const seasons = useSeasons();
    const players = usePlayers();

    const [season, games] = useSeasonGames(seasonID);

    if (games == null || season == null || seasons == null || players == null) {
        return <Loader />;
    }

    return (
        <GamesList
            games={games}
            currentSeason={season}
            seasons={seasons}
            pageNumber={page}
            players={players}
        />
    );
}
