import React, { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router";
import { TextInput } from "../../common/controls/TextInput";
import { PageTitle } from "../../common/PageTitle";
import { Panel } from "../../common/Panel";
import { Button } from "../../components/Button";
import { Player } from "../../games/models/Player";
import { usePlayerService } from "../../services/ServiceContext";

export function EditPlayerPage() {
    const [player, setPlayer] = useState<Player | null>(null);
    const [updated, setUpdated] = useState(false);
    const playerService = usePlayerService();

    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        playerService.get(parseInt(id, 10)).then(setPlayer);
    }, [id]);

    async function save() {
        if (player == null) {
            return;
        }
        await playerService.update(player);
        setUpdated(true);
    }

    if (!player) {
        return null;
    }
    if (updated) {
        return <Redirect to="/players" />;
    }
    return (
        <div className="edit-player-page">
            <PageTitle>Edit Player {player.getName()}</PageTitle>
            <Panel title="Edit Player" icon="fa-user">
                <div className="form">
                    <TextInput
                        value={player.getName()}
                        label="Name"
                        onChange={(n) => setPlayer(player.setName(n))}
                    />
                    <Button onClick={() => save()}>Save</Button>
                </div>
            </Panel>
        </div>
    );
}
