import { assert } from "../../util/Assert";

export enum PaymentSignType {
    GAIN = 1,
    LOSS = -1,
    NEUTRAL = 0,
}

export type PaymentSigns = [
    PaymentSignType,
    PaymentSignType,
    PaymentSignType,
    PaymentSignType,
];

export class PaymentSign {
    static isGain(sign: PaymentSignType) {
        return sign === PaymentSignType.GAIN;
    }

    static isLoss(sign: PaymentSignType) {
        return sign === PaymentSignType.LOSS;
    }

    static assertSign(sign: number) {
        assert(
            PaymentSign.values().indexOf(sign) > -1,
            `Invalid payment sign ${sign}`,
        );
    }

    static assertList(signs: number[]) {
        assert(
            Array.isArray(signs) && signs.length === 4,
            `Expected a list of payment signs but got ${JSON.stringify(signs)}`,
        );
        signs.forEach(PaymentSign.assertSign);
    }

    static values() {
        return [
            PaymentSignType.GAIN,
            PaymentSignType.LOSS,
            PaymentSignType.NEUTRAL,
        ];
    }
}
