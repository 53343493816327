import React from "react";
import { PlayerList } from "../../../games/PlayerList";
import { Game } from "../../../games/models/Game";
import { Seat, Seats } from "../../../games/models/Seat";
import { Rule } from "../../../seasons/Rule";
import { Season } from "../../../seasons/Season";
import { Callback } from "../../../util/Callback";
import { FinalResults } from "../../FinalResults";
import { FinalScoreInput } from "./FinalScoreInput";
import { FinalTotalScore } from "./FinalTotalScore";
import { GameCompleteSelector } from "./GameCompleteSelector";

export function GameFinalizer({
    finalResults,
    game,
    season,
    players,
    onUpdateFinalResults,
    onFinish,
    onSetDraft,
}: {
    finalResults: FinalResults;
    game: Game;
    season: Season;
    players: PlayerList;
    onUpdateFinalResults: (finalResults: FinalResults) => void;
    onFinish: () => void;
    onSetDraft: Callback<boolean>;
}) {
    function getPlayerNumbers() {
        return Seats.all();
    }

    function getGamePlayer(seat: Seat) {
        return game.getPlayerBySeat(seat);
    }

    function getPlayerName(seat: Seat) {
        return players.getById(getGamePlayer(seat).playerId).getName();
    }

    function onChangePlacement(seat: Seat, placement: number) {
        onUpdateFinalResults(finalResults.setPlacement(seat, placement));
    }

    function onChangeScore(seat: Seat, score: number) {
        onUpdateFinalResults(
            finalResults
                .setScore(seat, score)
                .computePlacementsFromScore(season.hasRule(Rule.SharedTies)),
        );
    }

    return (
        <div>
            <div className="row">
                {getPlayerNumbers().map((seat) => (
                    <div key={seat} className="col-sm-3" data-seat={seat}>
                        <label className="control-label">
                            {getPlayerName(seat)}
                        </label>
                        <FinalScoreInput
                            key={`${finalResults.getScoreBySeat(seat)}`}
                            onChangePlacement={(placement) =>
                                onChangePlacement(seat, placement)
                            }
                            onChangeScore={(score) =>
                                onChangeScore(seat, score)
                            }
                            score={finalResults.getScoreBySeat(seat)}
                            placement={finalResults.getPlacementBySeat(seat)}
                        />
                    </div>
                ))}
                <div className="col-sm-12">
                    <FinalTotalScore finalResults={finalResults} />
                </div>
                <div className="col-sm-6">
                    <GameCompleteSelector
                        onSetDraft={onSetDraft}
                        finalResults={finalResults}
                    />
                </div>
                <div className="col-sm-6">
                    <div className="pull-right">
                        <button
                            className="btn btn-primary finish-game"
                            onClick={onFinish}
                        >
                            Finish
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
