import { Game } from "../games/models/Game";
import { Sort } from "../games/Sort";
import { checkNotNull } from "../util/Nullable";

export class SeasonGameList {
    private games: Game[];
    constructor(games: Game[]) {
        this.games = games.sort(Sort.byID);
    }

    inOrder() {
        return this.games;
    }

    getCount(filter?: (game: Game) => boolean) {
        return this.games.filter(filter || (() => true)).length;
    }

    getLatestPage(pageNumber: number, filter?: (game: Game) => boolean) {
        return this.games
            .filter(filter || (() => true))
            .reverse()
            .slice(pageNumber * 10 - 10, pageNumber * 10);
    }

    getGameNumber(game: Game): string {
        const index = this.games.indexOf(game);
        if (index < 0) {
            return "-";
        }
        return String(index + 1);
    }

    byGameNumber(game: number) {
        return checkNotNull(this.games[game - 1]);
    }

    slice(includingGameNumber: number) {
        return this.games.slice(0, includingGameNumber);
    }
}
