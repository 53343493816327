import { Statistic } from "../stats/Statistic";
import { assert } from "../util/Assert";
import { checkNotNull } from "../util/Nullable";

export class StatisticCollection {
    stats = new Map<string, Statistic>();

    add(stat: Statistic) {
        assert(stat instanceof Statistic);
        this.stats.set(stat.getId(), stat);
        return this;
    }

    getById(id: string): Statistic {
        assert(
            this.stats.has(id),
            `No stat with id ${id} valid options are ${Array.from(
                this.stats.keys(),
            )}`,
        );
        return checkNotNull(this.stats.get(id));
    }
}
