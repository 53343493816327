import classnames from "classnames";
import React from "react";
import { Rotation } from "../../games/models/Rotation";
import { Seat } from "../../games/models/Seat";

export class RotationButton extends React.Component<{
    seat: Seat;
    rotation: number;
    onClick: () => void;
}> {
    render() {
        return (
            <button
                tabIndex={-1}
                className={classnames("btn btn-default rotation-button", {
                    active:
                        this.props.seat ===
                        Rotation.rotationToSeat(this.props.rotation),
                })}
                onClick={this.props.onClick}
            >
                <span className="fa fa-compass" />
            </button>
        );
    }
}
