import { Game } from "../games/models/Game";
import { Player } from "../games/models/Player";
import { assert } from "../util/Assert";
import { AverageStatistic } from "./AverageStatistic";
import { DurationStatistic } from "./DurationStatistic";
import { NumberStatistic } from "./NumberStatistic";
import { PercentStatistic } from "./PercentStatistic";
import { Statistic } from "./Statistic";

export abstract class GamePlayerStat {
    abstract getLabel(): string;

    abstract getId(): string;

    isSingleValue() {
        return true;
    }

    requiresSeat() {
        return true;
    }

    getStat(games: Game[], player: Player) {
        return this.getStatForGames(
            this.filterHasPlayer(games, player),
            player,
        );
    }

    abstract getStatForGames(games: Game[], player: Player): Statistic;

    filterHasPlayer(games: Game[], player: Player) {
        assert(player instanceof Player);

        return games
            .filter((g) => g.hasPlayer(player))
            .filter(
                (g) =>
                    !this.requiresSeat() || g.getSeatForPlayer(player) != null,
            );
    }

    createStatistic(value: number): NumberStatistic {
        return NumberStatistic.create(this.getLabel(), this.getId(), value);
    }

    createPercentStatistic(numerator: number, denominator: number) {
        return PercentStatistic.createPercent(
            this.getLabel(),
            this.getId(),
            numerator,
            denominator,
        );
    }

    createAverageStatistic(numerator: number, denominator: number) {
        return AverageStatistic.createAverage(
            this.getLabel(),
            this.getId(),
            numerator,
            denominator,
        );
    }

    createDurationStatistic(seconds: number) {
        assert(!Number.isNaN(seconds));
        return DurationStatistic.create(this.getLabel(), this.getId(), seconds);
    }

    sumReducer() {
        return (a: number, b: number) => a + b;
    }
}
