import { Validation } from "io-ts";
import { ThrowReporter } from "io-ts/lib/ThrowReporter";

export default class Validate {
    static validate<A>(validation: Validation<A>): A {
        return validation.getOrElseL(_ => {
            throw ThrowReporter.report(validation);
        });
    }
}
