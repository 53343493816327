import React from "react";
import { checkNotNull } from "../util/Nullable";
import { Season } from "./Season";
import { SeasonLink } from "./SeasonLink";

export class SeasonSelector extends React.Component<{
    season: Season;
    seasons: Season[];
}> {
    isCurrent(season: Season) {
        return season.getId() === this.props.season.getId();
    }

    render() {
        return (
            <div className="season-selector">
                {this.props.seasons.map(s => (
                    <div
                        className="season-selector__link"
                        key={checkNotNull(s.getId())}
                    >
                        <SeasonLink season={s} isCurrent={this.isCurrent(s)} />
                    </div>
                ))}
            </div>
        );
    }
}
