import React from "react";

export class RiichiButton extends React.Component<{ onRiichi: () => void }> {
    onClick(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        this.props.onRiichi();
    }

    render() {
        return (
            <button
                className="btn btn-xs btn-default riichi-button"
                onClick={e => this.onClick(e)}
            >
                Riichi
            </button>
        );
    }
}
