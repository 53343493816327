import { Game } from "../games/models/Game";
import { Player } from "../games/models/Player";
import { AbstractGamePercentStat } from "./AbstractGamePercentStat";

export class DeathStat extends AbstractGamePercentStat {
    getLabel() {
        return "Deaths";
    }

    getId() {
        return "deaths";
    }

    getCount(games: Game[], player: Player) {
        return games.filter((g) => this.ranOutOfPoints(player, g)).length;
    }

    ranOutOfPoints(player: Player, game: Game) {
        return game.getGamePlayer(player).score < 0;
    }
}
