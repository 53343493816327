import React, { useContext, useEffect, useState } from "react";
import { AuthService, User } from "../http/AuthService";

export interface Auth {
    user: User | null;
    reload(): Promise<void>;
}

export const AuthContext = React.createContext<Auth>({
    user: null,
    async reload() {},
});

export function AppAuth({
    authService,
    children,
}: {
    authService: AuthService;
    children: React.ReactNode;
}) {
    const [user, setUser] = useState<User | null>(null);

    async function update() {
        authService.current().then(setUser);
    }

    useEffect(() => {
        update();
    }, []);

    return (
        <AuthContext.Provider
            value={{
                user: user,
                reload: update,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}

export function AuthRequired({
    children,
}: {
    children: React.ReactNode;
}): React.ReactElement | null {
    const context = useContext(AuthContext);

    if (context.user != null) {
        return <>{children}</>;
    } else {
        return null;
    }
}

export function GuestRequired({ children }: { children: React.ReactNode }) {
    const context = useContext(AuthContext);

    if (context.user == null) {
        return <>{children}</>;
    } else {
        return null;
    }
}
