import { AuthService } from "../http/AuthService";
import { GamesService } from "../http/GamesService";
import { HttpService } from "../http/HttpService";
import { PlayerService } from "../http/PlayerService";
import { SeasonService } from "../http/SeasonService";

export class Services {
    private httpService = new HttpService();

    gameService = new GamesService(this.httpService);
    playerService = new PlayerService(this.httpService);
    authService = new AuthService(this.httpService);
    seasonService = new SeasonService(this.httpService);
}
