import React from "react";
import { Round } from "../../../games/models/Round";
import { Callback } from "../../../util/Callback";
import { RoundTypes } from "../../models/RoundType";
import { ButtonGroupSelector } from "./ButtonGroupSelector";

const MAIN_RIICHI_OPTIONS = [0, 1, 2, 3, 4];
const SECONDARY_RIICHI_OPTIONS = [5, 6, 7, 8, 9];

export function RiichiSelector({
    round,
    onSelectRiichi,
}: {
    round: Round;
    onSelectRiichi: Callback<number>;
}) {
    function getRiichi() {
        return round.getRiichi();
    }

    function canChangePoints() {
        return RoundTypes.getRoundByType(round.getType()).canChangePoints();
    }

    return (
        <div className="form-group riichi-selector">
            <div>
                <label className="form-label">Extra Riichi</label>
            </div>
            <ButtonGroupSelector
                mainOptions={MAIN_RIICHI_OPTIONS}
                secondaryOptions={SECONDARY_RIICHI_OPTIONS}
                value={getRiichi()}
                onChange={riichi => onSelectRiichi(riichi)}
                disabled={!canChangePoints()}
            />
        </div>
    );
}
