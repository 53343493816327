import { GameParserStrategy } from "./GameParserStrategy";

export class RoundCountCalculator extends GameParserStrategy {
    private count = 0;

    onRoundEnd() {
        this.count++;
    }

    getCount() {
        return this.count;
    }
}
