import React from "react";
import { PageTitle } from "../common/PageTitle";
import { Panel } from "../common/Panel";
import { RankingTable } from "../dashboard/RankingTable";
import { PlayerList } from "../games/PlayerList";
import { SeasonGameList } from "../http/SeasonGameList";
import { Season } from "../seasons/Season";
import { SeasonSelector } from "../seasons/SeasonSelector";
import { PlayerScoreChart } from "./PlayerScoreChart";

export class Ranking extends React.Component<{
    season: Season;
    seasons: Season[];
    games: SeasonGameList;
    players: PlayerList;
}> {
    render() {
        return (
            <div className="ranking">
                <PageTitle>
                    Rankings for {this.props.season.getName()}
                </PageTitle>
                <Panel title="Rankings" icon="fa-trophy">
                    <SeasonSelector
                        season={this.props.season}
                        seasons={this.props.seasons}
                    />
                    <RankingTable
                        games={this.props.games}
                        players={this.props.players}
                        season={this.props.season}
                    />
                    <PlayerScoreChart
                        games={this.props.games}
                        players={this.props.players}
                        season={this.props.season}
                    />
                </Panel>
            </div>
        );
    }
}
