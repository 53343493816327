import { NumberStatistic } from "./NumberStatistic";

export class DurationStatistic extends NumberStatistic {
    static create(label: string, id: string, value: number) {
        return new DurationStatistic(label, id, value);
    }

    formatValue() {
        return `${Math.round(this.value)} seconds`;
    }
}
