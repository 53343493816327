import { IPlayer } from "../../../../server/src/models/Game";
import { GamePlayer } from "../models/GamePlayer";

export class GamePlayerSerializer {
    static fromJSON(data: IPlayer) {
        return new GamePlayer(
            data.player_id ?? null,
            data.seat ?? null,
            data.placement ?? null,
            data.score ?? 0,
        );
    }

    static toJSON(player: GamePlayer): IPlayer {
        return {
            player_id: player.playerId,
            seat: player.seat,
            placement: player.placement,
            score: player.score,
        };
    }
}
