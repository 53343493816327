import React from "react";
import { Link } from "react-router-dom";
import { AuthRequired } from "../components/AuthRequired";

function NavigationItem({
    label,
    path,
    icon,
    authRequired,
}: {
    label: string;
    path: string;
    icon: string;
    authRequired?: boolean;
}) {
    function renderContent() {
        return (
            <li className="navigation__item">
                <Link to={path} title={label}>
                    <div>
                        <span className={`fa fa-fw ${icon}`}></span>
                        <span className="navigation-item__label">{label}</span>
                    </div>
                </Link>
            </li>
        );
    }
    if (authRequired) {
        return <AuthRequired>{renderContent()}</AuthRequired>;
    }
    return renderContent();
}

export function Navigation() {
    return (
        <div className="navigation">
            <ul className="navigation__list">
                <NavigationItem path="/" label="Home" icon="fa-home" />
                <NavigationItem
                    path="/games"
                    label="Games"
                    icon="fa-chess-pawn"
                />
                <NavigationItem
                    path="/games/draft"
                    label="Draft Games"
                    icon="fa-pencil-ruler"
                    authRequired
                />
                <NavigationItem
                    path="/games/new"
                    label="New Game"
                    icon="fa-plus"
                    authRequired
                />
                <NavigationItem
                    path="/seasons"
                    label="Seasons"
                    icon="fa-calendar"
                    authRequired
                />
                <NavigationItem
                    path="/seasons/new"
                    label="New Season"
                    icon="fa-calendar-plus"
                    authRequired
                />
                <NavigationItem
                    path="/rankings"
                    label="Rankings"
                    icon="fa-chart-line"
                />
                <NavigationItem
                    path="/ratings"
                    label="Ratings"
                    icon="fa-calculator"
                />
                <NavigationItem
                    path="/compare"
                    label="Compare"
                    icon="fa-balance-scale"
                />
                <NavigationItem path="/rules" label="Rules" icon="fa-gavel" />
                <NavigationItem
                    path="/live-score"
                    label="Live Score"
                    icon="fa-broadcast-tower"
                />
                <NavigationItem
                    path="/players"
                    label="Players"
                    icon="fa-user"
                    authRequired
                />
                <NavigationItem
                    path="/players/new"
                    label="New Player"
                    icon="fa-user-plus"
                    authRequired
                />
            </ul>
        </div>
    );
}
