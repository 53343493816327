import { Game } from "../games/models/Game";
import { GameParser } from "../games/parser/GameParser";
import { RoundCountCalculator } from "../games/parser/RoundCountCalculator";
import { GamePlayerStat } from "./GamePlayerStat";

export class RoundCountStat extends GamePlayerStat {
    getLabel() {
        return "Round Count";
    }

    getId() {
        return "round-count";
    }

    getStatForGames(games: Game[]) {
        const count = games
            .map((g) =>
                GameParser.parse(g, new RoundCountCalculator()).getCount(),
            )
            .reduce(this.sumReducer(), 0);
        return this.createStatistic(count);
    }
}
