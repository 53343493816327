import React from "react";
import { CheckboxInput } from "../../common/controls/CheckboxInput";
import { NumberInput } from "../../common/controls/NumberInput";
import { SelectInput } from "../../common/controls/SelectInput";
import { TextInput } from "../../common/controls/TextInput";
import { Button } from "../../components/Button";
import { Rule } from "../Rule";
import { ScoringMethods } from "../ScoringMethod";
import { Season } from "../Season";

export function SeasonForm({
    season,
    onChange,
    onSubmit,
    actionLabel,
}: {
    season: Season;
    onChange: (s: Season) => void;
    onSubmit: () => void;
    actionLabel: string;
}) {
    function toggleRules(enabled: boolean, rule: Rule) {
        if (enabled) {
            onChange(season.addRule(rule));
        } else {
            onChange(season.removeRule(rule));
        }
    }

    return (
        <form className="season-form">
            <TextInput
                label="Name"
                value={season.name}
                onChange={(n) => onChange(season.setName(n))}
            />
            <SelectInput
                label="Scoring Method"
                value={season.scoring_method}
                options={ScoringMethods.map((s) => ({
                    id: s.id,
                    label: s.name,
                }))}
                onChange={(n) => onChange(season.setScoringMethod(n.id))}
            />
            <NumberInput
                label="First Place Bonus"
                value={season.first_bonus}
                onChange={(b) => onChange(season.setFirstBonus(b))}
            />
            <NumberInput
                label="Second Place Bonus"
                value={season.second_bonus}
                onChange={(b) => onChange(season.setSecondBonus(b))}
            />
            <NumberInput
                label="Third Place Bonus"
                value={season.third_bonus}
                onChange={(b) => onChange(season.setThirdBonus(b))}
            />
            <NumberInput
                label="Fourth Place Bonus"
                value={season.fourth_bonus}
                onChange={(b) => onChange(season.setFourthBonus(b))}
            />
            <CheckboxInput
                value={season.hasRule(Rule.SharedTies)}
                label="Shared Ties - Placement bonuses are split evenly in case of ties"
                onChange={(enabled) => toggleRules(enabled, Rule.SharedTies)}
            />
            <Button onClick={onSubmit}>{actionLabel}</Button>
        </form>
    );
}
