import React from "react";
import { PageTitle } from "../../common/PageTitle";
import { Panel } from "../../common/Panel";
import { SeasonGameList } from "../../http/SeasonGameList";
import { Season } from "../../seasons/Season";
import { SeasonSelector } from "../../seasons/SeasonSelector";
import { Player } from "../models/Player";
import { PlayerList } from "../PlayerList";
import { GamesTable } from "./GamesTable";
import { Pagination } from "./Pagination";

export class GamesList extends React.Component<{
    currentSeason: Season;
    seasons: Season[];
    games: SeasonGameList;
    players: PlayerList;
    pageNumber: number;
    highlightPlayer?: Player;
}> {
    render() {
        return (
            <div className="games-list">
                <PageTitle>Games for {this.props.currentSeason.name}</PageTitle>
                <SeasonSelector
                    season={this.props.currentSeason}
                    seasons={this.props.seasons}
                />
                <Panel icon="fa-trophy" title="Games List">
                    <GamesTable
                        games={this.props.games}
                        players={this.props.players}
                        season={this.props.currentSeason}
                        highlightPlayer={this.props.highlightPlayer}
                        pageNumber={this.props.pageNumber}
                    />
                    <Pagination
                        season={this.props.currentSeason}
                        count={this.props.games.getCount()}
                        current={this.props.pageNumber}
                    />
                </Panel>
            </div>
        );
    }
}
