const COLORS = [
    "#5cbae6",
    "#b6d957",
    "#fac364",
    "#8cd3ff",
    "#d998cb",
    "#f2d249",
    "#93b9c6",
    "#ccc5a8",
    "#52bacc",
    "#dbdb46",
    "#98aafb",
];

export class Colors {
    static forIndex(i: number): string {
        return COLORS[i % COLORS.length];
    }
}
