import React, { useEffect, useState } from "react";
import { Callback } from "../../../util/Callback";

export function FinalScoreInput({
    score,
    placement,
    onChangeScore,
    onChangePlacement,
}: {
    score: number;
    placement: number;
    onChangeScore: Callback<number>;
    onChangePlacement: Callback<number>;
}) {
    const [value, setValue] = useState(score.toString());

    useEffect(() => {
        setValue(score.toString());
    }, [score]);

    function onBlur() {
        const newScore = parseInt(value, 10);
        if (Number.isNaN(newScore)) {
            setValue(newScore.toString());
        } else {
            onChangeScore(newScore);
        }
    }

    function setPlacement(e: React.ChangeEvent<HTMLInputElement>) {
        const newPlacement = parseInt(e.target.value, 10);
        onChangePlacement(newPlacement);
    }

    return (
        <div className="form-group final-score-input">
            <div className="input-group final-score-input__input-row">
                <input
                    className="form-control final-score-input__score"
                    type="number"
                    min="-1000000"
                    max="1000000"
                    step="100"
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    onBlur={() => onBlur()}
                />
                <input
                    className="form-control final-score-input__placement"
                    type="number"
                    min="0"
                    max="4"
                    step="1"
                    value={placement}
                    onChange={(e) => setPlacement(e)}
                />
            </div>
        </div>
    );
}
