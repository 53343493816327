import * as t from "io-ts";
import { optional } from "./Optional";
import Validate from "./Validate";

const Season = t.exact(
    t.type({
        id: optional(t.number),
        name: t.string,
        scoring_method: optional(t.number),
        first_bonus: optional(t.number),
        second_bonus: optional(t.number),
        third_bonus: optional(t.number),
        fourth_bonus: optional(t.number),
        rules: t.array(t.string),
    }),
);

export function parseSeason(data: any) {
    return Validate.validate(Season.decode(data));
}

export default Season;

export interface ISeason extends t.TypeOf<typeof Season> {}
