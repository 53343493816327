import lodash from "lodash";
import { Game } from "../games/models/Game";
import { GameParser } from "../games/parser/GameParser";
import { RoundTimeCalculator } from "../games/parser/RoundTimeCalculator";
import { Distribution } from "./Distribution";
import { GamePlayerStat } from "./GamePlayerStat";

export class RoundTimeStat extends GamePlayerStat {
    getId() {
        return "average-round-time";
    }

    getLabel() {
        return "Average Time per Round";
    }

    getStatForGames(games: Game[]) {
        const times = lodash(games)
            .map(g => GameParser.parse(g, new RoundTimeCalculator()))
            .map(s => s.getTimes())
            .flatten()
            .filter(d => d > 0)
            .value();
        const duration = new Distribution(times).trimmedMean(0);
        return this.createDurationStatistic(duration);
    }
}
