import { Game } from "../games/models/Game";
import { Player } from "../games/models/Player";
import { DealInCalculator } from "../games/parser/DealInCalculator";
import { GameParser } from "../games/parser/GameParser";
import { checkNotNull } from "../util/Nullable";
import { AbstractRoundPercentStat } from "./AbstractRoundPercentStat";

export class DealInStat extends AbstractRoundPercentStat {
    getLabel() {
        return "Deal in Rate";
    }

    getId() {
        return "deal-in-rate";
    }

    getCount(game: Game, player: Player) {
        return GameParser.parse(
            game,
            new DealInCalculator(checkNotNull(game.getSeatForPlayer(player))),
        ).getCount();
    }
}
