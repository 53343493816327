import lodash from "lodash";
import { Group } from "./Group";

export class GroupedData {
    static createSorted(groups: Group[]) {
        return GroupedData.create(groups).sort();
    }

    static create(groups: Group[]) {
        return new GroupedData(groups);
    }

    groups: Group[];
    constructor(groups: Group[]) {
        this.groups = groups.slice();
    }

    sort() {
        this.groups = lodash(this.groups)
            .sortBy(g => g.getTotal())
            .reverse()
            .value();
        return this;
    }

    getGroups() {
        return this.groups.slice();
    }
}
