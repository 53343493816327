import { Game } from "../games/models/Game";
import { PaymentSignType } from "../games/models/PaymentSign";
import { Round } from "../games/models/Round";
import { GameParser } from "../games/parser/GameParser";
import { HonbaCalculator } from "../games/parser/HonbaCalculator";
import { RiichiCalculator } from "../games/parser/RiichiCalculator";
import { assert } from "../util/Assert";
import { RoundType, RoundTypes } from "./models/RoundType";

export class ScoreCalculator {
    calculate(round: Round) {
        const type = RoundTypes.getRoundByType(round.getType());
        const basePoints = this._calculateBasePoints(round);
        if (!this.canCalculatePoints(type)) {
            return round;
        }
        const pointResult = type.calculatePoints(
            round.isDealer(),
            basePoints,
            round.getHonba(),
            round.getPaymentSignsList(),
        );
        const payments = round
            .getPaymentSignsList()
            .map((sign) => pointResult.getResultForSign(sign));
        if (type.isWinningHand()) {
            const winner = round
                .getPaymentSignsList()
                .indexOf(PaymentSignType.GAIN);
            assert(
                winner > -1,
                "Expected a player to gain points but none found in",
            );
            payments[winner] += round.getRiichi() * 1000;
        }
        return round.setPaymentsList(payments);
    }

    canCalculatePoints(type: RoundType) {
        return type !== RoundTypes.ADJUSTMENT;
    }

    updateRiichiCount(game: Game, round: Round) {
        if (RoundTypes.getRoundByType(round.getType()).isWinningHand()) {
            const count = GameParser.parse(
                game,
                new RiichiCalculator(),
            ).getRiichiCount(game);
            return round.setRiichi(count);
        }
        return round.setRiichi(0);
    }

    updateHonbaCount(game: Game, round: Round) {
        if (RoundTypes.getRoundByType(round.getType()).isWinningHand()) {
            const count = GameParser.parse(
                game,
                new HonbaCalculator(),
            ).getHonba();
            return round.setHonba(count);
        }
        return round.setHonba(0);
    }

    _calculateBasePoints(round: Round) {
        const base = this._basePointsFromFuAndHan(round);
        return this._limitBasePoints(base, round.getHan());
    }

    _limitBasePoints(basePoints: number, han: number) {
        if (han >= 39) {
            return 24000;
        } else if (han >= 26) {
            return 16000;
        } else if (han >= 13) {
            return 8000;
        } else if (han >= 11) {
            return 6000;
        } else if (han >= 8) {
            return 4000;
        } else if (han >= 6) {
            return 3000;
        } else if (han >= 5) {
            return 2000;
        }
        return Math.min(basePoints, 2000);
    }

    _basePointsFromFuAndHan(round: Round) {
        return round.getFu() * 2 ** (2 + round.getHan());
    }

    _roundHundreds(number: number) {
        return Math.round(number / 100) * 100;
    }
}
