import React from "react";
import { Game } from "../../games/models/Game";
import { Player } from "../../games/models/Player";
import { Rotation } from "../../games/models/Rotation";
import { Seats } from "../../games/models/Seat";
import { PlayerList } from "../../games/PlayerList";
import { Callback } from "../../util/Callback";
import { PlayerSelector } from "./PlayerSelector";
import { RotationButton } from "./RotationButton";

export class PlayerEditor extends React.Component<{
    game: Game;
    players: PlayerList;
    onSetPlayer: (seat: number, player: Player) => void;
    onSetRotation: Callback<number>;
}> {
    getRotationOrDefault() {
        return this.props.game.getRotation() || 0;
    }

    render() {
        return (
            <div className="row player-editor">
                {Seats.all().map(seat => (
                    <div className="player-editor__player" key={seat}>
                        <div className="player-editor__player-selector">
                            <PlayerSelector
                                seat={seat}
                                game={this.props.game}
                                players={this.props.players}
                                onSelectPlayer={player =>
                                    this.props.onSetPlayer(seat, player)
                                }
                            />
                        </div>
                        <RotationButton
                            seat={seat}
                            rotation={this.getRotationOrDefault()}
                            onClick={() =>
                                this.props.onSetRotation(
                                    Rotation.seatToRotation(seat),
                                )
                            }
                        />
                    </div>
                ))}
            </div>
        );
    }
}
