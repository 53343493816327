import React from "react";
import { Game } from "../../../games/models/Game";
import { Round } from "../../../games/models/Round";
import { Seat } from "../../../games/models/Seat";
import { PlayerList } from "../../../games/PlayerList";
import { PlayerSignScore } from "./PlayerSignScore";

export function WinLoseSelector({
    players,
    game,
    round,
    onPaymentChange,
    onSignChange,
}: {
    players: PlayerList;
    game: Game;
    round: Round;
    onPaymentChange: (seat: Seat, payment: number) => void;
    onSignChange: (seat: Seat, sign: number) => void;
}) {
    function getPlayers() {
        return [1, 2, 3, 4];
    }

    function getPlayerName(seat: Seat) {
        return players.getName(game.getPlayerBySeat(seat).playerId);
    }

    function getSign(seat: Seat) {
        return round.getPaymentBySeat(seat).getPaymentSign();
    }

    function getScore(seat: Seat) {
        return round.getPaymentBySeat(seat).getPayment();
    }

    return (
        <div className="row">
            {getPlayers().map((seat) => (
                <div className="col-sm-3" key={seat}>
                    <div className="form-group">
                        <label className="form-label">
                            {getPlayerName(seat)}
                        </label>
                        <PlayerSignScore
                            sign={getSign(seat)}
                            value={getScore(seat)}
                            onChangeValue={(v) => onPaymentChange(seat, v)}
                            onChangeSign={(v) => onSignChange(seat, v)}
                        />
                    </div>
                </div>
            ))}
        </div>
    );
}
