export class BaseStatistic {
    constructor(protected label: string, protected id: string) {}
    getId() {
        return this.id;
    }

    getLabel() {
        return this.label;
    }
}

export class Statistic extends BaseStatistic {
    constructor(label: string, id: string, protected value: number) {
        super(label, id);
    }

    getId() {
        return this.id;
    }

    getLabel() {
        return this.label;
    }

    getValue() {
        return this.value;
    }

    formatValue() {
        return String(this.value);
    }
}
