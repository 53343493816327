import React from "react";

export function CheckboxInput({
    value,
    label,
    onChange,
}: {
    value: boolean;
    label: string;
    onChange: (newValue: boolean) => void;
}) {
    const onChangeThing = () => {
        onChange(!value);
    };

    return (
        <div className="checkbox-input">
            <label className="checkbox-label">
                <input
                    type="checkbox"
                    checked={value}
                    onChange={onChangeThing}
                />{" "}
                {label}
            </label>
        </div>
    );
}
