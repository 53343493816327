import React, { ReactNode, useContext } from "react";
import { Services } from "./Services";

const Context = React.createContext<Services | null>(null);

export function ServiceContext({
    services,
    children,
}: {
    services: Services;
    children: ReactNode;
}) {
    return <Context.Provider value={services}>{children}</Context.Provider>;
}

function useCheckedContext() {
    const ctx = useContext(Context);
    if (ctx == null) {
        throw new Error("Missing service context");
    }
    return ctx;
}

export function useGamesService() {
    return useCheckedContext().gameService;
}

export function useAuthService() {
    return useCheckedContext().authService;
}

export function useSeasonService() {
    return useCheckedContext().seasonService;
}

export function usePlayerService() {
    return useCheckedContext().playerService;
}
