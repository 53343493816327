import { SeriesData } from "../common/charts/SeriesData";
import { SeasonGameList } from "../http/SeasonGameList";
import { Season } from "../seasons/Season";
import { GameCountStat } from "../stats/GameCountStat";
import { FinalScoreCalculator } from "./FinalScoreCalculator";
import { Game } from "./models/Game";
import { Player } from "./models/Player";
import { PlayerList } from "./PlayerList";
import { PlayerRanking } from "./PlayerRanking";

export class RankingService {
    constructor(private finalScoreCalculator: FinalScoreCalculator) {}

    getRankings(games: Game[], players: Player[], season: Season) {
        const rankings = players.map((p) =>
            this.createRanking(games, p, season),
        );
        return PlayerRanking.sortByScore(rankings);
    }

    getNonEmptyRankings(games: Game[], players: Player[], season: Season) {
        return this.getRankings(games, players, season).filter(
            (r) => r.getCount() > 0,
        );
    }

    getRankingsByGame(
        games: Game[],
        players: Player[],
        season: Season,
    ): PlayerRanking[][] {
        const rankingsByGame: Map<Game, PlayerRanking[]> = new Map();
        games.forEach((game) => {
            const slice = games.slice(0, games.indexOf(game) + 1);
            rankingsByGame.set(game, this.getRankings(slice, players, season));
        });
        const playersWithGames = this.getNonEmptyRankings(
            games,
            players,
            season,
        ).map((r) => r.getPlayer());
        return games
            .map((g) => rankingsByGame.get(g))
            .filter((r): r is PlayerRanking[] => r != null)
            .map((rankings) =>
                rankings.filter((r) =>
                    playersWithGames.some(
                        (p) => p.getId() === r.getPlayer().getId(),
                    ),
                ),
            );
    }

    createRanking(games: Game[], player: Player, season: Season) {
        return new PlayerRanking(
            player,
            this.finalScoreCalculator.getTotalFinalScore(games, player, season),
            new GameCountStat().getStat(games, player).getValue(),
        );
    }

    calculateRunningScores(
        games: SeasonGameList,
        playerList: PlayerList,
        season: Season,
    ) {
        const rankings = this.getRankingsByGame(
            games.inOrder(),
            playerList.getAll(),
            season,
        );
        const chartData = this.collectPlayers(rankings).map((p) => {
            const scores = rankings.map((gameRankings) =>
                gameRankings
                    .filter(
                        (playerRanking) =>
                            playerRanking.getPlayer().getId() === p.getId(),
                    )[0]
                    .getScore(),
            );
            return new SeriesData(p.getName(), scores);
        });
        return chartData;
    }

    collectPlayers(rankings: PlayerRanking[][]) {
        if (rankings.length === 0) {
            return [];
        }
        return rankings[0].map((r) => r.getPlayer());
    }
}
