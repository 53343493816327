import React from "react";

export interface Option<T extends string | number> {
    id: T;
    label: string;
}

interface SelectInputProps<T extends string | number> {
    value: T;
    label: string;
    options: Option<T>[];
    onChange: (newValue: Option<T>) => void;
}

export class SelectInput<T extends string | number> extends React.Component<
    SelectInputProps<T>,
    {}
> {
    onChange(e: React.ChangeEvent<HTMLSelectElement>) {
        e.preventDefault();
        const option = this.props.options.find(o => o.id == e.target.value);
        if (option == null) {
            return;
        }
        this.props.onChange(option);
    }

    render() {
        return (
            <div className="select-input">
                <label htmlFor="gen" className="select-input__label">
                    {this.props.label}
                </label>
                <select
                    className="select-input__input"
                    value={this.props.value}
                    onChange={e => this.onChange(e)}
                >
                    {this.props.options.map(o => (
                        <option key={o.id} value={o.id}>
                            {o.label}
                        </option>
                    ))}
                </select>
            </div>
        );
    }
}
