import React from "react";
import { ChartDimensions } from "./ChartDimensions";
import { Scale } from "./Scale";

const TICK_SIZE = 5;
const TEXT_Y_OFFSET = 3;
const TEXT_MARGIN = 3;

export class ChartScale extends React.Component<{
    scale: Scale;
    dimensions: ChartDimensions;
}> {
    renderTick(tick: number) {
        const scaledPosition =
            this.props.dimensions.getHeight() - this.props.scale.scale(tick);
        const tickLeft = -1 - TICK_SIZE;
        return (
            <g key={tick}>
                <line
                    className="chart-scale__tick"
                    x1={tickLeft}
                    x2={tickLeft + this.props.dimensions.getWidth()}
                    y1={scaledPosition}
                    y2={scaledPosition}
                />
                <text
                    className="chart-scale__label"
                    x={tickLeft - TEXT_MARGIN}
                    y={scaledPosition}
                    dy={TEXT_Y_OFFSET}
                >
                    {tick}
                </text>
            </g>
        );
    }

    render() {
        return (
            <g className="chart-scale">
                <line
                    className="chart-scale__line"
                    x1={-1}
                    x2={-1}
                    y1={0}
                    y2={this.props.dimensions.getHeight()}
                />
                {this.props.scale.getTicks().map(s => this.renderTick(s))}
            </g>
        );
    }
}
