import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import { Loader } from "../../components/Loader";
import {
    useGamesService,
    useSeasonService,
} from "../../services/ServiceContext";
import { Game } from "../models/Game";

export function NewGamePage() {
    const [gameId, setGameId] = useState<number | null>(null);
    const gamesService = useGamesService();
    const seasonsService = useSeasonService();
    async function createNewGame() {
        const season = await seasonsService.getCurrentSeason();
        gamesService
            .create(Game.builder(season.getScoringMethodType()).create())
            .then((game) => setGameId(game.getId()));
    }
    useEffect(() => {
        createNewGame();
    }, []);

    if (gameId != null) {
        return <Redirect to={`/games/${gameId}/edit`} />;
    }
    return <Loader />;
}
