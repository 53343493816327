import React from "react";
import { useData } from "../app/Data";
import { SeasonGameList } from "../http/SeasonGameList";
import { useGamesService, useSeasonService } from "../services/ServiceContext";
import { Season } from "./Season";

export function useSeasons(): Season[] | null {
    const seasonService = useSeasonService();
    return useData(() => seasonService.list());
}

export function useCurrentSeason(): Season | null {
    const seasonService = useSeasonService();
    return useData(() => seasonService.getCurrentSeason());
}

export function useSeasonGames(
    seasonID: number | null, // null is the current season
): [Season | null, SeasonGameList | null] {
    const seasonService = useSeasonService();
    const gamesService = useGamesService();
    const [season, setSeason] = React.useState<Season | null>(null);
    const [games, setGames] = React.useState<SeasonGameList | null>(null);

    React.useEffect(() => {
        setSeason(null);
        setGames(null);
        seasonService.get(seasonID).then((season) => {
            setSeason(season);
            gamesService.list(season).then(setGames);
        });
    }, [seasonID]);

    return [season, games];
}
