import { NumberStatistic } from "./NumberStatistic";

export class PercentStatistic extends NumberStatistic {
    static createPercent(
        label: string,
        id: string,
        numerator: number,
        denominator: number,
    ) {
        return new PercentStatistic(label, id, numerator, denominator);
    }

    constructor(
        label: string,
        id: string,
        private numerator: number,
        private denominator: number,
    ) {
        super(label, id, denominator !== 0 ? numerator / denominator : 0);
    }

    formatValue() {
        return `${this.formatPercent()} (${this.numerator}/${
            this.denominator
        })`;
    }

    formatPercent() {
        const percent = (this.value * 100).toFixed(1);
        return `${percent}%`;
    }

    getNumerator() {
        return this.numerator;
    }

    getDenominator() {
        return this.denominator;
    }
}
