import React, { useEffect, useState } from "react";
import { Game } from "../../games/models/Game";
import { Round } from "../../games/models/Round";
import { PlayerList } from "../../games/PlayerList";
import { Callback } from "../../util/Callback";
import { FuValidator } from "../FuValidator";
import { RoundCalculator } from "../RoundCalculator";
import { RoundEditorOptions } from "../RoundEditorOptions";
import { RoundNormalizer } from "../RoundNormalizer";
import { SignUpdater } from "../SignUpdater";
import { AddHandButton } from "./edit-form-components/AddHandButton";
import { AutoUpdate } from "./edit-form-components/AutoUpdate";
import { DealerSelector } from "./edit-form-components/DealerSelector";
import { FuSelector } from "./edit-form-components/FuSelector";
import { HanSelector } from "./edit-form-components/HanSelector";
import { HonbaSelector } from "./edit-form-components/HonbaSelector";
import { RiichiSelector } from "./edit-form-components/RiichiSelector";
import { TypeSelector } from "./edit-form-components/TypeSelector";
import { WinLoseSelector } from "./edit-form-components/WinLoseSelector";

export function EditForm({
    game,
    players,
    onAddRound,
}: {
    game: Game;
    players: PlayerList;
    onAddRound: Callback<Round>;
}) {
    const [round, setRound] = useState(Round.createDefault());
    const [options, setOptions] = useState(new RoundEditorOptions());

    function updateRound(round: Round) {
        let updated = FuValidator.updateFu(round);
        updated = RoundCalculator.calculateScore(game, updated, options);
        setRound(updated);
    }

    useEffect(() => {
        setRound(RoundCalculator.calculateScore(game, round, options));
    }, [game]);

    function addRound() {
        onAddRound(new RoundNormalizer().normalize(round));
    }

    return (
        <div className="row edit-form">
            <div className="col-sm-2">
                <TypeSelector
                    round={round}
                    onSelectType={(t) =>
                        updateRound(round.setType(t.getType()))
                    }
                />
            </div>
            <div className="col-sm-10">
                <div className="row">
                    <div className="col-sm-6">
                        <FuSelector
                            round={round}
                            onSelectFu={(fu) => updateRound(round.setFu(fu))}
                        />
                    </div>
                    <div className="col-sm-6">
                        <HanSelector
                            round={round}
                            onSelectHan={(fu) => updateRound(round.setHan(fu))}
                        />
                    </div>
                    <div className="col-sm-6">
                        <HonbaSelector
                            round={round}
                            onSelectHonba={(honba) =>
                                updateRound(round.setHonba(honba))
                            }
                        />
                    </div>
                    <div className="col-sm-6">
                        <RiichiSelector
                            round={round}
                            onSelectRiichi={(riichi) =>
                                updateRound(round.setRiichi(riichi))
                            }
                        />
                    </div>
                    <div className="col-sm-12">
                        <WinLoseSelector
                            round={round}
                            game={game}
                            players={players}
                            onPaymentChange={(seat, payment) => {
                                updateRound(
                                    round.updatePayment(seat, (p) =>
                                        p.copy({ payment: payment }),
                                    ),
                                );
                            }}
                            onSignChange={(seat, sign) =>
                                updateRound(
                                    new SignUpdater().setSign(
                                        round,
                                        seat,
                                        sign,
                                    ),
                                )
                            }
                        />
                    </div>
                    <div className="col-sm-4">
                        <DealerSelector
                            round={round}
                            onSetDealer={(dealer) =>
                                updateRound(round.setDealer(dealer))
                            }
                        />
                    </div>
                    <div className="col-sm-4 text-center">
                        <AutoUpdate
                            isAutoUpdate={options.isAutoUpdateHand()}
                            setAutoUpdate={(autoUpdate) =>
                                setOptions(
                                    options
                                        .copy()
                                        .setAutoUpdateHand(autoUpdate),
                                )
                            }
                        />
                    </div>
                    <div className="col-sm-4 text-right">
                        <AddHandButton onClick={addRound} />
                    </div>
                </div>
            </div>
        </div>
    );
}
