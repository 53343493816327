import React from "react";
import { Panel } from "../common/Panel";
import { Player } from "../games/models/Player";
import { PercentStatistic } from "../stats/PercentStatistic";
import { LabelledStat } from "./LabelledStat";
import { PlacementTable } from "./PlacementTable";
import { StatisticCollection } from "./StatisticCollection";
import { WindPlacementTable } from "./WindPlacementTable";

const OVERALL_STATS = ["average-score", "deaths"];

const ROUND_STATS = [
    "round-count",
    "round-win-rate",
    "ron-rate",
    "tsumo-rate",
    "riichi-rate",
    "deal-in-rate",
    "average-round-time",
    "participation-rate",
    "average-hand-value",
    "average-feed-hand-value",
    "yakuman-count",
];

export class PlayerStats extends React.Component<{
    player: Player;
    statisticCollection: StatisticCollection;
}> {
    getOverallTitle() {
        const name = this.props.player.getName();
        return `${name} - Overall Stats`;
    }

    getRoundStatsTitle() {
        const name = this.props.player.getName();
        return `${name} - Round Stats`;
    }

    getPlacements(): PercentStatistic[] {
        return [
            this.props.statisticCollection.getById("placement-1-stat"),
            this.props.statisticCollection.getById("placement-2-stat"),
            this.props.statisticCollection.getById("placement-3-stat"),
            this.props.statisticCollection.getById("placement-4-stat"),
        ] as any as PercentStatistic[];
    }

    renderStats(list: string[]) {
        return (
            <div className="player-stats__stat-list">
                {list.map((id) => (
                    <div className="player-stats__stat" key={id}>
                        <LabelledStat
                            stat={this.props.statisticCollection.getById(id)}
                        />
                    </div>
                ))}
            </div>
        );
    }

    render() {
        return (
            <div className="player-stats">
                <Panel title={this.getOverallTitle()} icon="fa-info">
                    {this.renderStats(OVERALL_STATS)}
                    <PlacementTable placementStats={this.getPlacements()} />
                    <WindPlacementTable
                        statisticCollection={this.props.statisticCollection}
                    />
                </Panel>
                <Panel title={this.getRoundStatsTitle()} icon="fa-info">
                    {this.renderStats(ROUND_STATS)}
                </Panel>
            </div>
        );
    }
}
