import { Game } from "../games/models/Game";
import { Seats } from "../games/models/Seat";
import { GameCalculatorService } from "../games/parser/GameCalculatorService";
import { Rule } from "../seasons/Rule";
import { Season } from "../seasons/Season";
import { FinalResults } from "./FinalResults";

export class TotalUpdater {
    private gameCalculatorService = new GameCalculatorService();

    updateTotals(game: Game, season: Season, finalResults: FinalResults) {
        const scores = Seats.all().map((s) => ({
            score: this.gameCalculatorService.getPlayerPoints(game, s),
            seat: s,
        }));

        let updatedFinalResults = finalResults;
        scores.forEach((score) => {
            updatedFinalResults = updatedFinalResults.setScore(
                score.seat,
                score.score,
            );
        });

        return updatedFinalResults.computePlacementsFromScore(
            season.hasRule(Rule.SharedTies),
        );
    }
}
