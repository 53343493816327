export function classnames(
    ...names: (string | { [key: string]: boolean })[]
): string {
    const classes: string[] = [];
    names.forEach(n => {
        if (typeof n === "string") {
            classes.push(n);
        } else if (n != null) {
            Object.keys(n).forEach(k => {
                if (n[k]) {
                    classes.push(k);
                }
            });
        }
    });
    return classes.join(" ");
}
