import { Game } from "../models/Game";
import { PointTotalCalculator } from "./PointTotalCalculator";

export class RiichiCalculator extends PointTotalCalculator {
    getRiichiCount(game: Game) {
        return Math.floor(
            (game.getTotalStartingPoints() - this.getTotalPoints()) / 1000,
        );
    }
}
