const TICK_MULTIPLES = [1, 2, 5];

export class TickScale {
    static getTicks(min: number, max: number, optimal = 10, center = 0) {
        const range = max - min;
        const exponent = Math.round(Math.log10(range));
        const multiples = TICK_MULTIPLES.map(
            t => t * 10 ** (exponent - 1),
        ).map(t => parseFloat(t.toFixed(4)));
        const closest = TickScale.getClosest(range, multiples, optimal);
        const ticks = [];
        for (let i = center; i <= max; i += closest) {
            ticks.push(i);
        }
        for (let i = center - closest; i >= min; i -= closest) {
            ticks.push(i);
        }
        return ticks.sort((a, b) => a - b);
    }

    static getClosest(range: number, multiples: number[], optimal: number) {
        let min = multiples[0];
        multiples.forEach(m => {
            if (
                TickScale.distanceToOptimalTick(range, m, optimal) <
                TickScale.distanceToOptimalTick(range, min, optimal)
            ) {
                min = m;
            }
        });
        return min;
    }

    static tickCount(range: number, tickSize: number) {
        return Math.floor(range / tickSize);
    }

    static distanceToOptimalTick(
        range: number,
        tickSize: number,
        optimal: number,
    ) {
        return Math.abs(TickScale.tickCount(range, tickSize) - optimal);
    }
}
