import { Game } from "../games/models/Game";
import { PaymentSign, PaymentSignType } from "../games/models/PaymentSign";
import { Round } from "../games/models/Round";
import { Seat, Seats } from "../games/models/Seat";
import { GameCalculatorService } from "../games/parser/GameCalculatorService";
import { Fluent } from "../util/Fluent";
import { RoundTypes } from "./models/RoundType";
import { PaymentSignListBuilder } from "./PaymentSignListBuilder";

export class SignUpdater {
    private gameCalculatorService = new GameCalculatorService();

    normalizeSigns(round: Round) {
        const type = RoundTypes.getRoundByType(round.getType());

        return round.setPaymentSignsList(
            type.updatePlayerSigns(
                round.getPaymentSignsList(),
                round.isDealer(),
                -1,
            ),
        );
    }

    normalizeSignsAndDealer(round: Round, dealerSeat: Seat) {
        return Fluent.of(round)
            .map((r) => this.normalizeSigns(r))
            .map((r) => this.updateDealer(r, dealerSeat))
            .get();
    }

    normalizeSignsFromGame(round: Round, game: Game) {
        const dealerSeat = this.gameCalculatorService
            .getSeatWind(game, null)
            .getSeat();
        return this.normalizeSignsAndDealer(round, dealerSeat);
    }

    setSign(round: Round, seat: Seat, sign: PaymentSignType) {
        const type = RoundTypes.getRoundByType(round.getType());
        const signs = round.getPaymentSignsList();
        signs[Seats.seatToIndex(seat)] = sign;
        return round.setPaymentSignsList(
            type.updatePlayerSigns(
                signs,
                round.isDealer(),
                Seats.seatToIndex(seat),
            ),
        );
    }

    updateDealer(round: Round, dealerSeat: Seat) {
        const type = RoundTypes.getRoundByType(round.getType());
        if (!type.isWinningHand()) {
            return round;
        }

        return Fluent.of(round)
            .map((r) => this.fixIsDealer(r, dealerSeat))
            .map((r) => this.fixDealerPayment(r, dealerSeat))
            .get();
    }

    fixIsDealer(round: Round, dealerSeat: Seat) {
        const isPaymentDealer = PaymentSign.isGain(
            round.getPaymentBySeat(dealerSeat).getPaymentSign(),
        );
        if (round.isDealer() && !isPaymentDealer) {
            return this.normalizeSigns(round.setDealer(false));
        } else if (!round.isDealer() && isPaymentDealer) {
            return this.normalizeSigns(round.setDealer(true));
        }
        return round;
    }

    fixDealerPayment(round: Round, dealerSeat: Seat) {
        if (
            !round.isDealer() &&
            !PaymentSign.isLoss(
                round.getPaymentBySeat(dealerSeat).getPaymentSign(),
            ) &&
            RoundTypes.getRoundByType(round.getType()).dealerPaysMore()
        ) {
            return round.setPaymentSignsList(
                PaymentSignListBuilder.from(round.getPaymentSignsList())
                    .resetSeatsWithSign(PaymentSignType.LOSS)
                    .setSign(dealerSeat, PaymentSignType.LOSS)
                    .create(),
            );
        }
        return round;
    }
}
