import React from "react";
import { SeasonGameList } from "../../http/SeasonGameList";
import { Season } from "../../seasons/Season";
import { optionalLast } from "../../util/Arrays";
import { Game } from "../models/Game";
import { Player } from "../models/Player";
import { PlayerList } from "../PlayerList";
import { GamesTableRow } from "./GamesTableRow";

export class GamesTable extends React.Component<{
    games: SeasonGameList;
    players: PlayerList;
    pageNumber: number;
    season: Season;
    highlightPlayer?: Player;
    filter?: (game: Game) => boolean;
}> {
    getFooterMessage(first: Game | null, last: Game | null) {
        return `Games ${this.gameNumber(first)} to ${this.gameNumber(
            last,
        )} of ${this.props.games.getCount()}`;
    }

    gameNumber(game: Game | null) {
        if (game == null) {
            return "-";
        }
        return this.props.games.getGameNumber(game);
    }

    render() {
        const page = this.props.games.getLatestPage(
            this.props.pageNumber,
            this.props.filter,
        );
        return (
            <div className="games-table">
                <table className="table table-striped table-bordered table-hover games-table__table">
                    <thead>
                        <tr>
                            <th className="games-table__number-column" />
                            <th>First</th>
                            <th>Second</th>
                            <th>Third</th>
                            <th>Forth</th>
                            <th>Date</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {page.map(g => (
                            <GamesTableRow
                                game={g}
                                players={this.props.players}
                                key={g.getId()}
                                season={this.props.season}
                                gameNumber={this.props.games.getGameNumber(g)}
                                highlightPlayer={this.props.highlightPlayer}
                            />
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan={7} className="text-muted">
                                {this.getFooterMessage(
                                    page[0],
                                    optionalLast(page),
                                )}
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        );
    }
}
