import { Player } from "../games/models/Player";
import { PlayerData } from "../games/models/PlayerData";
import { PlayerList } from "../games/PlayerList";
import { HttpService } from "./HttpService";

export class PlayerService {
    constructor(private httpService: HttpService) {}

    async list(): Promise<PlayerList> {
        const players: PlayerData[] = await this.httpService.get(
            "/api/players",
        );
        return new PlayerList(players.map((p) => Player.fromRest(p)));
    }

    async get(playerId: number): Promise<Player> {
        const players = await this.list();
        return players.getByIdChecked(playerId);
    }

    async update(player: Player): Promise<Player> {
        return Player.fromRest(
            await this.httpService.post(
                `/api/players/${player.getId()}`,
                player,
            ),
        );
    }

    async create(player: Player): Promise<Player> {
        return Player.fromRest(
            await this.httpService.post(`/api/players`, player),
        );
    }
}
