import React from "react";

interface ButtonLinkProps {
    onClick: () => void;
}

export class ButtonLink extends React.Component<ButtonLinkProps, {}> {
    render() {
        return (
            <button
                className="button-link"
                onClick={e => {
                    e.preventDefault();
                    this.props.onClick();
                }}
            >
                {this.props.children}
            </button>
        );
    }
}
