import classnames from "classnames";
import React from "react";
import { SelfLink } from "../components/SelfLink";
import { checkNotNull } from "../util/Nullable";
import { Season } from "./Season";

export class SeasonLink extends React.Component<{
    season: Season;
    isCurrent: boolean;
}> {
    render() {
        return (
            <span
                className={classnames("season-link", {
                    "season-link__selected": this.props.isCurrent,
                })}
            >
                <SelfLink
                    values={{ season: checkNotNull(this.props.season.getId()) }}
                >
                    {this.props.season.getName()}
                </SelfLink>
            </span>
        );
    }
}
