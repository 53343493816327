import { Game } from "../games/models/Game";
import { Distribution } from "../stats/Distribution";
import { checkNotNull } from "../util/Nullable";

const StartingElo = 1500;

export class EloChange {
    constructor(
        readonly player: number,
        readonly change: number,
        readonly newElo: number,
    ) {}
}

export class GameElo {
    constructor(
        readonly game: Game,
        readonly changes: ReadonlyArray<EloChange>,
    ) {}

    forPlayer(player: number) {
        return checkNotNull(this.changes.find((c) => c.player == player));
    }
}

export class EloHistory {
    playerHistory: Map<number, GameElo[]> = new Map();

    getGameHistory(player: number): ReadonlyArray<GameElo> {
        return this.playerHistory.get(player) ?? [];
    }

    getLastElo(player: number): number {
        const history = this.playerHistory.get(player);
        if (history == null || history.length == 0) {
            return StartingElo;
        }
        return history[history.length - 1].forPlayer(player).newElo;
    }

    countGames(player: number): number {
        return this.playerHistory.get(player)?.length ?? 0;
    }

    add(gameElo: GameElo) {
        gameElo.changes.forEach((change) =>
            this.playerHistory.set(change.player, [
                ...this.getGameHistory(change.player),
                gameElo,
            ]),
        );
    }

    players() {
        return Array.from(this.playerHistory.keys());
    }
}

function getAdjustment(gamesPlayed: number) {
    if (gamesPlayed < 400) {
        return 1 - gamesPlayed * 0.002;
    } else {
        return 0.2;
    }
}

function getBase(placement: number) {
    switch (placement) {
        case 1:
            return 30;
        case 2:
            return 10;
        case 3:
            return -10;
        case 4:
            return -30;
        default:
            throw new Error(`Invalid placement ${placement}`);
    }
}

export class RatingCalculator {
    eloHistory = new EloHistory();

    history() {
        return this.eloHistory;
    }

    updateAll(games: Game[]): EloHistory {
        games.forEach((game) => this.update(game));
        return this.eloHistory;
    }

    update(game: Game): GameElo | null {
        const players = game.getPlayers();
        if (players.some((p) => p.playerId == null || p.placement == null)) {
            console.error("Invalid game, missing player or placement", game);
            return null;
        }
        const averageRating = new Distribution(
            players.map((p) =>
                this.eloHistory.getLastElo(checkNotNull(p.playerId)),
            ),
        ).average();
        const changes: EloChange[] = [];
        players.forEach((player) => {
            const playerId = checkNotNull(player.playerId);
            const placement = checkNotNull(player.placement);
            const change = this.computeChange(
                placement,
                averageRating,
                this.eloHistory.getLastElo(playerId),
                this.eloHistory.countGames(playerId),
            );
            const newElo = this.eloHistory.getLastElo(playerId) + change;
            const eloChange = new EloChange(playerId, change, newElo);
            changes.push(eloChange);
        });
        const gameElo = new GameElo(game, changes);
        this.eloHistory.add(gameElo);
        return gameElo;
    }

    private computeChange(
        placement: number,
        averageRate: number,
        rate: number,
        gamesPlayed: number,
    ) {
        return (
            getAdjustment(gamesPlayed) *
            (getBase(placement) + (averageRate - rate) / 40)
        );
    }
}
