import { Round } from "../games/models/Round";
import { Fluent } from "../util/Fluent";
import { RoundTypes } from "./models/RoundType";

const HAS_DEALER_TYPES = [
    RoundTypes.RON,
    RoundTypes.TSUMO,
    RoundTypes.CHOMBO,
    RoundTypes.NAGASHIMANGAN,
];
export class RoundNormalizer {
    normalize(round: Round): Round {
        return Fluent.of(round)
            .map((r) => this.clearWinInformation(r))
            .map((r) => this.updateFu(r))
            .map((r) => this.clearDealerInformation(r))
            .map((r) => this.updateDate(r))
            .get();
    }

    updateFu(round: Round) {
        if (!RoundTypes.getRoundByType(round.getType()).isWinningHand()) {
            return round;
        }
        if (round.getHan() >= 5) {
            return round.setFu(0);
        }
        return round;
    }

    clearWinInformation(round: Round) {
        if (RoundTypes.getRoundByType(round.getType()).isWinningHand()) {
            return round;
        }
        return round.setHan(0).setFu(0).setHonba(0).setRiichi(0);
    }

    clearDealerInformation(round: Round) {
        const type = RoundTypes.getRoundByType(round.getType());
        if (HAS_DEALER_TYPES.indexOf(type) > -1) {
            return round;
        }
        return round.setDealer(false);
    }

    updateDate(round: Round) {
        return round.setCreatedAt(new Date());
    }
}
