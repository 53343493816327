import React, { useEffect, useState } from "react";
import * as io from "socket.io-client";
import { IGame } from "../../../server/src/models/Game";
import { FinalScoreCalculator } from "../games/FinalScoreCalculator";
import { Game } from "../games/models/Game";
import { Seats } from "../games/models/Seat";
import { PlayerList } from "../games/PlayerList";
import { PlayerRanking } from "../games/PlayerRanking";
import { RankingService } from "../games/RankingService";
import { GameSerializer } from "../games/serializers/GameSerializer";
import { Season } from "../seasons/Season";
import { usePlayerService, useSeasonService } from "../services/ServiceContext";
import { RankingTable } from "./RankingTable";
import { RoundInfo } from "./RoundInfo";
import { TableSeat } from "./TableSeat";

interface Update {
    draft?: IGame;
    games?: IGame[];
}

export function LiveScorePage() {
    const [game, setGame] = useState<Game | null>(null);
    const [players, setPlayers] = useState<PlayerList | null>(null);
    const [season, setSeason] = useState<Season | null>(null);
    const [rankings, setRankings] = useState<PlayerRanking[] | null>(null);

    const seasonService = useSeasonService();
    const playerService = usePlayerService();

    useEffect(() => {
        const socket = io.connect();

        Promise.all([
            seasonService.getCurrentSeason(),
            playerService.list(),
        ]).then(([season, players]) => {
            setSeason(season);
            setPlayers(players);
            function handleUpdate(update: Update) {
                if (update.draft != null) {
                    setGame(GameSerializer.fromJSON(update.draft));
                } else {
                    setGame(null);
                }
                if (update.games != null) {
                    const rankingService = new RankingService(
                        new FinalScoreCalculator(),
                    );
                    const games = update.games.map(GameSerializer.fromJSON);
                    const rankings = rankingService.getNonEmptyRankings(
                        games,
                        players.getAll(),
                        season,
                    );
                    setRankings(rankings);
                }
            }
            socket.on("update", (update: any) => handleUpdate(update));
        });

        return () => {
            socket.close();
        };
    }, []);

    function renderTable(game: Game, players: PlayerList) {
        return (
            <div className="live-table">
                {Seats.all().map((seat) => (
                    <TableSeat
                        key={seat}
                        seat={seat}
                        game={game}
                        players={players}
                    />
                ))}
                <RoundInfo game={game} />
            </div>
        );
    }

    if (game != null && players != null) {
        return renderTable(game, players);
    }
    if (rankings != null && season != null) {
        return <RankingTable rankings={rankings} season={season} large />;
    }
    return <div>Loading</div>;
}
