import { Game } from "../games/models/Game";
import { Player } from "../games/models/Player";
import { Wind, Winds } from "../games/models/Winds";
import { Distribution } from "./Distribution";
import { GamePlayerStat } from "./GamePlayerStat";
import { NumberStatistic } from "./NumberStatistic";

export class WindPlacementStat extends GamePlayerStat {
    constructor(private seatWind: Wind) {
        super();
        Winds.assertWind(seatWind);
    }

    getId() {
        return `expected-placement-${this.seatWind.getSeat()}`;
    }

    getLabel() {
        return `Expected Placement - ${this.seatWind.getName()} Seat`;
    }

    getStatForGames(games: Game[], player: Player): NumberStatistic {
        throw new Error("Not implemented");
    }

    getStat(games: Game[], player: Player) {
        const placements = games
            .filter((g) => g.hasPlayer(player))
            .map((g) => g.getGamePlayer(player))
            .filter((p) => p.seat === this.seatWind.getSeat())
            .map((p) => p.placement)
            .filter((p): p is number => p != null);
        return this.createStatistic(new Distribution(placements).average(2.5));
    }
}
