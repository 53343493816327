import React from "react";
import { classnames } from "../util/Classnames";

export class Panel extends React.Component<
    { title: React.ReactNode; icon: string },
    {}
> {
    render() {
        return (
            <div className="panel panel-default">
                <div className="panel-heading">
                    <span
                        className={classnames("fa", "fa-fw", this.props.icon)}
                    />{" "}
                    {this.props.title}
                </div>
                <div className="panel-body">{this.props.children}</div>
            </div>
        );
    }
}
