import React, { useEffect, useState } from "react";
import { ChartDimensions } from "../common/charts/ChartDimensions";
import { LineChart } from "../common/charts/LineChart";
import { SeriesData } from "../common/charts/SeriesData";
import { PageTitle } from "../common/PageTitle";
import { Player } from "../games/models/Player";
import { usePlayers } from "../player/Players";
import { Season } from "../seasons/Season";
import { useGamesService } from "../services/ServiceContext";
import { EloHistory, RatingCalculator } from "./RatingCalculator";
import { RatingGameHistory } from "./RatingGameHistory";

export function RatingsPage() {
    const gamesService = useGamesService();
    const [elo, setElo] = useState<EloHistory | null>(null);
    const [selectedPlayer, setSelectedPlayer] = useState<Player | null>(null);
    const players = usePlayers();

    async function loadGames() {
        const games = await gamesService.list(Season.createOverall());
        const calculator = new RatingCalculator();
        setElo(calculator.updateAll(games.inOrder()));
    }
    useEffect(() => {
        loadGames();
    }, []);
    if (players == null || elo == null) {
        return null;
    }

    function renderChart(elo: EloHistory) {
        if (selectedPlayer == null) {
            return null;
        }
        return (
            <LineChart
                lines={[
                    new SeriesData(
                        selectedPlayer.name,
                        elo
                            .getGameHistory(selectedPlayer.getIdChecked())
                            .map(
                                (h) =>
                                    h.forPlayer(selectedPlayer.getIdChecked())
                                        .newElo,
                            ),
                    ),
                ]}
                center={1500}
                showPoints={false}
                showCursor={false}
                scaleX
                dimensions={ChartDimensions.create(700, 400, 40, 40, 40, 60)}
            />
        );
    }
    function renderRecent(elo: EloHistory) {
        if (selectedPlayer == null || players == null) {
            return null;
        }
        const games = elo
            .getGameHistory(selectedPlayer.getIdChecked())
            .slice()
            .reverse()
            .slice(0, 10);
        return (
            <RatingGameHistory
                player={selectedPlayer.getIdChecked()}
                gameElo={games}
                players={players}
            />
        );
    }
    return (
        <div>
            <PageTitle>Ratings</PageTitle>
            <p>
                Computed using the{" "}
                <a href="https://arcturus.su/tenhou/ids-ranks.html#rate">
                    Tenhou Rate Formula
                </a>
            </p>
            <table className="table table-hover">
                <thead>
                    <tr>
                        <th>Player</th>
                        <th>Rating</th>
                    </tr>
                </thead>
                <tbody>
                    {elo
                        .players()
                        .sort((a, b) => elo.getLastElo(b) - elo.getLastElo(a))
                        .map((player) => (
                            <tr
                                key={player}
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                    setSelectedPlayer(
                                        players.getByIdChecked(player),
                                    )
                                }
                            >
                                <td>{players.getByIdChecked(player).name}</td>
                                <td>{elo.getLastElo(player).toFixed(0)}</td>
                            </tr>
                        ))}
                </tbody>
            </table>
            {renderChart(elo)}
            {renderRecent(elo)}
        </div>
    );
}
