import { checkNotNull } from "../util/Nullable";
import { Statistic } from "./Statistic";

export class PlayerStatistic extends Statistic {
    static fromMapCountExcludingPlayer(
        label: string,
        id: string,
        countMap: Map<number, number>,
        playerId: number,
    ) {
        const stat = new PlayerStatistic(label, id);
        Array.from(countMap.keys())
            .filter(p => p !== playerId)
            .forEach(p => stat.setCount(p, checkNotNull(countMap.get(p))));
        return stat;
    }

    static createPlayer(label: string, id: string) {
        return new PlayerStatistic(label, id);
    }

    playerValues: Map<number, number> = new Map();
    constructor(label: string, id: string) {
        super(label, id, 0);
    }

    setCount(playerId: number, count: number) {
        this.playerValues.set(playerId, count);
        return this;
    }

    getPlayerIds() {
        return Array.from(this.playerValues.keys());
    }

    hasPlayerId(playerId: number) {
        return this.playerValues.has(playerId);
    }

    getForPlayer(playerId: number) {
        return this.playerValues.get(playerId);
    }
}
