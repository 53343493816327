import classnames from "classnames";
import React from "react";
import ReactDom from "react-dom";

export class Popup extends React.Component<{
    x: number;
    y: number;
    visible: boolean;
    children: React.ReactNode;
}> {
    el = document.createElement("div");

    componentDidMount() {
        document.body.appendChild(this.el);
    }

    componentWillUnmount() {
        document.body.removeChild(this.el);
    }

    render() {
        return ReactDom.createPortal(
            <div className="popup">
                <div
                    className={classnames("popup__popup", {
                        "popup--visible": this.props.visible,
                    })}
                    style={{ left: this.props.x, top: this.props.y }}
                >
                    {this.props.children}
                </div>
            </div>,
            this.el,
        );
    }
}
