import { Game } from "./Game";

export class Rotation {
    static rotationToSeat(rotation: number) {
        return rotation + 1;
    }

    static seatToRotation(seat: number) {
        return seat - 1;
    }

    static getRotationOrDefault(game: Game) {
        return game.getRotation() || 0;
    }
}
