import { Game } from "../games/models/Game";
import { Player } from "../games/models/Player";
import { GameParser } from "../games/parser/GameParser";
import { RonCalculator } from "../games/parser/RonCalculator";
import { checkNotNull } from "../util/Nullable";
import { AbstractRoundPercentStat } from "./AbstractRoundPercentStat";

export class RonRateStat extends AbstractRoundPercentStat {
    getLabel() {
        return "Ron Rate";
    }

    getId() {
        return "ron-rate";
    }

    requiresSeat() {
        return false;
    }

    getCount(game: Game, player: Player): number {
        return GameParser.parse(
            game,
            new RonCalculator(checkNotNull(game.getSeatForPlayer(player))),
        ).getCount();
    }
}
