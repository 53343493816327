import { ScoringMethod } from "../seasons/ScoringMethod";
import { Season } from "../seasons/Season";
import { Collections } from "../util/Collections";
import { Game } from "./models/Game";
import { GamePlayer } from "./models/GamePlayer";
import { Player } from "./models/Player";

export class FinalScoreCalculator {
    getFinalScore(game: Game, gamePlayer: GamePlayer, season: Season) {
        const method = ScoringMethod.getById(season.getScoringMethod());
        return method.getScore(game, gamePlayer, season);
    }

    getTotalFinalScore(games: Game[], player: Player, season: Season) {
        const scores = games
            .filter((g) => g.hasPlayer(player))
            .map((g) =>
                this.getFinalScore(
                    g,
                    this.getFirstGamePlayer(g, player),
                    season,
                ),
            );
        return ScoringMethod.getById(season.getScoringMethod()).reduce(scores);
    }

    getFirstGamePlayer(game: Game, player: Player) {
        return Collections.findFirst(
            game.getPlayers(),
            (p) => p.playerId === player.getId(),
        );
    }
}
