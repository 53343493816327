import React, { useContext, useState } from "react";
import { Redirect } from "react-router";
import { AuthContext } from "../../components/AuthRequired";
import { useErrorHandler } from "../../errors/ErrorDisplay";
import { useAuthService } from "../../services/ServiceContext";

export function LoginPage() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [isRedirecting, setIsRedirecting] = useState(false);

    const authContext = useContext(AuthContext);
    const authService = useAuthService();
    const errorHandler = useErrorHandler();

    async function doLogin(e: React.FormEvent) {
        e.preventDefault();
        await authService.login(username, password).catch(errorHandler);
        await authContext.reload();
        setIsRedirecting(true);
    }

    if (isRedirecting) {
        return <Redirect to="/" />;
    }
    return (
        <div className="login-page">
            <form className="login-page__form" onSubmit={(e) => doLogin(e)}>
                <h2 className="login-page__title">Login</h2>
                <input
                    placeholder="Username"
                    type="text"
                    className="login-page__username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <input
                    placeholder="Password"
                    type="password"
                    className="login-page__password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <button className="login-page__login">Login</button>
            </form>
        </div>
    );
}
