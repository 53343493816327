import React from "react";
import { SelectInput } from "../common/controls/SelectInput";
import { Season } from "../seasons/Season";
import { Callback } from "../util/Callback";
import { checkNotNull } from "../util/Nullable";

export function SeasonSelector({
    seasonID,
    seasons,
    onChange,
}: {
    seasonID: number;
    seasons: Season[];
    onChange: Callback<number>;
}) {
    return (
        <SelectInput
            label="Season"
            options={seasons.map(s => ({
                id: checkNotNull(s.id),
                label: s.name,
            }))}
            value={seasonID}
            onChange={o => onChange(o.id)}
        />
    );
}
