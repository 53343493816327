import classnames from "classnames";
import React from "react";
import { Round } from "../../../games/models/Round";
import { RoundType, RoundTypes } from "../../models/RoundType";

export function TypeSelector({
    round,
    onSelectType,
}: {
    round: Round;
    onSelectType: (type: RoundType) => void;
}) {
    return (
        <div className="type-selector">
            <div>
                <label className="form-label">Type</label>
            </div>
            <div className="btn-group-vertical">
                {RoundTypes.getAddableTypes().map(t => (
                    <button
                        key={t.getName()}
                        className={classnames(
                            "btn",
                            "btn-default",
                            "btn-block",
                            { "btn-primary": round.type === t.getType() },
                            t.getType(),
                        )}
                        onClick={() => onSelectType(t)}
                    >
                        {t.getName()}
                    </button>
                ))}
            </div>
        </div>
    );
}
