import React, { ReactNode, useContext, useState } from "react";

type ErrorHandler = (e: Error | null) => void;
export const ErrorContext = React.createContext<ErrorHandler>(() => {});

export function ErrorDisplay({ children }: { children: ReactNode }) {
    const [error, setError] = useState<null | Error>(null);
    return (
        <ErrorContext.Provider value={setError}>
            {error && (
                <div className="error-display alert alert-danger">
                    <p>Error: {error.message}</p>
                    <button
                        className="error-display__close-button"
                        onClick={(e) => setError(null)}
                    >
                        <span className="fa fa-times"></span>
                    </button>
                </div>
            )}
            {children}
        </ErrorContext.Provider>
    );
}

export function useErrorHandler(): (e: Error) => never {
    const handler = useContext(ErrorContext);
    return (err) => {
        handler(err);
        throw err;
    };
}

export function useSafeErrorHandler(): (e: Error) => void {
    const handler = useContext(ErrorContext);
    return (err) => {
        handler(err);
    };
}
