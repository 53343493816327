import React from "react";
import { Callback } from "../../util/Callback";
import { BarGroup } from "./BarGroup";
import { ChartContainer } from "./ChartContainer";
import { ChartDimensions } from "./ChartDimensions";
import { ChartScale } from "./ChartScale";
import { Group } from "./Group";
import { GroupDimensions } from "./GroupDimensions";
import { GroupedData } from "./GroupedData";
import { Scale } from "./Scale";

export class GroupedBarChart extends React.Component<{
    data: GroupedData;
    onMouseEnter?: Callback<{ x: number; y: number; group: Group }>;
    onMouseLeave?: () => void;
}> {
    dimensions = ChartDimensions.createDefault().setHeight(200);
    groupDimensions = GroupDimensions.createDefault();

    getScale() {
        const scale = new Scale()
            .setRange(0, 5)
            .setOutputScale(0, this.dimensions.getHeight());
        this.props.data
            .getGroups()
            .forEach(g => g.getValues().forEach(v => scale.update(v)));
        return scale;
    }

    updateDimensions() {
        this.dimensions.setWidth(
            this.groupDimensions.getGroupX(
                this.props.data,
                this.props.data.getGroups().length,
            ) + this.groupDimensions.getMargin(),
        );
    }

    onMouseEnter(position: { x: number; y: number }, group: Group) {
        if (this.props.onMouseEnter != null) {
            this.props.onMouseEnter({
                x: position.x,
                y: position.y,
                group,
            });
        }
    }
    onMouseLeave() {
        if (this.props.onMouseLeave != null) {
            this.props.onMouseLeave();
        }
    }

    renderBarGroup(group: Group, index: number) {
        return (
            <g
                key={group.getLabel()}
                transform={`translate(${this.groupDimensions.getGroupX(
                    this.props.data,
                    index,
                )},0)`}
            >
                <BarGroup
                    group={group}
                    scale={this.getScale()}
                    groupDimensions={this.groupDimensions}
                    onMouseEnter={p => this.onMouseEnter(p, group)}
                    onMouseLeave={() => this.onMouseLeave()}
                />
            </g>
        );
    }

    render() {
        this.updateDimensions();
        return (
            <div className="grouped-bar-chart">
                <ChartContainer
                    className="grouped-bar-chart__chart"
                    dimensions={this.dimensions}
                >
                    <ChartScale
                        scale={this.getScale()}
                        dimensions={this.dimensions}
                    />
                    {this.props.data
                        .getGroups()
                        .map((g, index) => this.renderBarGroup(g, index))}
                </ChartContainer>
            </div>
        );
    }
}
