import { RoundType } from "../../round-editor/models/RoundType";
import { Round } from "../models/Round";
import { Seats } from "../models/Seat";
import { Winds } from "../models/Winds";
import { GameParserStrategy } from "./GameParserStrategy";

export class RoundWindCalculator extends GameParserStrategy {
    private done = false;
    private roundWind = Winds.EAST;
    private seatWind = Winds.EAST;

    constructor(private round?: Round | null) {
        super();
    }

    getRoundWind() {
        return this.roundWind;
    }

    getSeatWind() {
        return this.seatWind;
    }

    onRound(round: Round, type: RoundType) {
        if (this.round === round) {
            this.fixInconsistent(round, type);
            this.done = true;
        }
        if (this.done) {
            return;
        }
        if (!this.fixInconsistent(round, type)) {
            if (
                type.shouldAdvanceRound(
                    this.seatWind.getIndex(),
                    round.getPaymentsList(),
                    round.getPaymentSignsList(),
                )
            ) {
                this.next();
            }
        }
    }

    fixInconsistent(round: Round, type: RoundType) {
        if (!type.isWinningHand()) {
            return false;
        }
        if (!round.isDealer()) {
            return false;
        }
        if (
            round.getPaymentBySeat(this.seatWind.getSeat()).getPaymentSign() ===
            1
        ) {
            return false;
        }
        const index = round.getPaymentSignsList().indexOf(1);
        if (index === -1) {
            // eslint-disable-next-line
            console.warn(`Failed to find dealer for round ${round.toString()}`);
        }
        const seat = Seats.indexToSeat(index);
        if (seat === this.seatWind.next().getSeat()) {
            this.next();
        } else if (
            seat ===
            this.seatWind
                .next()
                .next()
                .getSeat()
        ) {
            this.next();
            this.next();
        } else if (seat === this.seatWind.previous().getSeat()) {
            this.previous();
        }
        return true;
    }

    previous() {
        if (this.seatWind.isFirst()) {
            this.roundWind = this.roundWind.previous();
        }
        this.seatWind = this.seatWind.previous();
    }

    next() {
        if (this.seatWind.isLast()) {
            this.roundWind = this.roundWind.next();
        }
        this.seatWind = this.seatWind.next();
    }
}
