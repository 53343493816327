import { DataClass } from "../DataClass";

export class GamePlayer extends DataClass<GamePlayer> {
    static create() {
        return new GamePlayer(null, null, null, 0);
    }

    static createWithoutSeat(
        playerId: number,
        score: number,
        placement: number,
    ) {
        return new GamePlayer(playerId, null, placement, score);
    }

    static forSeat(seat: number) {
        const gamePlayer = new GamePlayer(null, seat, 0, 0);
        return gamePlayer;
    }

    constructor(
        readonly playerId: number | null,
        readonly seat: number | null,
        readonly placement: number | null,
        readonly score: number,
    ) {
        super();
    }

    setSeat(seat: number | null): GamePlayer {
        return this.copy({ seat: seat });
    }

    setPlayerId(playerId: number | null) {
        return this.copy({ playerId: playerId });
    }

    setScore(score: number) {
        return this.copy({ score });
    }

    setPlacement(placement: number) {
        return this.copy({ placement });
    }
}
