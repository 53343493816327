import { Game } from "../games/models/Game";
import { Player } from "../games/models/Player";
import { GameParser } from "../games/parser/GameParser";
import { TsumoCalculator } from "../games/parser/TsumoCalculator";
import { checkNotNull } from "../util/Nullable";
import { AbstractRoundPercentStat } from "./AbstractRoundPercentStat";

export class TsumoRateStat extends AbstractRoundPercentStat {
    getLabel() {
        return "Tsumo Rate";
    }

    getId() {
        return "tsumo-rate";
    }

    requiresSeat() {
        return false;
    }

    getCount(game: Game, player: Player): number {
        return GameParser.parse(
            game,
            new TsumoCalculator(checkNotNull(game.getSeatForPlayer(player))),
        ).getCount();
    }
}
