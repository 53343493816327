import React from "react";
import { Game } from "../../games/models/Game";
import { Round } from "../../games/models/Round";
import { GameCalculatorService } from "../../games/parser/GameCalculatorService";
import { RoundTypes } from "../models/RoundType";

export class HandTableRow extends React.Component<{
    round: Round;
    game: Game;
    onDeleteRound: () => void;
}> {
    private gameCalculatorService = new GameCalculatorService();
    getRound() {
        return this.props.round;
    }

    getRoundDescription() {
        const roundWind = this.gameCalculatorService.getRoundWind(
            this.props.game,
            this.props.round,
        );
        const seatWind = this.gameCalculatorService.getSeatWind(
            this.props.game,
            this.props.round,
        );
        return `${roundWind.getCharacter()} ${seatWind.getNumber()}`;
    }

    getRoundType() {
        return RoundTypes.getRoundByType(this.getRound().getType());
    }

    renderActions() {
        if (this.getRoundType().isDeletable()) {
            return (
                <button
                    onClick={this.props.onDeleteRound}
                    className="btn btn-xs btn-danger"
                >
                    <span className="fa fa-trash" />
                </button>
            );
        }
        return null;
    }

    render() {
        const round = this.getRound();
        return (
            <tr>
                <td>
                    {this.getRoundType().getName()} {this.getRoundDescription()}
                </td>
                <td>{round.getPaymentBySeat(1).getPayment()}</td>
                <td>{round.getPaymentBySeat(2).getPayment()}</td>
                <td>{round.getPaymentBySeat(3).getPayment()}</td>
                <td>{round.getPaymentBySeat(4).getPayment()}</td>
                <td>{this.renderActions()}</td>
            </tr>
        );
    }
}
