import classnames from "classnames";
import React from "react";
import { Round } from "../../../games/models/Round";
import { Callback } from "../../../util/Callback";

export function DealerSelector({
    round,
    onSetDealer,
}: {
    round: Round;
    onSetDealer: Callback<boolean>;
}) {
    return (
        <div className="dealer-selector btn-group">
            <button
                className={classnames("btn", "dealer-selector__button", {
                    "btn-primary": round.isDealer(),
                    active: round.isDealer(),
                    "btn-default": !round.isDealer(),
                })}
                onClick={() => onSetDealer(!round.isDealer())}
            >
                Dealer
            </button>
        </div>
    );
}
