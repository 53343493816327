import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useData } from "../../app/Data";
import { PageTitle } from "../../common/PageTitle";
import { Panel } from "../../common/Panel";
import { ButtonLink } from "../../components/ButtonLink";
import { Loader } from "../../components/Loader";
import { useSeasonService } from "../../services/ServiceContext";
import { checkNotNull } from "../../util/Nullable";
import { ScoringMethod } from "../ScoringMethod";
import { Season } from "../Season";

export function SeasonsPage() {
    const seasonService = useSeasonService();
    const seasons = useData(() => seasonService.rawList());
    const [currentSeason, setCurrentSeason] = useState<Season | null>(null);

    useEffect(() => {
        seasonService.getCurrentSeason().then(setCurrentSeason);
    }, []);

    async function activateSeason(season: Season) {
        await seasonService.setCurrentSeason(season);
        seasonService.getCurrentSeason().then(setCurrentSeason);
    }

    function renderSeason(season: Season) {
        const currentId = currentSeason?.getId();
        return (
            <tr key={checkNotNull(season.getId())}>
                <td>{season.getName()}</td>
                <td>{ScoringMethod.getById(season.getScoringMethod()).name}</td>
                <td>
                    {season.getId() === currentId ? (
                        <span className="fa fa-check" />
                    ) : (
                        ""
                    )}
                </td>
                <td>
                    <Link to={`/seasons/${season.getId()}/edit`}>Edit</Link>
                    &nbsp;/&nbsp;
                    <ButtonLink onClick={() => activateSeason(season)}>
                        Activate
                    </ButtonLink>
                </td>
            </tr>
        );
    }

    if (seasons == null) {
        return <Loader />;
    }

    return (
        <div className="seasons-page">
            <PageTitle>Seasons</PageTitle>
            <Panel icon="fa-snowflake" title="Seasons">
                <p>
                    <Link to="/seasons/new" className="btn btn-default">
                        New Season
                    </Link>
                </p>
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>Season</th>
                            <th>Scoring Type</th>
                            <th>Active</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>{seasons.map((s) => renderSeason(s))}</tbody>
                </table>
            </Panel>
        </div>
    );
}
