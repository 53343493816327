import React from "react";
import { Round } from "../../../games/models/Round";
import { Callback } from "../../../util/Callback";
import { RoundTypes } from "../../models/RoundType";
import { ButtonGroupSelector } from "./ButtonGroupSelector";

const MAIN_HAN_OPTIONS = [1, 2, 3, 4, 5, 6, 7, 8];
const SECONDARY_HAN_OPTIONS = [9, 10, 11, 12, 13, 26, 39];

export function HanSelector({
    round,
    onSelectHan,
}: {
    round: Round;
    onSelectHan: Callback<number>;
}) {
    function getHan() {
        return round.getHan();
    }

    function canChangePoints() {
        return RoundTypes.getRoundByType(round.getType()).canChangePoints();
    }

    return (
        <div className="form-group han-selector">
            <div>
                <label className="form-label">Han</label>
            </div>
            <ButtonGroupSelector
                mainOptions={MAIN_HAN_OPTIONS}
                secondaryOptions={SECONDARY_HAN_OPTIONS}
                value={getHan()}
                onChange={onSelectHan}
                disabled={!canChangePoints()}
            />
        </div>
    );
}
