import lodash from "lodash";
import { RoundTypes } from "../../round-editor/models/RoundType";
import { ScoreCalculator } from "../../round-editor/ScoreCalculator";
import { ScoringMethod } from "../../seasons/ScoringMethod";
import { assert, equals } from "../../util/Assert";
import { Game } from "./Game";
import { GamePlayer } from "./GamePlayer";
import { PaymentSignType } from "./PaymentSign";
import { Round } from "./Round";
import { Seat, Seats } from "./Seat";

export class GameBuilder {
    constructor(readonly scoringMethod: ScoringMethod) {}

    game = new Game()
        .setPlayers([
            GamePlayer.forSeat(1)
                .setPlacement(1)
                .setScore(this.scoringMethod.getStartingPoints()),
            GamePlayer.forSeat(2)
                .setPlacement(2)
                .setScore(this.scoringMethod.getStartingPoints()),
            GamePlayer.forSeat(3)
                .setPlacement(3)
                .setScore(this.scoringMethod.getStartingPoints()),
            GamePlayer.forSeat(4)
                .setPlacement(4)
                .setScore(this.scoringMethod.getStartingPoints()),
        ])
        .setRotation(0)
        .addRound(
            Round.createEmpty()
                .setType(RoundTypes.INITIAL.getType())
                .setPaymentsAndSignsList(
                    [
                        this.scoringMethod.getStartingPoints(),
                        this.scoringMethod.getStartingPoints(),
                        this.scoringMethod.getStartingPoints(),
                        this.scoringMethod.getStartingPoints(),
                    ],
                    [0, 0, 0, 0],
                )
                .setHonba(0)
                .setRiichi(0)
                .setHan(0)
                .setFu(0)
                .setDealer(false)
                .setDeleted(false)
                .setCreatedAt(new Date()),
        )
        .setDraft(true);

    addRiichi(seat: Seat) {
        this.game = this.game.addRound(Round.createRiichi(seat));
        return this;
    }

    addRon(from: Seat, to: Seat, isDealer: boolean, fu: number, han: number) {
        equals(typeof han, "number");
        const calc = new ScoreCalculator();
        let round = Round.createEmpty()
            .setType(RoundTypes.RON.getType())
            .setPaymentSignsList(this._paymentSignList(from, to))
            .setFu(fu)
            .setHan(han)
            .setDealer(isDealer);
        round = calc.updateRiichiCount(this.game, round);
        round = calc.updateHonbaCount(this.game, round);
        round = calc.calculate(round);
        this.game = this.game.addRound(round);
        return this;
    }

    addTsumo(from: Seat, to: Seat, fu: number, han: number) {
        const calc = new ScoreCalculator();
        let round = Round.createEmpty()
            .setType(RoundTypes.TSUMO.getType())
            .setPaymentSignsList(this._paymentSignList(from, to))
            .setFu(fu)
            .setHan(han);
        round = calc.updateRiichiCount(this.game, round);
        round = calc.updateHonbaCount(this.game, round);
        round = calc.calculate(round);
        this.game = this.game.addRound(round);
        return this;
    }

    addDealerTsumo(dealerSeat: Seat, fu: number, han: number) {
        const calc = new ScoreCalculator();
        let round = Round.createEmpty()
            .setType(RoundTypes.TSUMO.getType())
            .setPaymentSignsList(this._singleWinnerPaymentSignList(dealerSeat))
            .setFu(fu)
            .setHan(han)
            .setDealer(true);
        round = calc.updateRiichiCount(this.game, round);
        round = calc.updateHonbaCount(this.game, round);
        round = calc.calculate(round);
        this.game = this.game.addRound(round);
        return this;
    }

    addRyuukyoku(
        seat1Tempai: boolean,
        seat2Tempai: boolean,
        seat3Tempai: boolean,
        seat4Tempai: boolean,
    ) {
        const calc = new ScoreCalculator();
        let round = Round.createEmpty()
            .setType(RoundTypes.RYUUKYOKU.getType())
            .setPaymentSignsList([
                seat1Tempai ? PaymentSignType.GAIN : PaymentSignType.LOSS,
                seat2Tempai ? PaymentSignType.GAIN : PaymentSignType.LOSS,
                seat3Tempai ? PaymentSignType.GAIN : PaymentSignType.LOSS,
                seat4Tempai ? PaymentSignType.GAIN : PaymentSignType.LOSS,
            ]);
        round = calc.calculate(round);
        this.game = this.game.addRound(round);
        return this;
    }

    setScores(scores: number[]) {
        scores.forEach((score, index) => {
            this.game = this.game.updatePlayerBySeat(
                Seats.indexToSeat(index),
                (p) => p.setScore(score),
            );
        });
        const sorted = lodash.sortBy(this.game.getPlayers(), (p) => -p.score);
        this.game = this.game.setPlayers(
            this.game.players.map((p) => {
                const index = sorted.indexOf(p);
                if (index < 0) {
                    throw new Error("Failed to sort and find");
                }
                return p.setPlacement(index + 1);
            }),
        );
        return this;
    }

    withNoSeats() {
        this.game = this.game.setPlayers(
            this.game.players.map((p) => p.setSeat(null)),
        );
        return this;
    }

    setPlacements(placements: number[]) {
        placements.forEach((placement, index) => {
            this.game = this.game.updatePlayerBySeat(
                Seats.indexToSeat(index),
                (p) => p.setPlacement(placement),
            );
        });
        return this;
    }

    setPlayers(playerIds: (number | null)[]) {
        playerIds.forEach((playerId, index) => {
            this.game = this.game.updatePlayerBySeat(
                Seats.indexToSeat(index),
                (p) => p.setPlayerId(playerId),
            );
        });
        return this;
    }

    setRotation(rotation: number) {
        this.game = this.game.setRotation(rotation);
        return this;
    }

    create() {
        return this.game.copy({
            created_at: new Date(),
            updated_at: new Date(),
        });
    }

    _paymentSignList(loser: Seat, winner: Seat) {
        Seats.assertSeat(loser);
        Seats.assertSeat(winner);
        assert(loser !== winner);
        const list = [
            PaymentSignType.NEUTRAL,
            PaymentSignType.NEUTRAL,
            PaymentSignType.NEUTRAL,
            PaymentSignType.NEUTRAL,
        ];
        list[Seats.seatToIndex(loser)] = PaymentSignType.LOSS;
        list[Seats.seatToIndex(winner)] = PaymentSignType.GAIN;
        return list;
    }

    _singleWinnerPaymentSignList(winner: Seat) {
        Seats.assertSeat(winner);
        const list = [
            PaymentSignType.LOSS,
            PaymentSignType.LOSS,
            PaymentSignType.LOSS,
            PaymentSignType.LOSS,
        ];
        list[Seats.seatToIndex(winner)] = PaymentSignType.GAIN;
        return list;
    }
}
