import { sortBy } from "lodash";
import moment, { Moment } from "moment";
import { DataClass } from "../games/DataClass";
import { Game } from "../games/models/Game";
import { Seat, Seats } from "../games/models/Seat";

type Scores = [number, number, number, number];
type Placements = [number, number, number, number];

export class FinalResults extends DataClass<FinalResults> {
    static ofGame(game: Game) {
        return new FinalResults(
            game.getPlayers().map((p) => p.score),
            game.getPlayers().map((p, i) => p.placement ?? i),
            game.getCompletedAt(),
            game.isDraft(),
        );
    }

    static default() {
        return new FinalResults(
            [25000, 25000, 25000, 25000],
            [1, 2, 3, 4],
            null,
            true,
        );
    }

    constructor(
        readonly scores: ReadonlyArray<number>,
        readonly placements: ReadonlyArray<number>,
        readonly completedAt: Moment | null,
        readonly draft: boolean,
    ) {
        super();
    }

    isDraft() {
        return this.draft;
    }

    setDraft(draft: boolean) {
        return this.copy({ draft });
    }

    getCompletedAt() {
        if (this.completedAt == null) {
            return null;
        }
        return this.completedAt.toDate();
    }

    setCompletedAt(completedAt: Date | Moment | null) {
        if (completedAt == null) {
            return this.copy({ completedAt: null });
        }
        return this.copy({ completedAt: moment(completedAt) });
    }

    setScores(scores: Scores) {
        return this.copy({ scores: scores.slice() as Scores });
    }

    getScores() {
        return this.scores.slice();
    }

    getPlacements() {
        return this.placements.slice();
    }

    setPlacements(placements: Placements) {
        return this.copy({ placements: placements.slice() });
    }

    getScoreBySeat(seat: Seat) {
        return this.scores[Seats.seatToIndex(seat)];
    }

    getPlacementBySeat(seat: Seat) {
        return this.placements[Seats.seatToIndex(seat)];
    }

    setPlacement(seat: Seat, placement: number): FinalResults {
        const placements = this.placements.slice();
        placements[Seats.seatToIndex(seat)] = placement;
        return this.copy({ placements });
    }

    setScore(seat: Seat, score: number): FinalResults {
        const scores = this.scores.slice();
        scores[Seats.seatToIndex(seat)] = score;
        return this.copy({ scores });
    }

    computePlacementsFromScore(sharedTies: boolean): FinalResults {
        const sortable = this.scores.map((score, index) => ({
            score,
            seat: Seats.indexToSeat(index),
        }));
        const sorted = sortBy(sortable, (o) => -o.score);
        const placements = Seats.all().map((seat) => {
            if (!sharedTies) {
                const index = sorted.findIndex((o) => o.seat == seat);
                if (index == -1) {
                    throw new Error(`Failed to find index for seat ${seat}`);
                }
                return index + 1;
            } else {
                const index = sorted.findIndex(
                    (o) => o.score == this.scores[Seats.seatToIndex(seat)],
                );
                return index + 1;
            }
        });
        return this.copy({ placements });
    }
}
