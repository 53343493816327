import React, { useContext } from "react";
import { Link } from "react-router-dom";
import {
    AuthContext,
    AuthRequired,
    GuestRequired,
} from "../components/AuthRequired";
import { useAuthService } from "../services/ServiceContext";

export function Header({ onToggle }: { onToggle: () => void }) {
    const authService = useAuthService();
    const context = useContext(AuthContext);

    async function logOut(e: React.MouseEvent) {
        e.preventDefault();
        await authService.logout();
        await context.reload();
    }

    return (
        <div className="header">
            <h1 className="header__title">
                <Link to="/">Mahjong</Link>
            </h1>
            <div className="header__login">
                <GuestRequired>
                    <Link to="/login">Login</Link>
                </GuestRequired>
                <AuthRequired>
                    <a onClick={(e) => logOut(e)}>Logout</a>
                </AuthRequired>
            </div>
            <button className="header__menu-toggle" onClick={() => onToggle()}>
                <span className="fa fa-lg fa-bars"></span>
            </button>
        </div>
    );
}
