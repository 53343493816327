import React from "react";
import * as ReactDOM from "react-dom";
import { Application } from "./app/Application";

function main() {
    const element = document.querySelector(".application-container");
    if (element == null) {
        throw new Error("Failed to find container");
    }
    ReactDOM.render(<Application />, element);
}

main();
