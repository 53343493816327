import { PaymentSigns } from "../../games/models/PaymentSign";
import { PointResult } from "./PointResult";
import { SignLimiter } from "./SignLimiter";

interface RequiredOptions {
    type: string;
    name: string;
    addable: boolean;
    editable: boolean;
    deletable: boolean;
    movable: boolean;
}

interface OptionalOptions {
    canChangePoints: boolean;
    isWinningHand: boolean;
    dealerPaysMore: boolean;
    addsHonba: boolean;
    endsRound: boolean;
}

type RoundOptions = RequiredOptions & Partial<OptionalOptions>;

const DEFAULT_OPTIONS: OptionalOptions = {
    canChangePoints: false,
    isWinningHand: false,
    dealerPaysMore: false,
    addsHonba: false,
    endsRound: true,
};

export abstract class RoundType {
    private options: RequiredOptions & OptionalOptions;
    constructor(options: RoundOptions) {
        this.options = Object.assign({}, DEFAULT_OPTIONS, options);
    }

    // Takes the current set of payment signs and updates them to be valid
    updatePlayerSigns(
        signs: PaymentSigns,
        isDealer: boolean,
        lastChange: number,
    ): PaymentSigns {
        return signs;
    }

    calculatePoints(
        isDealer: boolean,
        basePoints: number,
        honba: number,
        signs: PaymentSigns,
    ): PointResult {
        return new PointResult(0, 0, 0);
    }

    shouldAdvanceRound(
        currentWind: number,
        payments: number[],
        paymentSigns: PaymentSigns,
    ): boolean {
        return false;
    }

    getType() {
        return this.options.type;
    }

    getName() {
        return this.options.name;
    }

    endsRound() {
        return this.options.endsRound;
    }

    isDeletable() {
        return this.options.deletable;
    }

    dealerPaysMore() {
        return this.options.dealerPaysMore;
    }

    isAddable() {
        return this.options.addable;
    }

    canChangePoints() {
        return this.options.canChangePoints;
    }

    isWinningHand() {
        return this.options.isWinningHand;
    }

    addsHonba() {
        return this.options.addsHonba;
    }
}

function ceilHundreds(value: number) {
    return Math.ceil(value / 100) * 100;
}

class RonRoundType extends RoundType {
    constructor() {
        super({
            type: "ron",
            name: "Ron",
            addable: true,
            editable: true,
            deletable: true,
            movable: true,
            canChangePoints: true,
            isWinningHand: true,
            addsHonba: true,
        });
    }

    calculatePoints(
        isDealer: boolean,
        basePoints: number,
        honba: number,
        signs: PaymentSigns,
    ): PointResult {
        if (isDealer) {
            const loser = ceilHundreds(6 * basePoints) + honba * 300;
            return new PointResult(loser, 0, -loser);
        }
        const loser = ceilHundreds(4 * basePoints);
        return new PointResult(loser, 0, -loser);
    }

    updatePlayerSigns(
        signs: PaymentSigns,
        isDealer: boolean,
        lastChange: number,
    ): PaymentSigns {
        const limiter = new SignLimiter(1, 1, 2, 2, 1, 1);
        return limiter.limit(signs, lastChange) as PaymentSigns;
    }

    shouldAdvanceRound(
        currentWind: number,
        payments: number[],
        paymentSigns: PaymentSigns,
    ): boolean {
        return paymentSigns[currentWind] <= 0;
    }
}

class TsumoRoundType extends RoundType {
    constructor() {
        super({
            type: "tsumo",
            name: "Tsumo",
            addable: true,
            editable: true,
            deletable: true,
            movable: true,
            canChangePoints: true,
            isWinningHand: true,
            dealerPaysMore: true,
            addsHonba: true,
        });
    }

    calculatePoints(
        isDealer: boolean,
        basePoints: number,
        honba: number,
        signs: PaymentSigns,
    ): PointResult {
        if (isDealer) {
            const loser = ceilHundreds(2 * basePoints) + honba * 100;
            return new PointResult(3 * loser, 0, -loser);
        }
        const dealer = ceilHundreds(2 * basePoints);
        const loser = ceilHundreds(basePoints);
        return new PointResult(dealer + loser * 2, -loser, -dealer);
    }

    updatePlayerSigns(
        signs: PaymentSigns,
        isDealer: boolean,
        lastChange: number,
    ): PaymentSigns {
        let limiter = null;
        if (isDealer) {
            limiter = new SignLimiter(1, 1, 0, 0, 3, 3);
        } else {
            limiter = new SignLimiter(1, 1, 2, 2, 1, 1);
        }
        return limiter.limit(signs, lastChange) as PaymentSigns;
    }

    shouldAdvanceRound(
        currentWind: number,
        payments: number[],
        paymentSigns: PaymentSigns,
    ): boolean {
        return paymentSigns[currentWind] <= 0;
    }
}

class RyuukyokuRoundType extends RoundType {
    constructor() {
        super({
            type: "ryuukyoku",
            name: "Ryūkyoku",
            addable: true,
            editable: true,
            deletable: true,
            movable: true,
            addsHonba: true,
        });
    }

    calculatePoints(
        isDealer: boolean,
        basePoints: number,
        honba: number,
        signs: PaymentSigns,
    ): PointResult {
        const positiveCount = signs.filter(s => s === 1).length;
        switch (positiveCount) {
            case 4:
                return new PointResult(0, 0, 0);
            case 3:
                return new PointResult(1000, 0, -3000);
            case 2:
                return new PointResult(1500, 0, -1500);
            case 1:
                return new PointResult(3000, 0, -1000);
            case 0:
                return new PointResult(0, 0, 0);
            default:
                throw new Error(`Invalid signs ${signs}`);
        }
    }

    updatePlayerSigns(
        signs: PaymentSigns,
        isDealer: boolean,
        lastChange: number,
    ): PaymentSigns {
        const limiter = new SignLimiter(0, 4, 0, 0, 0, 4);
        return limiter.limit(signs, lastChange) as PaymentSigns;
    }

    shouldAdvanceRound(
        currentWind: number,
        payments: number[],
        paymentSigns: PaymentSigns,
    ): boolean {
        if (paymentSigns != null) {
            // If the dealer is negative, advance
            if (paymentSigns[currentWind] < 0) {
                return true;
            }
        }
        // Preserve for backwards compatability, only advance if the dealer loses points
        return payments[currentWind] < 0;
    }
}

class KyushuRoundType extends RoundType {
    constructor() {
        super({
            type: "kyushu",
            name: "Kyūshu Kyūhai",
            addable: true,
            editable: true,
            deletable: true,
            movable: true,
        });
    }

    calculatePoints(
        isDealer: boolean,
        basePoints: number,
        honba: number,
        signs: PaymentSigns,
    ): PointResult {
        return new PointResult(0, 0, 0);
    }

    updatePlayerSigns(
        signs: PaymentSigns,
        isDealer: boolean,
        lastChange: number,
    ): PaymentSigns {
        const limiter = new SignLimiter(0, 0, 4, 4, 0, 0);
        return limiter.limit(signs, -1) as PaymentSigns;
    }
}

class ChomboRoundType extends RoundType {
    constructor() {
        super({
            type: "chombo",
            name: "Chombo",
            addable: true,
            editable: true,
            deletable: true,
            movable: true,
        });
    }

    calculatePoints(
        isDealer: boolean,
        basePoints: number,
        honba: number,
        signs: PaymentSigns,
    ): PointResult {
        return new PointResult(4000, 0, -12000);
    }

    updatePlayerSigns(
        signs: PaymentSigns,
        isDealer: boolean,
        lastChange: number,
    ): PaymentSigns {
        let limiter = new SignLimiter(3, 3, 0, 0, 1, 1);
        return limiter.limit(signs, lastChange) as PaymentSigns;
    }
}

class RefundRiichiRoundType extends RoundType {
    constructor() {
        super({
            type: "refund",
            name: "Refund Riichi",
            addable: true,
            editable: true,
            deletable: true,
            movable: true,
            endsRound: false,
        });
    }

    calculatePoints(
        isDealer: boolean,
        basePoints: number,
        honba: number,
        signs: PaymentSigns,
    ): PointResult {
        return new PointResult(1000, 0, 0);
    }

    updatePlayerSigns(
        signs: PaymentSigns,
        isDealer: boolean,
        lastChange: number,
    ): PaymentSigns {
        const limiter = new SignLimiter(1, 1, 3, 3, 0, 0);
        return limiter.limit(signs, lastChange) as PaymentSigns;
    }
}

class NagashiManganRoundType extends RoundType {
    constructor() {
        super({
            type: "nagashi",
            name: "Nagashi Mangan",
            addable: true,
            editable: true,
            deletable: true,
            movable: true,
            dealerPaysMore: true,
        });
    }

    calculatePoints(
        isDealer: boolean,
        basePoints: number,
        honba: number,
        signs: PaymentSigns,
    ): PointResult {
        if (isDealer) {
            return new PointResult(12000, 0, -4000);
        }
        return new PointResult(8000, -2000, -4000);
    }

    updatePlayerSigns(
        signs: PaymentSigns,
        isDealer: boolean,
        lastChange: number,
    ): PaymentSigns {
        let limiter = new SignLimiter(1, 1, 2, 2, 1, 1);
        if (isDealer) {
            limiter = new SignLimiter(1, 1, 0, 0, 3, 3);
        }
        return limiter.limit(signs, lastChange) as PaymentSigns;
    }
}

class AdjustmentRoundType extends RoundType {
    constructor() {
        super({
            type: "adjustment",
            name: "Manual",
            addable: true,
            editable: true,
            deletable: true,
            movable: true,
            endsRound: false,
        });
    }

    updatePlayerSigns(
        signs: PaymentSigns,
        isDealer: boolean,
        lastChange: number,
    ): PaymentSigns {
        const limiter = new SignLimiter(0, 0, 0, 4, 0, 0);
        return limiter.limit(signs, -1) as PaymentSigns;
    }
}

class RiichiRoundType extends RoundType {
    constructor() {
        super({
            type: "riichi",
            name: "Rīchi",
            addable: false,
            editable: false,
            deletable: true,
            movable: true,
            endsRound: false,
        });
    }
}

class InitialRoundType extends RoundType {
    constructor() {
        super({
            type: "initial",
            name: "Start",
            addable: false,
            editable: false,
            deletable: false,
            movable: false,
            endsRound: false,
        });
    }
}

export class RoundTypes {
    static RON = new RonRoundType();
    static TSUMO = new TsumoRoundType();
    static RYUUKYOKU = new RyuukyokuRoundType();
    static KYUSHU = new KyushuRoundType();
    static CHOMBO = new ChomboRoundType();
    static REFUNDRIICHI = new RefundRiichiRoundType();
    static NAGASHIMANGAN = new NagashiManganRoundType();
    static ADJUSTMENT = new AdjustmentRoundType();
    static RIICHI = new RiichiRoundType();
    static INITIAL = new InitialRoundType();

    static getRoundByType(type: string) {
        const found = RoundTypes.values().filter(r => r.getType() === type)[0];
        if (found == null) {
            throw new Error(`Invalid round type ${type}`);
        }
        return found;
    }

    static getAddableTypes() {
        return RoundTypes.values().filter(t => t.isAddable());
    }

    static values(): RoundType[] {
        return [
            RoundTypes.RON,
            RoundTypes.TSUMO,
            RoundTypes.RYUUKYOKU,
            RoundTypes.KYUSHU,
            RoundTypes.CHOMBO,
            RoundTypes.REFUNDRIICHI,
            RoundTypes.NAGASHIMANGAN,
            RoundTypes.ADJUSTMENT,
            RoundTypes.RIICHI,
            RoundTypes.INITIAL,
        ];
    }
}
