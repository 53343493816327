import React from "react";

interface TextInputProps {
    value: string;
    label: string;
    onChange: (newValue: string) => void;
}

export class TextInput extends React.Component<TextInputProps, {}> {
    onChange(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();
        this.props.onChange(e.target.value);
    }

    render() {
        return (
            <div className="text-input">
                <label htmlFor="gen" className="text-input__label">
                    {this.props.label}
                </label>
                <input
                    type="text"
                    className="text-input__input"
                    value={this.props.value}
                    onChange={e => this.onChange(e)}
                />
            </div>
        );
    }
}
