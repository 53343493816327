import React from "react";
import { PlayerLink } from "../games/components/PlayerLink";
import { FinalScoreCalculator } from "../games/FinalScoreCalculator";
import { PlayerList } from "../games/PlayerList";
import { RankingService } from "../games/RankingService";
import { SeasonGameList } from "../http/SeasonGameList";
import { Season } from "../seasons/Season";
import { checkNotNull } from "../util/Nullable";

export class RankingTable extends React.Component<{
    games: SeasonGameList;
    players: PlayerList;
    season: Season;
}> {
    getRankings() {
        return new RankingService(
            new FinalScoreCalculator(),
        ).getNonEmptyRankings(
            this.props.games.inOrder(),
            this.props.players.getAll(),
            this.props.season,
        );
    }

    render() {
        return (
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th>Player</th>
                        <th>Score</th>
                        <th>Games Played</th>
                    </tr>
                </thead>
                <tbody>
                    {this.getRankings().map(r => (
                        <tr key={checkNotNull(r.getPlayer().getId())}>
                            <td>
                                <PlayerLink player={r.getPlayer()} />
                            </td>
                            <td>{r.getScore()}</td>
                            <td>{r.getCount()}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    }
}
