import { Game } from "../games/models/Game";
import { Player } from "../games/models/Player";
import { GamePlayerStat } from "./GamePlayerStat";

export class AverageScoreStat extends GamePlayerStat {
    getLabel() {
        return "Average Score";
    }

    getId() {
        return "average-score";
    }

    requiresSeat() {
        return false;
    }

    getStatForGames(games: Game[], player: Player) {
        const totalScore = games
            .map((g) => g.getGamePlayer(player))
            .map((p) => p.score)
            .reduce(this.sumReducer(), 0);

        return this.createAverageStatistic(totalScore, games.length);
    }
}
