import { assert } from "../../util/Assert";
import { Collections } from "../../util/Collections";
import { Seat } from "./Seat";

export class Wind {
    constructor(
        private number: number,
        private name: string,
        private character: string,
    ) {}

    advance(count: number) {
        let wind: Wind = this;
        for (let i = 0; i < count; i++) {
            wind = wind.next();
        }
        return wind;
    }

    next(): Wind {
        switch (this) {
            case Winds.EAST:
                return Winds.SOUTH;
            case Winds.SOUTH:
                return Winds.WEST;
            case Winds.WEST:
                return Winds.NORTH;
            case Winds.NORTH:
                return Winds.EAST;
            default:
                throw new Error();
        }
    }

    previous() {
        switch (this) {
            case Winds.EAST:
                return Winds.NORTH;
            case Winds.SOUTH:
                return Winds.EAST;
            case Winds.WEST:
                return Winds.SOUTH;
            case Winds.NORTH:
                return Winds.WEST;
            default:
                throw new Error();
        }
    }

    isFirst() {
        return this === Winds.EAST;
    }

    isLast() {
        return this === Winds.NORTH;
    }

    getIndex() {
        return this.number - 1;
    }

    getCharacter() {
        return this.character;
    }

    getName() {
        return this.name;
    }

    getNumber() {
        return this.number;
    }

    getSeat() {
        return this.number;
    }
}
export class Winds {
    static EAST = new Wind(1, "East", "東");
    static SOUTH = new Wind(2, "South", "南");
    static WEST = new Wind(3, "West", "西");
    static NORTH = new Wind(4, "North", "北");

    static values() {
        return [Winds.EAST, Winds.SOUTH, Winds.WEST, Winds.NORTH];
    }

    static getBySeat(s: Seat): Wind {
        assert(
            s >= 1 && s <= 4,
            `Expected seat to be in range 1 to 4 but got ${s}`,
        );
        return Collections.findOnly(
            Winds.values(),
            (w: Wind) => w.getSeat() === s,
        );
    }

    static assertWind(w: Wind) {
        assert(Winds.values().includes(w));
    }
}
