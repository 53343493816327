import React from "react";
import { ChartDimensions } from "./ChartDimensions";
import { Scale } from "./Scale";

export class LineChartRuler extends React.Component<{
    index: number;
    dimensions: ChartDimensions;
    scale: Scale;
}> {
    render() {
        return (
            <line
                className="line-chart-ruler"
                x1={this.props.scale.scale(this.props.index)}
                x2={this.props.scale.scale(this.props.index)}
                y1={0}
                y2={this.props.dimensions.getHeight()}
            />
        );
    }
}
