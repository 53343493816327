import React from "react";
import { Round } from "../../../games/models/Round";
import { Callback } from "../../../util/Callback";
import { RoundTypes } from "../../models/RoundType";
import { ButtonGroupSelector } from "./ButtonGroupSelector";

const MAIN_HONBA_OPTIONS = [0, 1, 2, 3, 4];
const SECONDARY_OPTIONS = [5, 6, 7, 8, 9];
export function HonbaSelector({
    round,
    onSelectHonba,
}: {
    round: Round;
    onSelectHonba: Callback<number>;
}) {
    function getHonba() {
        return round.getHonba();
    }

    function shouldDisable() {
        return (
            !RoundTypes.getRoundByType(round.getType()).canChangePoints() ||
            !round.isDealer()
        );
    }

    return (
        <div className="form-group honba-selector">
            <div>
                <label className="form-label">Honba</label>
            </div>
            <ButtonGroupSelector
                mainOptions={MAIN_HONBA_OPTIONS}
                secondaryOptions={SECONDARY_OPTIONS}
                value={getHonba()}
                onChange={onSelectHonba}
                disabled={shouldDisable()}
            />
        </div>
    );
}
