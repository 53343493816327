import classnames from "classnames";
import React, { createRef } from "react";
import { ChartDimensions } from "./ChartDimensions";

const PIXEL_OFFSET = 0.5;

export class ChartContainer extends React.Component<{
    children: React.ReactNode;
    className?: string;
    onMouseMove?: (x: number, y: number) => void;
    onMouseLeave?: () => void;
    dimensions: ChartDimensions;
}> {
    getTranslation() {
        const x = this.props.dimensions.getLeft() + PIXEL_OFFSET;
        const y = this.props.dimensions.getTop() + PIXEL_OFFSET;
        return `translate(${x}, ${y})`;
    }

    getWidth() {
        return (
            this.props.dimensions.getWidth() +
            this.props.dimensions.getHorizontalMargin()
        );
    }

    getHeight() {
        return (
            this.props.dimensions.getHeight() +
            this.props.dimensions.getVerticalMargin()
        );
    }

    onMouseEnter(e: React.MouseEvent<SVGSVGElement>) {
        this.dispatchMove(e.clientX, e.clientY);
    }

    onMouseLeave() {
        if (this.props.onMouseLeave) {
            this.props.onMouseLeave();
        }
    }

    dispatchMove(clientX: number, clientY: number) {
        if (!this.container.current) {
            return;
        }
        const rect = this.container.current.getBoundingClientRect();
        if (this.props.onMouseMove) {
            this.props.onMouseMove(clientX - rect.left, clientY - rect.top);
        }
    }

    container = createRef<SVGGElement>();

    render() {
        return (
            <svg
                onClick={e => this.onMouseEnter(e)}
                onMouseEnter={e => this.onMouseEnter(e)}
                onMouseMove={e => this.onMouseEnter(e)}
                onMouseLeave={e => this.onMouseLeave()}
                className={classnames("chart-container", this.props.className)}
                width={this.getWidth()}
                height={this.getHeight()}
            >
                <g transform={this.getTranslation()}>
                    <g ref={this.container} />
                    {this.props.children}
                </g>
            </svg>
        );
    }
}
