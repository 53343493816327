import { LoginPage } from "../auth/pages/LoginPage";
import { ComparePage } from "../compare/pages/ComparePage";
import { GamesEditPage } from "../games/pages/GamesEditPage";
import { DraftGamesListPage } from "../games/pages/GamesInProgressListPage";
import { GamesListPage } from "../games/pages/GamesListPage";
import { NewGamePage } from "../games/pages/NewGamePage";
import { ViewGamePage } from "../games/pages/ViewGamePage";
import { EditPlayerPage } from "../player/pages/EditPlayerPage";
import { NewPlayerPage } from "../player/pages/NewPlayerPage";
import { PlayersPage } from "../player/pages/PlayersPage";
import { ViewPlayerPage } from "../player/pages/ViewPlayerPage";
import { RankingPage } from "../rankings/pages/RankingPage";
import { RatingsPage } from "../ratings/RatingsPage";
import { RulesPage } from "../rules/pages/RulesPage";
import { EditSeasonPage } from "../seasons/pages/EditSeasonPage";
import { NewSeasonPage } from "../seasons/pages/NewSeasonPage";
import { SeasonsPage } from "../seasons/pages/SeasonsPage";
import { DashboardPage } from "./DashboardPage";
import { Page } from "./Page";

export class Pages {
    static list() {
        return [
            new Page("/", DashboardPage),
            new Page("/games", GamesListPage),
            new Page("/login", LoginPage),
            new Page("/games/draft", DraftGamesListPage),
            new Page("/games/new", NewGamePage),
            new Page("/seasons", SeasonsPage),
            new Page("/games/:id/edit", GamesEditPage),
            new Page("/players/:id/view", ViewPlayerPage),
            new Page("/games/:id/view", ViewGamePage),
            new Page("/rankings", RankingPage),
            new Page("/ratings", RatingsPage),
            new Page("/rules", RulesPage),
            new Page("/compare", ComparePage),
            new Page("/seasons/new", NewSeasonPage),
            new Page("/seasons/:id/edit", EditSeasonPage),
            new Page("/players", PlayersPage),
            new Page("/players/:id/edit", EditPlayerPage),
            new Page("/players/new", NewPlayerPage),
        ];
    }
}
