import { assert } from "../util/Assert";
import { GamePlayer } from "./models/GamePlayer";

export class Placement {
    static all() {
        return [1, 2, 3, 4];
    }

    static assert(placement: number) {
        assert(
            Placement.all().indexOf(placement) > -1,
            `Invalid placement ${placement}`,
        );
    }

    static sorter() {
        return (a: GamePlayer, b: GamePlayer) => {
            const aPlacement = a.placement;
            const bPlacement = b.placement;
            if (aPlacement == null || bPlacement == null) {
                return 0;
            }
            return aPlacement - bPlacement;
        };
    }
}
