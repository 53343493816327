import { assert } from "../../util/Assert";
import { TickScale } from "./TickScale";

function interpolate(
    value: number,
    min: number,
    max: number,
    minOut: number,
    maxOut: number,
) {
    return ((value - min) / (max - min)) * (maxOut - minOut) + minOut;
}
export class Scale {
    private min: number = 0;
    private max: number = 100;
    private outMin: number = 0;
    private outMax: number = 100;
    private center: number = 0;

    setRange(min: number, max: number) {
        this.min = min;
        this.max = max;
        return this;
    }

    update(value: number) {
        if (value < this.min) {
            this.min = value;
        }
        if (value > this.max) {
            this.max = value;
        }
        return this;
    }

    setCenter(center: number) {
        this.center = center;
        return this;
    }

    getRange() {
        return this.max - this.min;
    }

    scale(value: number) {
        return interpolate(value, this.min, this.max, this.outMin, this.outMax);
    }

    reverseScale(value: number) {
        return interpolate(value, this.outMin, this.outMax, this.min, this.max);
    }

    scalePosition(value: number) {
        return this.outMax - this.scale(value);
    }

    setOutputScale(min: number, max: number) {
        assert(min < max);
        this.outMin = min;
        this.outMax = max;
        return this;
    }

    getOutputMax() {
        return this.outMax;
    }

    getOutputMin() {
        return this.outMin;
    }

    getTicks() {
        return TickScale.getTicks(this.min, this.max, 10, this.center);
    }
}
