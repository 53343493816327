import { RoundType, RoundTypes } from "../../round-editor/models/RoundType";
import { Round } from "../models/Round";
import { Seat } from "../models/Seat";
import { GameParserStrategy } from "./GameParserStrategy";

export class RiichiCountCalculator extends GameParserStrategy {
    constructor(private seat: Seat) {
        super();
    }
    private count = 0;

    onRound(round: Round, roundType: RoundType) {
        if (
            roundType === RoundTypes.RIICHI &&
            round.getRiichiSeat() === this.seat
        ) {
            this.count++;
        }
    }

    getCount() {
        return this.count;
    }
}
