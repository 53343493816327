import { Game } from "../games/models/Game";
import { Player } from "../games/models/Player";
import { Placement } from "../games/Placement";
import { GamePlayerStat } from "./GamePlayerStat";
import { PlayerStatistic } from "./PlayerStatistic";

export class PlacementVsStat extends GamePlayerStat {
    constructor(private placement: number) {
        super();
        Placement.assert(placement);
    }

    getLabel() {
        return "VS Placement";
    }

    getId() {
        return `placement-vs-${this.placement}`;
    }

    isSingleValue() {
        return false;
    }

    getStatForGames(games: Game[], player: Player) {
        const byPlayerId = new Map();
        games
            .filter(
                (game) =>
                    game.getGamePlayer(player).placement === this.placement,
            )
            .forEach((game) =>
                game.getPlayers().forEach((gamePlayer) => {
                    const id = gamePlayer.playerId;
                    const previous = byPlayerId.get(id) || 0;
                    byPlayerId.set(id, previous + 1);
                }),
            );
        return PlayerStatistic.fromMapCountExcludingPlayer(
            this.getLabel(),
            this.getId(),
            byPlayerId,
            player.getIdChecked(),
        );
    }
}
