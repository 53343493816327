import { Game } from "../games/models/Game";
import { Player } from "../games/models/Player";
import { GamePlayerStat } from "./GamePlayerStat";

export abstract class AbstractGamePercentStat extends GamePlayerStat {
    getStatForGames(games: Game[], player: Player) {
        const count = this.getCount(games, player);
        return this.createPercentStatistic(count, games.length);
    }

    requiresSeat() {
        return false;
    }

    abstract getCount(games: Game[], player: Player): number;
}
