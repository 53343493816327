import React from "react";
import { Wind, Winds } from "../games/models/Winds";
import { StatisticCollection } from "./StatisticCollection";

export class WindPlacementTable extends React.Component<{
    statisticCollection: StatisticCollection;
}> {
    renderRow(wind: Wind) {
        return (
            <tr key={wind.getSeat()}>
                <td>{wind.getName()}</td>
                <td>
                    {this.props.statisticCollection
                        .getById(`expected-placement-${wind.getSeat()}`)
                        .formatValue()}
                </td>
            </tr>
        );
    }

    render() {
        return (
            <div className="wind-placement-table">
                <table className="table table-bordered wind-placement-table__table">
                    <thead>
                        <tr>
                            <th>Seat</th>
                            <th>Expected Placement</th>
                        </tr>
                    </thead>
                    <tbody>{Winds.values().map(w => this.renderRow(w))}</tbody>
                </table>
            </div>
        );
    }
}
