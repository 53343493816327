import { sum } from "lodash";
import moment from "moment";
import { RoundTypes } from "../../round-editor/models/RoundType";
import { ScoringMethod } from "../../seasons/ScoringMethod";
import { assert } from "../../util/Assert";
import { checkNotNull } from "../../util/Nullable";
import { DataClass } from "../DataClass";
import { GameBuilder } from "./GameBuilder";
import { GamePlayer } from "./GamePlayer";
import { Player } from "./Player";
import { Round } from "./Round";
import { Seat, Seats } from "./Seat";

export class Game extends DataClass<Game> {
    static builder(scoringMethod: ScoringMethod) {
        return new GameBuilder(scoringMethod);
    }

    readonly id: number = 0;
    readonly season_id: number = 0;
    readonly draft: boolean = false;
    readonly delete_reason: string | null = null;
    readonly players: ReadonlyArray<GamePlayer> = [];
    readonly rounds: ReadonlyArray<Round> = [];
    readonly rotation: number | null = null;
    readonly created_at: Date | null = null;
    readonly completed_at: Date | null = null;
    readonly updated_at: Date | null = null;

    getTotalStartingPoints() {
        const firstRound = this.rounds[0];
        if (firstRound.type != RoundTypes.INITIAL.getType()) {
            throw new Error(`Invalid starting round ${firstRound.type}`);
        }
        return sum(firstRound.payments.map((p) => p.getPayment()));
    }

    getId() {
        return this.id;
    }

    setId(id: number) {
        return this.copy({ id });
    }

    isDraft() {
        return this.draft;
    }

    setDraft(draft: boolean) {
        return this.copy({ draft });
    }

    getPlayers() {
        return this.players.slice();
    }

    getOrderedPlayers() {
        return this.players
            .slice()
            .sort((a, b) => (a.placement ?? 0) - (b.placement ?? 0));
    }

    getRounds() {
        return this.rounds;
    }

    updateRound(index: number, update: (round: Round) => Round): Game {
        const rounds = this.rounds.slice();
        rounds[index] = update(rounds[index]);
        return this.copy({ rounds });
    }

    getNonDeletedRounds() {
        return this.rounds.filter((r) => !r.getDeleted());
    }

    addRound(round: Round) {
        return this.copy({ rounds: [...this.rounds, round] });
    }

    setRounds(rounds: Round[]) {
        return this.copy({ rounds });
    }

    getPlayerBySeat(seat: number) {
        return this.getPlayers()[this.getPlayerIndexBySeat(seat)];
    }

    updatePlayerBySeat(seat: number, update: (p: GamePlayer) => GamePlayer) {
        const index = this.getPlayerIndexBySeat(seat);
        return this.copy({
            players: this.getPlayers().map((p, i) => {
                if (i == index) {
                    return update(p);
                } else {
                    return p;
                }
            }),
        });
    }

    private getPlayerIndexBySeat(seat: number) {
        assert(
            seat >= 1 && seat <= 4,
            `Expected a seat from 1 to 4 but got ${seat}`,
        );
        let index = this.getPlayers().findIndex((p) => p.seat === seat);
        if (index > -1) {
            return index;
        }
        if (this.getPlayers().every((p) => p.seat == null)) {
            return seat - 1;
        }
        throw new Error(`Failed to get player in seat ${seat}`);
    }

    getGamePlayer(player: Player) {
        const gamePlayer = this.players.find(
            (p) => p.playerId === player.getId(),
        );
        return checkNotNull(gamePlayer);
    }

    setPlayers(players: GamePlayer[]) {
        return this.copy({ players });
    }

    getSeatForPlayer(player: Player): Seat {
        const seat = this.getGamePlayer(player).seat;
        if (seat == null) {
            const index = this.players.findIndex(
                (p) => p.playerId === player.getId(),
            );
            assert(index > -1);
            return Seats.indexToSeat(index);
        }
        return seat;
    }

    getCompletedAt() {
        if (this.completed_at != null) {
            return moment(this.completed_at);
        }
        return null;
    }

    setCompletedAt(isCompleted: null | Date) {
        assert(isCompleted === null || isCompleted instanceof Date);
        return this.copy({ completed_at: isCompleted });
    }

    getSeasonId() {
        return this.season_id;
    }

    hasPlayer(player: Player) {
        assert(player instanceof Player);
        return this.players.some((p) => p.playerId === player.getId());
    }

    getRotation() {
        return this.rotation;
    }

    setRotation(rotation: number) {
        return this.copy({ rotation });
    }

    setDeleteReason(reason: string | null) {
        return this.copy({ delete_reason: reason });
    }

    setSeasonId(season: number) {
        return this.copy({ season_id: season });
    }

    getDeleteReason() {
        return this.delete_reason;
    }

    isDeleted() {
        return !!this.delete_reason;
    }
}
