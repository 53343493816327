import React, { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router";
import { PageTitle } from "../../common/PageTitle";
import { Panel } from "../../common/Panel";
import { Loader } from "../../components/Loader";
import { useSeasonService } from "../../services/ServiceContext";
import { Season } from "../Season";
import { SeasonForm } from "./SeasonForm";

export function EditSeasonPage() {
    const { id } = useParams<{ id: string }>();

    const [season, setSeason] = useState<Season | null>(null);
    const seasonService = useSeasonService();
    const [updated, setUpdated] = useState(false);
    useEffect(() => {
        seasonService.get(id).then(setSeason);
    }, [id]);

    async function saveSeason() {
        if (season == null) {
            return;
        }
        await seasonService.update(season);
        setUpdated(true);
    }

    if (updated) {
        return <Redirect to="/seasons" />;
    }
    if (!season) {
        return <Loader />;
    }
    return (
        <div className="edit-season-page">
            <PageTitle>Update Season</PageTitle>
            <Panel title="Edit Season" icon="fa-calendar">
                <SeasonForm
                    season={season}
                    onChange={setSeason}
                    onSubmit={() => saveSeason()}
                    actionLabel="Update"
                />
            </Panel>
        </div>
    );
}
