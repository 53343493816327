import { Game } from "../games/models/Game";
import { Player } from "../games/models/Player";
import { GameParser } from "../games/parser/GameParser";
import { WinCountCalculator } from "../games/parser/WinCountCalculator";
import { AbstractRoundPercentStat } from "./AbstractRoundPercentStat";

export class RoundWinRateStat extends AbstractRoundPercentStat {
    getLabel() {
        return "Round Win Rate";
    }

    getId() {
        return "round-win-rate";
    }

    getCount(game: Game, player: Player) {
        const seat = game.getSeatForPlayer(player);
        return GameParser.parse(game, new WinCountCalculator(seat)).getCount();
    }
}
