import React from "react";
import { Link } from "react-router-dom";
import { Game } from "../models/Game";

export class GameLink extends React.Component<{
    game: Game;
    className?: string;
    children: React.ReactNode;
}> {
    render() {
        return (
            <Link
                to={`/games/${this.props.game.getId()}/view`}
                className={this.props.className}
            >
                {this.props.children}
            </Link>
        );
    }
}
