import lodash from "lodash";
import { Collections } from "../../util/Collections";

interface Value {
    label: string;
    value: number;
}

export class Group {
    static create(label: string) {
        return new Group(label);
    }

    values: Value[] = [];
    constructor(private label: string) {}

    add(label: string, value: number) {
        this.values.push({ label, value });
        return this;
    }

    getTotal() {
        return lodash.sum(this.values.map(v => v.value));
    }

    getLabel() {
        return this.label;
    }

    getLabels() {
        return this.values.map(v => v.label);
    }

    getValues() {
        return this.values.map(v => v.value);
    }

    getValue(label: string): number {
        return Collections.findOnly(this.values, v => v.label === label).value;
    }
}
