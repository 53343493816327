import classnames from "classnames";
import React, { ChangeEvent } from "react";
import { Callback } from "../../../util/Callback";

export function PlayerSignScore({
    value,
    sign,
    onChangeSign,
    onChangeValue,
}: {
    value: number;
    sign: number;
    onChangeSign: Callback<number>;
    onChangeValue: Callback<number>;
}) {
    function signIs(s: number) {
        return sign === s;
    }

    function onChange(e: ChangeEvent<HTMLInputElement>) {
        onChangeValue(parseInt(e.target.value, 10));
    }

    function onSetPositive() {
        onChangeSign(1);
    }

    function onSetNegative() {
        onChangeSign(-1);
    }

    return (
        <div className="input-group">
            <input
                type="number"
                max="400000"
                min="-400000"
                step="100"
                className="form-control"
                value={value}
                onChange={e => onChange(e)}
            />
            <span className="input-group-btn">
                <button
                    className={classnames("btn btn-default", {
                        "btn-success": signIs(1),
                    })}
                    onClick={() => onSetPositive()}
                >
                    <span className="fa fa-plus" />
                </button>
                <button
                    className={classnames("btn btn-default", {
                        "btn-danger": signIs(-1),
                    })}
                    onClick={() => onSetNegative()}
                >
                    <span className="fa fa-minus" />
                </button>
            </span>
        </div>
    );
}
