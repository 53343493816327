import React from "react";
import { PageTitle } from "../common/PageTitle";
import { Panel } from "../common/Panel";
import { GamesTable } from "../games/components/GamesTable";
import { Pagination } from "../games/components/Pagination";
import { Game } from "../games/models/Game";
import { Player } from "../games/models/Player";
import { PlayerList } from "../games/PlayerList";
import { SeasonGameList } from "../http/SeasonGameList";
import { Season } from "../seasons/Season";
import { SeasonSelector } from "../seasons/SeasonSelector";
import { PlayerStatisticsService } from "./PlayerStatisticsService";
import { PlayerStats } from "./PlayerStats";
import { PlayerVsStats } from "./PlayerVsStats";

export class ViewPlayer extends React.Component<{
    player: Player;
    players: PlayerList;
    games: SeasonGameList;
    seasons: Season[];
    season: Season;
    pageNumber: number;
}> {
    render() {
        const filter = (game: Game) => game.hasPlayer(this.props.player);
        const stats = new PlayerStatisticsService().compute(
            this.props.games.inOrder(),
            this.props.player,
        );
        return (
            <div className="view-player">
                <PageTitle>
                    Viewing Player {this.props.player.getName()}
                </PageTitle>
                <SeasonSelector
                    seasons={this.props.seasons}
                    season={this.props.season}
                />
                <PlayerStats
                    player={this.props.player}
                    statisticCollection={stats}
                />
                <PlayerVsStats
                    players={this.props.players}
                    statisticCollection={stats}
                />
                <Panel
                    title={`${this.props.player.getName()} - Games`}
                    icon="fa-list"
                >
                    <GamesTable
                        players={this.props.players}
                        games={this.props.games}
                        season={this.props.season}
                        highlightPlayer={this.props.player}
                        pageNumber={this.props.pageNumber}
                        filter={filter}
                    />
                    <Pagination
                        count={this.props.games.getCount(filter)}
                        current={this.props.pageNumber}
                        season={this.props.season}
                    />
                </Panel>
            </div>
        );
    }
}
