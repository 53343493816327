import { RoundType, RoundTypes } from "../../round-editor/models/RoundType";
import { assert } from "../../util/Assert";
import { Round } from "../models/Round";
import { Seat, Seats } from "../models/Seat";
import { GameParserStrategy } from "./GameParserStrategy";
import { PlayerRiichis } from "./PlayerRiichis";

export class RoundGroupingCalculator extends GameParserStrategy {
    private rounds: Round[] = [];
    private riichiCount = new Map();
    private currentRiichis = new PlayerRiichis();

    onRoundEnd(round: Round) {
        this.riichiCount.set(round, this.currentRiichis);
        this.rounds.push(round);
        this.resetRiichis();
    }

    resetRiichis() {
        this.currentRiichis = new PlayerRiichis();
    }

    onRound(round: Round, type: RoundType) {
        if (type === RoundTypes.RIICHI) {
            this.currentRiichis.setHasRiichied(round.getRiichiSeat());
        }
    }

    getRounds() {
        return this.rounds.slice();
    }

    getRiichis(round: Round) {
        assert(this.riichiCount.has(round));
        return this.riichiCount.get(round);
    }

    hasRiichied(round: Round, seat: Seat) {
        Seats.assertSeat(seat);
        return this.getRiichis(round).getHasRiichied(seat);
    }
}
