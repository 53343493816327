import React from "react";
import { useRouteMatch } from "react-router";
import { Loader } from "../../components/Loader";
import { useSafeErrorHandler } from "../../errors/ErrorDisplay";
import { FinalResults } from "../../round-editor/FinalResults";
import { RoundEditor } from "../../round-editor/components/RoundEditor";
import { useSeasons } from "../../seasons/SeasonsContext";
import {
    useAuthService,
    useGamesService,
    usePlayerService,
} from "../../services/ServiceContext";
import { PlayerList } from "../PlayerList";
import { Game } from "../models/Game";

export function GamesEditPage() {
    const match = useRouteMatch<{ id: string }>();
    const gamesService = useGamesService();
    const playersService = usePlayerService();
    const seasons = useSeasons();
    const authService = useAuthService();

    const [game, setGame] = React.useState<Game | null>(null);
    const [updateCounter, setUpdateCounter] = React.useState(1);
    const [finalResults, setFinalResults] = React.useState<FinalResults | null>(
        null,
    );
    const [players, setPlayers] = React.useState<PlayerList | null>(null);
    const showError = useSafeErrorHandler();

    React.useEffect(() => {
        authService.current().then((user) => {
            if (user == null) {
                showError(new Error("Not logged in"));
            }
        });
    }, []);

    React.useEffect(() => {
        const id = match.params.id;
        if (id != null) {
            gamesService.get(id).then((game) => {
                setGame(game);
                setFinalResults(FinalResults.ofGame(game));
            });
            playersService.list().then(setPlayers);
        }
    }, [match.params.id]);

    function onChange({
        game: updateGame,
        finalResults,
    }: {
        game?: Game;
        finalResults?: FinalResults;
    }) {
        if (updateGame != null) {
            gamesService.update(updateGame).catch((e) => showError(e));
            setUpdateCounter(updateCounter + 1);
            setGame(updateGame);
        }
        if (finalResults != null) {
            setFinalResults(finalResults);
            setUpdateCounter(updateCounter + 1);
        }
    }

    if (
        game == null ||
        finalResults == null ||
        players == null ||
        seasons == null
    ) {
        return <Loader />;
    }
    return (
        <RoundEditor
            game={game}
            players={players}
            finalResults={finalResults}
            onChange={onChange}
            seasons={seasons}
        />
    );
}
