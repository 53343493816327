import { clamp } from "lodash";
import React from "react";
import { Callback } from "../../util/Callback";
import { ChartContainer } from "./ChartContainer";
import { ChartDimensions } from "./ChartDimensions";
import { ChartScale } from "./ChartScale";
import { LineChartLine } from "./LineChartLine";
import { LineChartRuler } from "./LineChartRuler";
import { Scale } from "./Scale";
import { SeriesData } from "./SeriesData";

const POINT_SPACING = 30;
export function computeIndex(
    position: number,
    max: number = Number.MAX_SAFE_INTEGER,
) {
    const index = Math.round((position - POINT_SPACING) / POINT_SPACING);
    return clamp(index, 0, max);
}

export function calculatePosition(index: number) {
    return index * POINT_SPACING + POINT_SPACING;
}

export class LineChart extends React.Component<
    {
        lines: SeriesData[];
        onSelect?: Callback<number>;
        center: number;
        dimensions: ChartDimensions;
        showPoints: boolean;
        showCursor: boolean;
        scaleX: boolean;
    },
    { selectedIndex: number | null }
> {
    state = {
        selectedIndex: null,
    };

    getScale(dimensions: ChartDimensions) {
        const scale = new Scale()
            .setCenter(this.props.center)
            .setRange(this.props.center, this.props.center)
            .setOutputScale(0, dimensions.getHeight());
        this.props.lines.forEach((line) => {
            line.getValues().forEach((v) => scale.update(v));
        });
        return scale;
    }

    onMouseMove(x: number) {
        const nearest = clamp(
            Math.round(this.getXScale().reverseScale(x)),
            0,
            this.xPoints(),
        );
        this.setState({ selectedIndex: nearest });
        this.props.onSelect?.(clamp(nearest - 1, 0, this.xPoints() - 1));
    }

    renderCursor(dimensions: ChartDimensions) {
        if (this.state.selectedIndex == null || !this.props.showCursor) {
            return null;
        }
        return (
            <LineChartRuler
                dimensions={dimensions}
                index={this.state.selectedIndex || 0}
                scale={this.getXScale()}
            />
        );
    }

    xPoints() {
        return Math.max(...this.props.lines.map((l) => l.getValues().length));
    }

    getXScale() {
        const count = this.xPoints();
        const scale = new Scale().setRange(0, count);
        if (this.props.scaleX) {
            scale.setOutputScale(0, this.props.dimensions.getWidth());
        } else {
            scale.setOutputScale(0, POINT_SPACING * count);
        }
        return scale;
    }

    render() {
        const originalDimensions = this.props.dimensions;
        const dimensions = originalDimensions.copy();
        const xScale = this.getXScale();
        dimensions.setWidth(
            Math.max(xScale.getOutputMax(), originalDimensions.getWidth()),
        );
        const scale = this.getScale(dimensions);
        return (
            <ChartContainer
                className="line-chart"
                dimensions={dimensions}
                onMouseMove={(x: number, y: number) => this.onMouseMove(x)}
            >
                {this.props.lines.map((series, index) => (
                    <LineChartLine
                        key={series.getLabel()}
                        series={series}
                        index={index}
                        scale={scale}
                        xScale={xScale}
                        highlightIndex={this.state.selectedIndex}
                        initalValue={this.props.center}
                        showPoints={this.props.showPoints}
                    />
                ))}
                <ChartScale scale={scale} dimensions={dimensions} />
                {this.renderCursor(dimensions)}
            </ChartContainer>
        );
    }
}
