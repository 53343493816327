import moment from "moment";
import React from "react";
import { Callback } from "../../../util/Callback";
import { FinalResults } from "../../FinalResults";

export function GameCompleteSelector({
    finalResults,
    onSetDraft,
}: {
    finalResults: FinalResults;
    onSetDraft: Callback<boolean>;
}) {
    function formatCompletedAt() {
        const completedAt = finalResults.getCompletedAt();
        if (completedAt != null) {
            return moment(completedAt).format("lll");
        }
        return "";
    }

    return (
        <div>
            <div className="radio">
                <label>
                    <input
                        type="radio"
                        name="completeRadio"
                        checked={finalResults.isDraft()}
                        onChange={() => onSetDraft(true)}
                    />
                    Draft
                </label>
            </div>
            <div className="radio">
                <label>
                    <input
                        type="radio"
                        name="completeRadio"
                        checked={!finalResults.isDraft()}
                        onChange={() => onSetDraft(false)}
                    />
                    Complete
                </label>
            </div>
            <div>Completed At: {formatCompletedAt()}</div>
        </div>
    );
}
