import React from "react";
import { Game } from "../../games/models/Game";
import { Seat } from "../../games/models/Seat";
import { PlayerList } from "../../games/PlayerList";

export class HandTableHeader extends React.Component<{
    players: PlayerList;
    game: Game;
}> {
    getPlayerName(seat: Seat) {
        return this.props.players.getName(
            this.props.game.getPlayerBySeat(seat).playerId,
        );
    }

    render() {
        return (
            <tr>
                <th className="col-sm-2">Type</th>
                <th className="col-sm-2">{this.getPlayerName(1)}</th>
                <th className="col-sm-2">{this.getPlayerName(2)}</th>
                <th className="col-sm-2">{this.getPlayerName(3)}</th>
                <th className="col-sm-2">{this.getPlayerName(4)}</th>
                <th className="col-sm-2">Actions</th>
            </tr>
        );
    }
}
