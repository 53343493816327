import moment from "moment";
import { Round } from "../models/Round";
import { GameParserStrategy } from "./GameParserStrategy";

export class RoundTimeCalculator extends GameParserStrategy {
    times: number[] = [];
    lastTime: Date | null = null;

    onRoundEnd(round: Round) {
        if (this.lastTime != null && round.getCreatedAt() != null) {
            this.times.push(
                moment(round.getCreatedAt()).diff(
                    moment(this.lastTime),
                    "seconds",
                ),
            );
        }
        this.lastTime = round.getCreatedAt();
    }

    getTimes() {
        return this.times;
    }
}
