import React from "react";
import { Link } from "react-router-dom";
import { Season } from "../../seasons/Season";
import { Player } from "../models/Player";

export class PlayerLink extends React.Component<{
    player: Player;
    season?: Season;
}> {
    render() {
        const s =
            this.props.season != null ? `?season=${this.props.season.id}` : "";
        return (
            <Link to={`/players/${this.props.player.getId()}/view${s}`}>
                {this.props.player.getName()}
            </Link>
        );
    }
}
