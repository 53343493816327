import { Game } from "../games/models/Game";
import { Player } from "../games/models/Player";
import { GameParser } from "../games/parser/GameParser";
import { RiichiCountCalculator } from "../games/parser/RiichiCountCalculator";
import { AbstractRoundPercentStat } from "./AbstractRoundPercentStat";

export class RiichiRateStat extends AbstractRoundPercentStat {
    getLabel() {
        return "Riichi Rate";
    }

    getId() {
        return "riichi-rate";
    }

    getCount(game: Game, player: Player) {
        const seat = game.getSeatForPlayer(player);
        return GameParser.parse(
            game,
            new RiichiCountCalculator(seat),
        ).getCount();
    }
}
