import React from "react";
import { Round } from "../../../games/models/Round";
import { Callback } from "../../../util/Callback";
import { FuValidator } from "../../FuValidator";
import { RoundTypes } from "../../models/RoundType";
import { ButtonGroupSelector } from "./ButtonGroupSelector";

const MAIN_FOO_OPTIONS = [20, 25, 30, 40, 50];
const SECONDARY_FOO_OPTIONS = [60, 70, 80, 90, 100, 110, 120];

export function FuSelector({
    round,
    onSelectFu,
}: {
    round: Round;
    onSelectFu: Callback<number>;
}) {
    const canChangePoints = RoundTypes.getRoundByType(
        round.getType(),
    ).canChangePoints();

    return (
        <div className="form-group fu-selector">
            <div>
                <label className="form-label">Fu</label>
            </div>
            <ButtonGroupSelector
                mainOptions={MAIN_FOO_OPTIONS}
                secondaryOptions={SECONDARY_FOO_OPTIONS}
                value={round.getFu()}
                onChange={(fu) => onSelectFu(fu)}
                disabledOptions={
                    FuValidator.hasFuRestriction(round)
                        ? [FuValidator.RestrictedFu]
                        : []
                }
                disabled={!canChangePoints}
            />
        </div>
    );
}
