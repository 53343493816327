export class SeriesData {
    constructor(private label: string, private values: number[]) {}

    getValues() {
        return this.values.slice();
    }

    getLabel() {
        return this.label;
    }
}
