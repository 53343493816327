import { assert } from "../util/Assert";
import { Player } from "./models/Player";

export class PlayerList {
    constructor(private players: Player[]) {}

    getName(id: number | null) {
        return this.getById(id).getName();
    }

    getById(id: number | null) {
        return this.listWithEmpty().filter((p) => p.getId() === id)[0];
    }

    getByIdChecked(id: number) {
        const player = this.getById(id);
        assert(player != null, `No player with id ${id} found`);
        return player;
    }

    listWithEmpty() {
        return [Player.emptyPlayer(), ...this.players];
    }

    getAll() {
        return this.players.slice();
    }
}
