import React from "react";
import { Link } from "react-router-dom";
import { Game } from "../models/Game";

export class EditGameLink extends React.Component<{
    game: Game;
    children: React.ReactNode;
}> {
    render() {
        return (
            <Link to={`/games/${this.props.game.getId()}/edit`}>
                {this.props.children}
            </Link>
        );
    }
}
