import React from "react";
import { PageTitle } from "../../common/PageTitle";
import { Panel } from "../../common/Panel";
import { SeasonGameList } from "../../http/SeasonGameList";
import { Season } from "../../seasons/Season";
import { PlayerList } from "../PlayerList";
import { GamesTable } from "./GamesTable";

export class GamesDraftList extends React.Component<{
    games: SeasonGameList;
    players: PlayerList;
    currentSeason: Season;
}> {
    render() {
        return (
            <div className="games-draft-list">
                <PageTitle>Draft Games</PageTitle>
                <Panel icon="fa-trophy" title="Games In Progress">
                    <GamesTable
                        games={this.props.games}
                        players={this.props.players}
                        season={this.props.currentSeason}
                        pageNumber={1}
                    />
                </Panel>
            </div>
        );
    }
}
