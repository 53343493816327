import React from "react";
import { Game } from "../../games/models/Game";
import { Round } from "../../games/models/Round";
import { PlayerList } from "../../games/PlayerList";
import { Callback } from "../../util/Callback";
import { HandTableFooter } from "./HandTableFooter";
import { HandTableHeader } from "./HandTableHeader";
import { HandTableRow } from "./HandTableRow";

export class HandTable extends React.Component<{
    game: Game;
    players: PlayerList;
    onDeleteRound: Callback<Round>;
    onRiichi: Callback<Round>;
}> {
    getRounds() {
        return this.props.game.getNonDeletedRounds();
    }

    render() {
        return (
            <div>
                <table className="table table-condensed table-bordered table-striped hand-table">
                    <thead>
                        <HandTableHeader
                            game={this.props.game}
                            players={this.props.players}
                        />
                    </thead>
                    <tbody>
                        {this.getRounds().map((round, index) => (
                            <HandTableRow
                                game={this.props.game}
                                round={round}
                                key={index}
                                onDeleteRound={() =>
                                    this.props.onDeleteRound(round)
                                }
                            />
                        ))}
                    </tbody>
                    <HandTableFooter
                        game={this.props.game}
                        players={this.props.players}
                        onRiichi={this.props.onRiichi}
                    />
                </table>
            </div>
        );
    }
}
