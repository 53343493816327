import React, { useState } from "react";
import { Redirect } from "react-router";
import { PageTitle } from "../../common/PageTitle";
import { Panel } from "../../common/Panel";
import { useSeasonService } from "../../services/ServiceContext";
import { Season } from "../Season";
import { SeasonForm } from "./SeasonForm";

export function NewSeasonPage() {
    const [season, setSeason] = useState<Season>(Season.create());
    const [created, setCreated] = useState(false);

    const seasonService = useSeasonService();

    async function createSeason() {
        await seasonService.create(season);
        setCreated(true);
    }

    if (created) {
        return <Redirect to="/seasons" />;
    }
    return (
        <div className="new-season-page">
            <PageTitle>New Season</PageTitle>
            <Panel title="New Season" icon="fa-calendar">
                <SeasonForm
                    season={season}
                    onChange={(s) => setSeason(s)}
                    onSubmit={() => createSeason()}
                    actionLabel="Create"
                />
            </Panel>
        </div>
    );
}
