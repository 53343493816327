import React from "react";
import { GameLink } from "../games/components/GameLink";
import { Game } from "../games/models/Game";
import { Placement } from "../games/Placement";
import { PlayerList } from "../games/PlayerList";
import { SeasonGameList } from "../http/SeasonGameList";
import { Season } from "../seasons/Season";

export class RecentGames extends React.Component<{
    games: SeasonGameList;
    players: PlayerList;
    season: Season;
}> {
    getWinner(game: Game) {
        const first = game.getPlayers().sort(Placement.sorter())[0];
        return this.props.players.getById(first.playerId);
    }

    renderGame(game: Game) {
        return (
            <GameLink
                className="list-group-item"
                game={game}
                key={game.getId()}
            >
                Game #{this.props.games.getGameNumber(game)} of{" "}
                {this.props.season.getName()} won by{" "}
                {this.getWinner(game).getName()}
            </GameLink>
        );
    }

    render() {
        return (
            <div className="list-group">
                {this.props.games
                    .getLatestPage(1)
                    .map((g) => this.renderGame(g))}
            </div>
        );
    }
}
