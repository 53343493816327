import React from "react";
import { PlayerLink } from "../games/components/PlayerLink";
import { GameInfoService } from "../games/GameInfoService";
import { Game } from "../games/models/Game";
import { GamePlayer } from "../games/models/GamePlayer";
import { Placement } from "../games/Placement";
import { PlayerList } from "../games/PlayerList";
import { PlayerRanking } from "../games/PlayerRanking";
import { Season } from "../seasons/Season";

export class GameRankingScorePanel extends React.Component<{
    game: Game;
    rankings: PlayerRanking[];
    players: PlayerList;
    season: Season;
}> {
    getPlayers() {
        return this.props.game.getPlayers().slice().sort(Placement.sorter());
    }

    getRankingForPlayer(gamePlayer: GamePlayer): number | null {
        const ranking = this.props.rankings.find(
            (r) => r.getPlayer().getId() === gamePlayer.playerId,
        );
        if (ranking == null) {
            return null;
        }
        return ranking.getScore();
    }

    render() {
        return (
            <table className="table table-bordered game-ranking-score-panel">
                <thead>
                    <tr>
                        <th>Player</th>
                        <th>Placement</th>
                        <th>Final Score</th>
                        <th>Total Score</th>
                    </tr>
                </thead>
                <tbody>
                    {this.getPlayers().map((p, i) => (
                        <tr key={i}>
                            <td>
                                <PlayerLink
                                    player={this.props.players.getById(
                                        p.playerId,
                                    )}
                                />
                            </td>
                            <td>{p.placement}</td>
                            <td className="game-ranking-score-panel__game-score">
                                {new GameInfoService().formatFinalScore(
                                    this.props.season,
                                    this.props.game,
                                    p,
                                )}
                            </td>
                            <td className="game-ranking-score-panel__total-score">
                                {this.getRankingForPlayer(p)}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    }
}
