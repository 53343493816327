import { useEffect, useState } from "react";
import { useErrorHandler } from "../errors/ErrorDisplay";

export function useData<T extends {}>(
    loader: () => Promise<T>,
    dependencies: any[] = [],
): T | null {
    const errorHandler = useErrorHandler();
    const [state, setState] = useState<T | null>(null);

    useEffect(() => {
        loader().catch(errorHandler).then(setState);
    }, dependencies);

    return state;
}
