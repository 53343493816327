import React from "react";
import { Panel } from "../common/Panel";
import { PlayerList } from "../games/PlayerList";
import { SeasonGameList } from "../http/SeasonGameList";
import { Season } from "../seasons/Season";
import { RankingTable } from "./RankingTable";
import { RecentGames } from "./RecentGames";

export class Dashboard extends React.Component<{
    games: SeasonGameList;
    players: PlayerList;
    season: Season;
}> {
    render() {
        return (
            <div className="dashboard">
                <div className="row">
                    <div className="col-sm-6">
                        <Panel icon="fa-history" title="Recent Games">
                            <RecentGames
                                games={this.props.games}
                                players={this.props.players}
                                season={this.props.season}
                            />
                        </Panel>
                    </div>
                    <div className="col-sm-6">
                        <Panel icon="fa-chart-bar" title="Current Rankings">
                            <RankingTable
                                games={this.props.games}
                                players={this.props.players}
                                season={this.props.season}
                            />
                        </Panel>
                    </div>
                </div>
            </div>
        );
    }
}
