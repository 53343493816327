import React from "react";
import { Panel } from "../../common/Panel";
import { PlayerList } from "../../games/PlayerList";
import { Game } from "../../games/models/Game";
import { Player } from "../../games/models/Player";
import { Round } from "../../games/models/Round";
import { Seat, Seats } from "../../games/models/Seat";
import { Season } from "../../seasons/Season";
import { Callback } from "../../util/Callback";
import { checkNotNull } from "../../util/Nullable";
import { FinalResults } from "../FinalResults";
import { SeasonSelector } from "../SeasonSelector";
import { TotalUpdater } from "../TotalUpdater";
import { EditForm } from "./EditForm";
import { GameDeleter } from "./GameDeleter";
import { HandTable } from "./HandTable";
import { PlayerEditor } from "./PlayerEditor";
import { GameFinalizer } from "./edit-form-components/GameFinalizer";

export function RoundEditor({
    game,
    players,
    seasons,
    finalResults,
    onChange,
}: {
    game: Game;
    players: PlayerList;
    seasons: Season[];
    finalResults: FinalResults;
    onChange: Callback<{ game?: Game; finalResults?: FinalResults }>;
}) {
    function finalizeGame() {
        let updatedFinalResults = finalResults.setDraft(false);
        if (updatedFinalResults.getCompletedAt() == null) {
            updatedFinalResults = updatedFinalResults.setCompletedAt(
                new Date(),
            );
        }
        let updatedGame = game;
        Seats.all().forEach(
            (seat) =>
                (updatedGame = updatedGame.updatePlayerBySeat(seat, (p) =>
                    p
                        .setScore(updatedFinalResults.getScoreBySeat(seat))
                        .setPlacement(
                            updatedFinalResults.getPlacementBySeat(seat),
                        ),
                )),
        );
        updatedGame = updatedGame
            .setCompletedAt(updatedFinalResults.getCompletedAt())
            .setDraft(updatedFinalResults.isDraft());
        onChange({ finalResults: updatedFinalResults, game: updatedGame });
    }

    function onAddRound(round: Round) {
        const updatedGame = game.addRound(round);
        const totals = new TotalUpdater().updateTotals(
            updatedGame,
            checkNotNull(seasons.find((s) => s.id === game.season_id)),
            finalResults,
        );
        onChange({ game: updatedGame, finalResults: totals });
    }

    const season = checkNotNull(seasons.find((s) => s.id === game.season_id));
    function onDeleteRound(round: Round) {
        const index = game.rounds.indexOf(round);
        if (index === -1) {
            return;
        }
        const newRounds = game.rounds.slice();
        newRounds.splice(index, 1);
        const updatedGame = game.setRounds(newRounds);
        const totals = new TotalUpdater().updateTotals(
            updatedGame,
            season,
            finalResults,
        );
        onChange({ game: updatedGame, finalResults: totals });
    }

    return (
        <div>
            <Panel title="Edit Game" icon="fa-edit">
                <PlayerEditor
                    game={game}
                    players={players}
                    onSetPlayer={(seat: Seat, player: Player) => {
                        const copy = game.updatePlayerBySeat(seat, (p) =>
                            p.setPlayerId(player.getId()),
                        );
                        onChange({ game: copy });
                    }}
                    onSetRotation={(rotation: number) =>
                        onChange({
                            game: game.setRotation(rotation),
                        })
                    }
                />
                <HandTable
                    game={game}
                    players={players}
                    onDeleteRound={onDeleteRound}
                    onRiichi={onAddRound}
                />
                <EditForm
                    game={game}
                    players={players}
                    onAddRound={onAddRound}
                />
            </Panel>
            <Panel title="End Game" icon="fa-flag-checkered">
                <GameFinalizer
                    players={players}
                    game={game}
                    season={season}
                    onUpdateFinalResults={(finalResults: FinalResults) =>
                        onChange({ finalResults })
                    }
                    onSetDraft={(isDraft: boolean) =>
                        onChange({
                            game: game.setDraft(isDraft),
                            finalResults: finalResults.setDraft(isDraft),
                        })
                    }
                    finalResults={finalResults}
                    onFinish={finalizeGame}
                />
            </Panel>
            <Panel title="Delete Game" icon="fa-trash">
                <GameDeleter
                    game={game}
                    onDelete={(reason) =>
                        onChange({
                            game: game.setDeleteReason(reason),
                        })
                    }
                    onUndelete={() =>
                        onChange({
                            game: game.setDeleteReason(null),
                        })
                    }
                />
            </Panel>
            <Panel title="Other" icon="fa-gear">
                <SeasonSelector
                    onChange={(s) => onChange({ game: game.setSeasonId(s) })}
                    seasons={seasons}
                    seasonID={game.season_id}
                />
            </Panel>
        </div>
    );
}
