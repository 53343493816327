import React, { ReactNode, useState } from "react";
import { Header } from "./Header";
import { Navigation } from "./Navigation";

export function DefaultLayout({ children }: { children: ReactNode }) {
    const [expanded, setExpanded] = useState(false);

    return (
        <div className="default-layout">
            <div className="default-layout__header">
                <Header onToggle={() => setExpanded(!expanded)} />
            </div>
            <div
                className={[
                    "default-layout__navigation",
                    expanded ? "default-layout__navigation--expanded" : "",
                ].join(" ")}
            >
                <Navigation />
            </div>
            <div className="default-layout__content">{children}</div>
        </div>
    );
}
