import React from "react";
import { Panel } from "../../common/Panel";
import { Game } from "../models/Game";

export class GameInfoPanel extends React.Component<{ game: Game }> {
    getDateString() {
        const date = this.props.game.getCompletedAt();
        if (date != null) {
            return `Played ${date.fromNow()} at ${date.format("lll")}`;
        }
        return "Date unavailable;";
    }

    render() {
        return (
            <Panel icon="fa-info" title="Game Info">
                {this.getDateString()}
            </Panel>
        );
    }
}
