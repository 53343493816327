import { SeasonSerializer } from "../games/serializers/SeasonSerializer";
import { Season } from "../seasons/Season";
import { HttpService } from "./HttpService";

export class SeasonService {
    constructor(private httpService: HttpService) {}

    list(): Promise<Season[]> {
        return this.rawList().then((seasons) => [
            Season.createOverall(),
            ...seasons,
        ]);
    }

    rawList(): Promise<Season[]> {
        return this.httpService
            .get("/api/seasons")
            .then((seasons) => seasons.map(SeasonSerializer.fromJSON));
    }

    async update(season: Season) {
        if (season.getId() == null) {
            throw new Error("Invalid season");
        }
        return SeasonSerializer.fromJSON(
            await this.httpService.post(
                `/api/seasons/${season.getId()}`,
                season,
            ),
        );
    }

    async get(id: number | string | null): Promise<Season> {
        if (id === Season.OVERALL_ID) {
            return Season.createOverall();
        }
        if (id == null) {
            return this.getCurrentSeason();
        }
        return this.httpService
            .get(`/api/seasons/${id}`)
            .then(SeasonSerializer.fromJSON);
    }

    async getCurrentSeason(): Promise<Season> {
        return this.httpService
            .get(`/api/seasons/current`)
            .then(SeasonSerializer.fromJSON);
    }

    async setCurrentSeason(season: Season): Promise<Season> {
        return this.httpService.post("/api/seasons/current", season);
    }

    async create(season: Season): Promise<Season> {
        return SeasonSerializer.fromJSON(
            await this.httpService.post("/api/seasons", season),
        );
    }
}
