import React from "react";
import { ChartDimensions } from "../common/charts/ChartDimensions";
import { LineChart } from "../common/charts/LineChart";
import { FinalScoreCalculator } from "../games/FinalScoreCalculator";
import { PlayerList } from "../games/PlayerList";
import { RankingService } from "../games/RankingService";
import { SeasonGameList } from "../http/SeasonGameList";
import { ScoringMethod } from "../seasons/ScoringMethod";
import { Season } from "../seasons/Season";
import { GameRankingScorePanel } from "./GameRankingScorePanel";

export class PlayerScoreChart extends React.Component<
    { games: SeasonGameList; players: PlayerList; season: Season },
    { selectedGameNumber: number | null }
> {
    state = {
        selectedGameNumber: null,
    };

    renderGameInfo() {
        const gameNumber = this.state.selectedGameNumber;
        if (gameNumber == null) {
            return null;
        }
        const games = this.props.games.slice(gameNumber);
        const rankings = new RankingService(
            new FinalScoreCalculator(),
        ).getRankings(games, this.props.players.getAll(), this.props.season);
        return (
            <GameRankingScorePanel
                game={this.props.games.byGameNumber(gameNumber)}
                players={this.props.players}
                season={this.props.season}
                rankings={rankings}
            />
        );
    }

    getCenter() {
        return ScoringMethod.getById(
            this.props.season.getScoringMethod(),
        ).getInitialScore();
    }

    render() {
        const runningScore = new RankingService(
            new FinalScoreCalculator(),
        ).calculateRunningScores(
            this.props.games,
            this.props.players,
            this.props.season,
        );
        return (
            <div className="player-score-chart">
                <div className="player-score-chart__line-chart">
                    <LineChart
                        lines={runningScore}
                        onSelect={(index) =>
                            this.setState({ selectedGameNumber: index + 1 })
                        }
                        showPoints
                        showCursor
                        scaleX={false}
                        center={this.getCenter()}
                        dimensions={ChartDimensions.create(
                            700,
                            400,
                            40,
                            40,
                            40,
                            60,
                        )}
                    />
                </div>
                {this.renderGameInfo()}
            </div>
        );
    }
}
