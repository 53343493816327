import { equals } from "./Assert";

export class Fluent<T> {
    static of<T>(value: T) {
        return new Fluent(value);
    }

    constructor(readonly value: T) {}

    map(mapper: (t: T) => T) {
        equals(typeof mapper, "function");
        return new Fluent(mapper(this.value));
    }

    get() {
        return this.value;
    }
}
