import { assert } from "./Assert";

export class Collections {
    static findOnly<T>(list: T[], predicate: (t: T) => boolean): T {
        const value = list.filter(predicate);
        assert(
            value.length === 1,
            `Expected a single value but got ${JSON.stringify(value)}`,
        );
        return value[0];
    }

    static findFirst<T>(list: T[], predicate: (t: T) => boolean): T {
        const value = list.filter(predicate);
        assert(
            value.length > 0,
            `Expected at least one value but got ${JSON.stringify(value)}`,
        );
        return value[0];
    }
}
