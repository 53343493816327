import lodash from "lodash";
import { Sort } from "../games/Sort";
import { assert } from "../util/Assert";
import { checkNotNull } from "../util/Nullable";

export class Distribution {
    values: number[];
    constructor(values: number[]) {
        this.values = values.slice().sort(Sort.byValue);
    }

    average(defaultValue: number = 0) {
        if (this.values.length === 0) {
            assert(defaultValue != null);
            return defaultValue;
        }
        return lodash.sum(this.values) / this.values.length;
    }

    q1(defaultValue: number = 0) {
        return new Distribution(this.lowerHalf()).median(defaultValue);
    }

    median(defaultValue?: number) {
        if (this.values.length === 0) {
            return checkNotNull(defaultValue);
        }
        const mid = this.middle();
        if (this.values.length % 2 === 0) {
            return (this.values[mid] + this.values[mid - 1]) / 2;
        }
        return this.values[mid];
    }

    q3(defaultValue: number = 0) {
        return new Distribution(this.upperHalf()).median(defaultValue);
    }

    iqr(defaultValue: number = 0) {
        return this.q3(defaultValue) - this.q1(defaultValue);
    }

    lowerHalf() {
        return this.values.slice(0, this.middle());
    }

    upperHalf() {
        if (this.values.length % 2 === 0) {
            return this.values.slice(this.middle(), this.values.length);
        }
        return this.values.slice(this.middle() + 1, this.values.length);
    }

    trimmedMean(defaultValue?: number): number {
        if (this.values.length === 0) {
            return checkNotNull(defaultValue);
        }
        if (this.values.length < 4) {
            return this.average();
        }
        const iqr = this.iqr();
        const lower = this.q1() - iqr * 1.5;
        const upper = this.q3() + iqr * 1.5;
        const values = this.values
            .filter((v) => v >= lower)
            .filter((v) => v <= upper);
        if (values.length === 0) {
            return checkNotNull(defaultValue);
        }
        return lodash.sum(values) / values.length;
    }

    middle() {
        return Math.floor(this.values.length / 2);
    }
}
