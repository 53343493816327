import { Game } from "../games/models/Game";
import { Round } from "../games/models/Round";
import { Fluent } from "../util/Fluent";
import { RoundEditorOptions } from "./RoundEditorOptions";
import { ScoreCalculator } from "./ScoreCalculator";
import { SignUpdater } from "./SignUpdater";

export class RoundCalculator {
    static calculateScore(
        game: Game,
        round: Round,
        roundEditorOptions: RoundEditorOptions,
    ) {
        const scoreCalculator = new ScoreCalculator();
        return Fluent.of(round)
            .map(r => {
                if (roundEditorOptions.isAutoUpdateHand()) {
                    return this.autoNormalize(game, round);
                }
                return this.normalize(r);
            })
            .map(r => scoreCalculator.calculate(r))
            .get();
    }

    private static autoNormalize(game: Game, round: Round) {
        const scoreCalculator = new ScoreCalculator();
        const signUpdater = new SignUpdater();
        return Fluent.of(round)
            .map(r => signUpdater.normalizeSignsFromGame(r, game))
            .map(r => scoreCalculator.updateRiichiCount(game, r))
            .map(r => scoreCalculator.updateHonbaCount(game, r))
            .get();
    }

    private static normalize(round: Round) {
        const signUpdater = new SignUpdater();
        return signUpdater.normalizeSigns(round);
    }
}
