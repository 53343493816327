import { Player } from "../games/models/Player";
import { Statistic } from "../stats/Statistic";

export class PlayerStatistic {
    static sortDescending() {
        return (a: PlayerStatistic, b: PlayerStatistic) =>
            b.getStat().getValue() - a.getStat().getValue();
    }

    constructor(private player: Player, private stat: Statistic) {}

    getId() {
        return this.player.getId();
    }

    getPlayer() {
        return this.player;
    }

    getStat() {
        return this.stat;
    }
}
