import React, { ChangeEvent } from "react";
import { PlayerList } from "../../games/PlayerList";
import { Game } from "../../games/models/Game";
import { Player } from "../../games/models/Player";
import { Seat } from "../../games/models/Seat";
import { Winds } from "../../games/models/Winds";
import { Callback } from "../../util/Callback";

export class PlayerSelector extends React.Component<{
    seat: Seat;
    game: Game;
    players: PlayerList;
    onSelectPlayer: Callback<Player>;
}> {
    getSelectedPlayerID() {
        return this.props.game.getPlayerBySeat(this.props.seat).playerId;
    }

    getSeatWind() {
        return Winds.getBySeat(this.props.seat).getName();
    }

    getAvailablePlayers() {
        return this.props.players
            .listWithEmpty()
            .sort((a, b) => {
                if (a.id == null) {
                    return -1;
                }
                if (b.id == null) {
                    return 1;
                }
                return a.name.localeCompare(b.name);
            })
            .filter((p) => !this.isAlreadySelected(p));
    }

    isAlreadySelected(player: Player) {
        if (player === Player.emptyPlayer()) {
            return false;
        }
        return this.props.game
            .getPlayers()
            .filter((p) => p.seat !== this.props.seat)
            .some((p) => p.playerId === player.getId());
    }

    onSelectPlayer(e: ChangeEvent<HTMLSelectElement>) {
        const id = parseInt(e.target.value, 10) || null;
        const player = this.props.players.getById(id);
        this.props.onSelectPlayer(player);
    }

    render() {
        let selectValue = this.getSelectedPlayerID();
        return (
            <div className="player-selector">
                <label className="form-label">{this.getSeatWind()}</label>
                <select
                    className="form-control"
                    value={selectValue != null ? selectValue : ""}
                    onChange={(event) => this.onSelectPlayer(event)}
                    data-seat={this.props.seat}
                >
                    {this.getAvailablePlayers().map((player) => (
                        <option
                            key={player.getId() || ""}
                            value={player.getId() || ""}
                        >
                            {player.getName()}
                        </option>
                    ))}
                </select>
            </div>
        );
    }
}
