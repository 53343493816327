import React from "react";

interface ButtonProps {
    onClick: () => void;
}

export class Button extends React.Component<ButtonProps, {}> {
    render() {
        return (
            <button
                className="btn btn-default"
                onClick={e => {
                    e.preventDefault();
                    this.props.onClick();
                }}
            >
                {this.props.children}
            </button>
        );
    }
}
