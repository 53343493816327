import * as superagent from "superagent";

function decorateError(e: Error): never {
    if ("response" in e) {
        const res = (e as any).response;
        const req = (res as any).req;
        throw new Error(`${res.statusText}(${res.status}) ${req.url}`);
    }
    throw e;
}

export class HttpService {
    constructor() {}

    get(url: string, query = {}) {
        return this.rawGet(url, query);
    }

    rawGet(url: string, query = {}) {
        return superagent
            .get(url)
            .query(query)
            .catch(decorateError)
            .then((res) => res.body);
    }

    post(url: string, data = {}) {
        return superagent
            .post(url)
            .send(data)
            .set("X-Requested-With", "XMLHttpRequest")
            .catch(decorateError)
            .then((res) => res.body);
    }

    delete(url: string) {
        return superagent.delete(url).then((res) => res.body);
    }
}
