import React from "react";
import { Game } from "../../games/models/Game";

interface Props {
    game: Game;
    onDelete: (reason: string) => void;
    onUndelete: () => void;
}

interface State {
    edit: string | null;
}

export class GameDeleter extends React.Component<Props, State> {
    state: State = { edit: null };

    isDeleted() {
        return this.props.game.isDeleted();
    }

    getDeleteCaption() {
        return this.isDeleted() ? "Undelete" : "Delete";
    }

    onClick() {
        if (this.isDeleted()) {
            this.props.onUndelete();
        } else {
            const reason = this.state.edit || this.props.game.getDeleteReason();
            if (reason == null) {
                return;
            }
            this.props.onDelete(reason);
            this.setState({ edit: null });
        }
    }

    onChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ edit: e.target.value });
    }

    hasDeleteReason() {
        return this.state.edit != null;
    }

    render() {
        return (
            <div className="game-deleter">
                <p>
                    <input
                        className="form-control game-deleter__reason"
                        placeholder="Reason"
                        value={
                            this.state.edit ||
                            this.props.game.getDeleteReason() ||
                            ""
                        }
                        onChange={e => this.onChange(e)}
                        disabled={this.isDeleted()}
                    />
                </p>
                <p className="text-right">
                    <button
                        className="game-deleter__delete btn btn-default"
                        onClick={() => this.onClick()}
                        disabled={!this.isDeleted() && !this.hasDeleteReason()}
                    >
                        {this.getDeleteCaption()}
                    </button>
                </p>
            </div>
        );
    }
}
