import { IPayment } from "../../../../server/src/models/Game";
import { Payment } from "../models/Payment";

export class PaymentSerialzier {
    static fromJSON(data: IPayment) {
        return new Payment(data.payment, data.payment_sign);
    }

    static toJSON(payment: Payment): IPayment {
        return {
            payment: payment.payment,
            payment_sign: payment.payment_sign,
        };
    }
}
