import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { PlayerList } from "../games/PlayerList";
import { checkNotNull } from "../util/Nullable";
import { GameElo } from "./RatingCalculator";

export function RatingGameHistory({
    gameElo,
    player,
    players,
}: {
    gameElo: GameElo[];
    player: number;
    players: PlayerList;
}) {
    function formatDate(date: Date | null) {
        if (date == null) {
            return "-";
        }
        return moment(date).format("lll");
    }
    function formatElo(elo: number) {
        const rounded = elo.toFixed(1);
        if (elo > 0) {
            return `+${rounded}`;
        }
        return rounded;
    }
    return (
        <section>
            <h3>Recent Games</h3>
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>First (Elo Change)</th>
                        <th>Second (Elo Change)</th>
                        <th>Third (Elo Change)</th>
                        <th>Fourth (Elo Change)</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {gameElo.map((elo) => (
                        <tr key={elo.game.id}>
                            <td>{formatDate(elo.game.completed_at)}</td>
                            {elo.game
                                .getPlayers()
                                .sort(
                                    (a, b) =>
                                        (a.placement ?? 0) - (b.placement ?? 0),
                                )
                                .map((p) => (
                                    <td
                                        key={p.playerId ?? 0}
                                        className={
                                            p.playerId == player ? "info" : ""
                                        }
                                    >
                                        {players
                                            .getByIdChecked(
                                                checkNotNull(p.playerId),
                                            )
                                            .getName()}{" "}
                                        (
                                        {formatElo(
                                            elo.forPlayer(
                                                checkNotNull(p.playerId),
                                            ).change,
                                        )}
                                        )
                                    </td>
                                ))}
                            <td>
                                <Link
                                    className="btn btn-primary"
                                    to={`/games/${elo.game.getId()}/view`}
                                >
                                    View
                                </Link>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </section>
    );
}
