import { equals } from "../util/Assert";
import { Statistic } from "./Statistic";

export class NumberStatistic extends Statistic {
    static create(label: string, id: string, value: number): NumberStatistic {
        return new NumberStatistic(label, id, value);
    }

    constructor(label: string, id: string, value: number) {
        equals(typeof value, "number");
        super(label, id, value);
    }

    formatValue() {
        return this.value.toFixed(2);
    }
}
