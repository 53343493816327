import { RoundType, RoundTypes } from "../../round-editor/models/RoundType";
import { PaymentSignType } from "../models/PaymentSign";
import { Round } from "../models/Round";
import { Seat } from "../models/Seat";
import { GameParserStrategy } from "./GameParserStrategy";

export class TsumoCalculator extends GameParserStrategy {
    count = 0;
    constructor(private seat: Seat) {
        super();
    }

    onRound(round: Round, roundType: RoundType) {
        if (
            roundType === RoundTypes.TSUMO &&
            round.getPaymentBySeat(this.seat).getPaymentSign() ===
                PaymentSignType.GAIN
        ) {
            this.count++;
        }
    }

    getCount() {
        return this.count;
    }
}
