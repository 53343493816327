import { Seat, Seats } from "../models/Seat";

export class PlayerRiichis {
    riichis: [boolean, boolean, boolean, boolean] = [
        false,
        false,
        false,
        false,
    ];

    setHasRiichied(seat: Seat) {
        this.riichis[Seats.seatToIndex(seat)] = true;
    }

    getHasRiichied(seat: Seat) {
        return this.riichis[Seats.seatToIndex(seat)];
    }
}
