import { Game } from "../games/models/Game";
import { Player } from "../games/models/Player";
import { GamePlayerStat } from "./GamePlayerStat";
import { RoundCountStat } from "./RoundCountStat";

export abstract class AbstractRoundPercentStat extends GamePlayerStat {
    abstract getCount(game: Game, player: Player): number;

    getStatForGames(games: Game[], player: Player) {
        const wins = games
            .map((g) => this.getCount(g, player))
            .reduce(this.sumReducer(), 0);
        const total = new RoundCountStat().getStat(games, player);
        return this.createPercentStatistic(wins, total.getValue());
    }
}
