import React, { ChangeEvent } from "react";
import { GamePlayerStat } from "../../stats/GamePlayerStat";
import { Callback } from "../../util/Callback";

export class StatSelector extends React.Component<{
    selected: string | null;
    options: GamePlayerStat[];
    onChange: Callback<string>;
}> {
    onChange(e: ChangeEvent<HTMLSelectElement>) {
        this.props.onChange(e.target.value);
    }

    render() {
        return (
            <div className="stat-selector">
                <select
                    className="form-control"
                    value={this.props.selected || undefined}
                    onChange={e => this.onChange(e)}
                >
                    {this.props.options
                        .slice()
                        .sort((a, b) =>
                            a.getLabel().localeCompare(b.getLabel()),
                        )
                        .map(o => (
                            <option key={o.getId()} value={o.getId()}>
                                {o.getLabel()}
                            </option>
                        ))}
                </select>
            </div>
        );
    }
}
