import { assert } from "../../util/Assert";
export enum Seat {
    EAST = 1,
    SOUTH = 2,
    WEST = 3,
    NORTH = 4,
}

export class Seats {
    static seatToIndex(seat: Seat) {
        return seat - 1;
    }

    static indexToSeat(index: number): Seat {
        return index + 1;
    }

    static all() {
        return [Seat.EAST, Seat.SOUTH, Seat.WEST, Seat.NORTH];
    }

    static assertSeat(seat: Seat) {
        assert(
            seat >= 1 && seat <= 4,
            `Expected seat to be between 1 and 4 but got ${seat}`,
        );
    }

    static wrap(seat: Seat): Seat {
        let newSeat = seat;
        while (newSeat < 1) {
            newSeat += 4;
        }
        while (newSeat > 4) {
            newSeat -= 4;
        }
        return newSeat;
    }
}
