export class SignLimiter {
    constructor(
        private minPlus: number,
        private maxPlus: number,
        private minZero: number,
        private maxZero: number,
        private minMinus: number,
        private maxMinus: number,
    ) {}
    plusCount = 0;
    zeroCount = 0;
    minusCount = 0;

    countSigns(signs: number[], sign: number) {
        let count = 0;
        signs.forEach(currentSign => {
            if (currentSign === sign) {
                count++;
            }
        });
        return count;
    }

    limit(signs: number[], lastChange: number) {
        this.plusCount = this.countSigns(signs, 1);
        this.zeroCount = this.countSigns(signs, 0);
        this.minusCount = this.countSigns(signs, -1);

        return signs.map((sign, index) => {
            if (index === lastChange) {
                return sign;
            }
            if (this.invalidSign(sign)) {
                return this.getAvailableSign(sign);
            }
            return sign;
        });
    }

    invalidSign(sign: number) {
        if (sign === 1) {
            if (this.plusCount > this.maxPlus) {
                return true;
            }
            if (this.plusCount < this.minPlus) {
                return true;
            }
        }
        if (sign === 0) {
            if (this.zeroCount > this.maxZero) {
                return true;
            }
            if (this.zeroCount < this.minZero) {
                return true;
            }
        }
        if (sign === -1) {
            if (this.minusCount > this.maxMinus) {
                return true;
            }
            if (this.minusCount < this.minMinus) {
                return true;
            }
        }
        return false;
    }

    getAvailableSign(currentSign: number) {
        if (currentSign === 1) {
            this.plusCount--;
        }
        if (currentSign === 0) {
            this.zeroCount--;
        }
        if (currentSign === -1) {
            this.minusCount--;
        }
        if (this.plusCount < this.maxPlus) {
            this.plusCount++;
            return 1;
        }
        if (this.zeroCount < this.maxZero) {
            this.zeroCount++;
            return 0;
        }
        if (this.minusCount < this.maxMinus) {
            this.minusCount++;
            return -1;
        }
        throw new Error("Invalid state, no assignable signs");
    }
}
