import { RoundTypes } from "../../round-editor/models/RoundType";
import { Round } from "../models/Round";
import { Seat } from "../models/Seat";
import { RoundGroupingCalculator } from "./RoundGroupingCalculator";

export class LastRoundCalculator extends RoundGroupingCalculator {
    getLastRound() {
        return this.getRounds()[this.getRounds().length - 1];
    }

    getPaymentBySeat(seat: Seat) {
        const lastRound = this.getLastRound();
        if (lastRound == null) {
            return 0;
        }
        return this.getAdjustedPayment(lastRound, seat);
    }

    hasPayment(seat: Seat) {
        const lastRound = this.getLastRound();
        if (lastRound == null) {
            return false;
        }
        return lastRound.getPaymentBySeat(seat).getPayment() !== 0;
    }

    getRiichi(seat: Seat) {
        const lastRound = this.getLastRound();
        if (lastRound == null) {
            return 0;
        }
        if (
            this.roundIncludesRiichi(lastRound) &&
            this.getAdjustedPayment(lastRound, seat) > 0
        ) {
            return lastRound.getRiichi() * 1000;
        }
        return 0;
    }

    hasRiichi(seat: Seat) {
        return this.getRiichi(seat) > 0;
    }

    roundIncludesRiichi(round: Round) {
        return RoundTypes.getRoundByType(round.getType()).isWinningHand();
    }

    getAdjustedPayment(round: Round, seat: Seat) {
        let payment = round.getPaymentBySeat(seat).getPayment();
        if (this.roundIncludesRiichi(round) && payment > 0) {
            payment -= round.getRiichi() * 1000;
        }
        return payment;
    }
}
