import React from "react";
import { Callback } from "../../../util/Callback";

export function AddHandButton({ onClick }: { onClick: Callback<void> }) {
    return (
        <button
            className="btn btn-primary add-hand-button"
            onClick={() => onClick()}
        >
            Add
        </button>
    );
}
