import { Round } from "../games/models/Round";
import { RoundTypes } from "./models/RoundType";

export class FuValidator {
    static RestrictedFu = 20;

    static updateFu(round: Round): Round {
        if (
            FuValidator.hasFuRestriction(round) &&
            round.fu == FuValidator.RestrictedFu
        ) {
            return round.setFu(30);
        }
        return round;
    }

    static hasFuRestriction(round: Round): Boolean {
        return round.type == RoundTypes.RON.getType();
    }
}
