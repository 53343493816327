import { HttpService } from "./HttpService";

export interface User {
    username: string;
}

export class AuthService {
    constructor(private httpService: HttpService) {}

    async current(): Promise<User | null> {
        try {
            return await this.httpService.rawGet("/api/auth/current");
        } catch (e) {
            return null;
        }
    }

    async login(username: string, password: string): Promise<User> {
        return await this.httpService.post("/api/auth", {
            username,
            password,
        });
    }

    async logout() {
        return await this.httpService.delete("/api/auth/current");
    }
}
