import { Group } from "../common/charts/Group";
import { GroupedData } from "../common/charts/GroupedData";
import { Player } from "../games/models/Player";
import { PlayerList } from "../games/PlayerList";
import { PlayerStatistic } from "../stats/PlayerStatistic";
import { checkNotNull } from "../util/Nullable";
import { StatisticCollection } from "./StatisticCollection";

const STAT_IDS = [
    "placement-vs-1",
    "placement-vs-2",
    "placement-vs-3",
    "placement-vs-4",
];

const LABELS = ["First", "Second", "Third", "Fourth"];

export class PlayerPlacementService {
    getGroupedPlacements(
        statisticCollection: StatisticCollection,
        players: PlayerList,
    ) {
        const groups = this.collectPlayers(statisticCollection, players).map(
            (player) => {
                const group = new Group(player.getName());
                STAT_IDS.forEach((statId, index) => {
                    const label = LABELS[index];
                    const stat = (statisticCollection.getById(
                        statId,
                    ) as any) as PlayerStatistic;
                    const playerID = player.getId();
                    if (playerID != null && stat.hasPlayerId(playerID)) {
                        group.add(
                            label,
                            checkNotNull(stat.getForPlayer(playerID)),
                        );
                    } else {
                        group.add(label, 0);
                    }
                });
                return group;
            },
        );
        return GroupedData.createSorted(groups);
    }

    collectPlayers(
        statisticCollection: StatisticCollection,
        players: PlayerList,
    ): Player[] {
        const set = new Set<Player>();
        STAT_IDS.forEach((stat) => {
            this.addPlayers(
                (statisticCollection.getById(stat) as any) as PlayerStatistic,
                players,
                set,
            );
        });
        return Array.from(set.values());
    }

    addPlayers(stat: PlayerStatistic, players: PlayerList, set: Set<Player>) {
        stat.getPlayerIds()
            .map((id) => players.getById(id))
            .forEach((player) => set.add(player));
    }
}
