import React from "react";
import { PercentStatistic } from "../stats/PercentStatistic";

export class PlacementTable extends React.Component<{
    placementStats: PercentStatistic[];
}> {
    render() {
        return (
            <div className="placement-table">
                <table className="table table-bordered placement-table__table">
                    <thead>
                        <tr>
                            <th />
                            <th>Count</th>
                            <th>Percent</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.placementStats.map(p => (
                            <tr key={p.getId()}>
                                <th>{p.getLabel()}</th>
                                <td>
                                    {p.getNumerator()}/{p.getDenominator()}
                                </td>
                                <td>{p.formatPercent()}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }
}
