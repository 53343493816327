import { IRound } from "../../../../server/src/models/Game";
import { DateFormatter } from "../../components/DateFormatter";
import { Round } from "../models/Round";
import { PaymentSerialzier } from "./PaymentSerializer";

export class RoundSerializer {
    static fromJSON(data: IRound) {
        const round = new Round().copy({
            type: data.type,
            honba: data.honba,
            riichi: data.riichi,
            han: data.han,
            fu: data.fu,
            dealer: data.dealer,
            deleted: data.deleted,
            payments: data.payments.map(PaymentSerialzier.fromJSON),
            created_at: DateFormatter.parse(data.created_at),
        });
        return round;
    }
    static toJSON(round: Round): IRound {
        return {
            type: round.type,
            honba: round.honba,
            riichi: round.riichi,
            han: round.han,
            fu: round.fu,
            dealer: round.dealer,
            deleted: round.deleted,
            payments: round.payments.map((p) => PaymentSerialzier.toJSON(p)),
            created_at: round.created_at.toJSON(),
        };
    }
}
