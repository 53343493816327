import React from "react";
import { useRouteMatch } from "react-router";
import { useQuery } from "../../app/QueryString";
import { useSeasonGames, useSeasons } from "../../seasons/SeasonsContext";
import { checkNotNull } from "../../util/Nullable";
import { parseOptional } from "../../util/Numbers";
import { usePlayers } from "../Players";
import { ViewPlayer } from "../ViewPlayer";

export function ViewPlayerPage() {
    const match = useRouteMatch<{ id: string }>();
    const query = useQuery<{ season?: string; page?: string }>();
    const seasonID = parseOptional(String(query.season));
    const [season, games] = useSeasonGames(seasonID);
    const seasons = useSeasons();
    const players = usePlayers();
    const player =
        players &&
        players.getByIdChecked(parseInt(checkNotNull(match.params.id)));

    if (
        season == null ||
        games == null ||
        seasons == null ||
        players == null ||
        player == null
    ) {
        return null;
    }
    return (
        <ViewPlayer
            seasons={seasons}
            games={games}
            season={season}
            player={player}
            players={players}
            pageNumber={parseOptional(String(query.page)) || 1}
        />
    );
}
