export class ChartDimensions {
    static createDefault() {
        return new ChartDimensions(700, 400, 40, 40, 40, 40);
    }

    static create(
        width: number,
        height: number,
        top: number,
        right: number,
        bottom: number,
        left: number,
    ) {
        return new ChartDimensions(width, height, top, right, bottom, left);
    }

    constructor(
        private width: number,
        private height: number,
        private top: number,
        private right: number,
        private bottom: number,
        private left: number,
    ) {}

    copy() {
        return new ChartDimensions(
            this.width,
            this.height,
            this.top,
            this.right,
            this.bottom,
            this.left,
        );
    }

    getWidth() {
        return this.width;
    }

    getHeight() {
        return this.height;
    }

    setWidth(width: number) {
        this.width = width;
        return this;
    }

    setHeight(height: number) {
        this.height = height;
        return this;
    }

    getTop() {
        return this.top;
    }

    getBottom() {
        return this.bottom;
    }

    getLeft() {
        return this.left;
    }

    getRight() {
        return this.right;
    }

    getHorizontalMargin() {
        return this.left + this.right;
    }

    getVerticalMargin() {
        return this.top + this.bottom;
    }
}
