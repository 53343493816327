import classnames from "classnames";
import React from "react";
import { RoundTypes } from "../../round-editor/models/RoundType";
import { Round } from "../models/Round";
import { Seat, Seats } from "../models/Seat";
import { Wind } from "../models/Winds";
import { PlayerRiichis } from "../parser/PlayerRiichis";

export class RoundDetailsRow extends React.Component<{
    seatWind: Wind;
    roundWind: Wind;
    round: Round;
    riichis: PlayerRiichis;
}> {
    getCellClass(payment: number) {
        return classnames({ success: payment > 0, danger: payment < 0 });
    }

    formatPayment(payment: number) {
        if (payment !== 0) {
            return payment;
        }
        return "";
    }

    getPlayerRow(seat: Seat) {
        const payment = this.props.round.getPaymentBySeat(seat);
        return (
            <td key={seat} className={this.getCellClass(payment.getPayment())}>
                {this.formatPayment(payment.getPayment())}{" "}
                {this.addRiichiIcon(seat)}
            </td>
        );
    }

    addRiichiIcon(seat: Seat) {
        if (this.props.riichis.getHasRiichied(seat)) {
            return (
                <img
                    src="/images/riichi-stick.png"
                    className="round-details-row__riichi-icon"
                />
            );
        }
        return null;
    }

    getFu() {
        if (
            this.isWinningHand() &&
            this.props.round.getFu() &&
            this.props.round.getHan() < 5
        ) {
            return this.props.round.getFu();
        }
        return "";
    }

    getHan() {
        return this.isWinningHand() ? this.props.round.getHan() : "";
    }

    isWinningHand() {
        return RoundTypes.getRoundByType(
            this.props.round.getType(),
        ).isWinningHand();
    }

    render() {
        const type = RoundTypes.getRoundByType(this.props.round.getType());
        return (
            <tr className="round-details-row">
                <td>{type.getName()}</td>
                <td>
                    {this.props.roundWind.getCharacter()}{" "}
                    {this.props.seatWind.getSeat()}
                </td>
                {Seats.all().map(seat => this.getPlayerRow(seat))}
                <td>{this.getFu()}</td>
                <td>{this.getHan()}</td>
            </tr>
        );
    }
}
