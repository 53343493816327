import React from "react";
import { Panel } from "../../common/Panel";
import { GameInfoService } from "../GameInfoService";
import { Game } from "../models/Game";
import { Seat, Seats } from "../models/Seat";
import { GameCalculatorService } from "../parser/GameCalculatorService";
import { PlayerList } from "../PlayerList";
import { RoundDetailsRow } from "./RoundDetailsRow";

export function RoundDetails({
    game,
    players,
}: {
    game: Game;
    players: PlayerList;
}) {
    const gameInfoService = new GameInfoService();
    const gameCalculatorService = new GameCalculatorService();
    function calculateGroupedRounds() {
        return gameInfoService.calculateRounds(game);
    }

    function playerHeader(seat: Seat) {
        const name = players
            .getById(game.getPlayerBySeat(seat).playerId)
            .getName();
        return <th key={seat}>{name}</th>;
    }

    const groupedRounds = calculateGroupedRounds();
    return (
        <Panel icon="fa-gear" title="Round Details">
            <div className="round-details">
                <table className="table table-bordered round-details round-details__table">
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th>Round</th>
                            {Seats.all().map((s) => playerHeader(s))}
                            <th>Fu</th>
                            <th>Han</th>
                        </tr>
                    </thead>
                    <tbody>
                        {groupedRounds.getRounds().map((round, i) => (
                            <RoundDetailsRow
                                key={i}
                                round={round}
                                riichis={groupedRounds.getRiichis(round)}
                                roundWind={gameCalculatorService.getRoundWind(
                                    game,
                                    round,
                                )}
                                seatWind={gameCalculatorService.getSeatWind(
                                    game,
                                    round,
                                )}
                            />
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <th>Type</th>
                            <th>Round</th>
                            {Seats.all().map((s) => playerHeader(s))}
                            <th>Fu</th>
                            <th>Han</th>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </Panel>
    );
}
