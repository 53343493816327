import { Game } from "../games/models/Game";
import { PaymentSignType } from "../games/models/PaymentSign";
import { Player } from "../games/models/Player";
import { Round } from "../games/models/Round";
import { Seat } from "../games/models/Seat";
import { GameParser } from "../games/parser/GameParser";
import { GameParserStrategy } from "../games/parser/GameParserStrategy";
import { RoundType } from "../round-editor/models/RoundType";
import { checkNotNull } from "../util/Nullable";
import { AbstractRoundPercentStat } from "./AbstractRoundPercentStat";

export class YakumanParser extends GameParserStrategy {
    count = 0;

    constructor(private seat: Seat) {
        super();
    }

    onRound(round: Round, roundType: RoundType) {
        if (
            roundType.isWinningHand() &&
            round.getSingleSeatWithSign(PaymentSignType.GAIN) === this.seat &&
            round.han >= 13
        ) {
            this.count++;
        }
    }

    getCount() {
        return this.count;
    }
}

export class YakumanStat extends AbstractRoundPercentStat {
    getLabel() {
        return "Yakuman Count";
    }

    getId() {
        return "yakuman-count";
    }

    getCount(game: Game, player: Player) {
        return GameParser.parse(
            game,
            new YakumanParser(checkNotNull(game.getSeatForPlayer(player))),
        ).getCount();
    }
}
