import lodash from "lodash";
import React from "react";
import { Group } from "../common/charts/Group";
import { GroupedBarChart } from "../common/charts/GroupedBarChart";
import { Panel } from "../common/Panel";
import { Popup } from "../common/Popup";
import { PlayerList } from "../games/PlayerList";
import { PercentStatistic } from "../stats/PercentStatistic";
import { checkNotNull } from "../util/Nullable";
import { PlayerPlacementService } from "./PlayerPlacementService";
import { StatisticCollection } from "./StatisticCollection";

export class PlayerVsStats extends React.Component<
    {
        statisticCollection: StatisticCollection;
        players: PlayerList;
    },
    { x: number; y: number; group: Group | null }
> {
    state: { x: number; y: number; group: Group | null } = {
        x: 0,
        y: 0,
        group: null,
    };

    onMouseEnter(event: { x: number; y: number; group: Group }) {
        this.setState({
            x: event.x,
            y: event.y,
            group: event.group,
        });
    }

    onMouseLeave() {
        this.setState({ group: null });
    }

    getGroups() {
        return new PlayerPlacementService().getGroupedPlacements(
            this.props.statisticCollection,
            this.props.players,
        );
    }

    getStats() {
        const total = lodash.sum(checkNotNull(this.state.group).getValues());
        return checkNotNull(this.state.group)
            .getLabels()
            .map(label =>
                PercentStatistic.createPercent(
                    label,
                    label,
                    checkNotNull(this.state.group).getValue(label),
                    total,
                ),
            );
    }

    renderPopupContent() {
        if (!this.state.group) {
            return <div />;
        }
        return (
            <table className="table table-condensed">
                <thead>
                    <tr>
                        <th colSpan={2}>
                            {checkNotNull(this.state.group).getLabel()}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {this.getStats().map(stat => (
                        <tr key={stat.getLabel()}>
                            <td>{stat.getLabel()}</td>
                            <td>{stat.formatValue()}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    }

    render() {
        return (
            <Panel icon="fa-bar-chart" title="Player VS Stats">
                <div className="player-vs-stats">
                    <GroupedBarChart
                        data={this.getGroups()}
                        onMouseEnter={e => this.onMouseEnter(e)}
                        onMouseLeave={() => this.onMouseLeave()}
                    />
                </div>
                <Popup
                    x={this.state.x}
                    y={this.state.y}
                    visible={!!this.state.group}
                >
                    {this.renderPopupContent()}
                </Popup>
            </Panel>
        );
    }
}
