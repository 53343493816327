import React from "react";
import { Game } from "../games/models/Game";
import { GameCalculatorService } from "../games/parser/GameCalculatorService";

export class RoundInfo extends React.Component<{
    game: Game;
}> {
    gameCalculatorService = new GameCalculatorService();

    getRoundWindInfo() {
        const roundWind = this.gameCalculatorService.getRoundWind(
            this.props.game,
        );
        const seatWind = this.gameCalculatorService.getSeatWind(
            this.props.game,
        );
        return `${roundWind.getCharacter()} ${seatWind.getNumber()}`;
    }

    getHonbaCount() {
        return this.gameCalculatorService.getHonbaCount(this.props.game);
    }

    getRiichiCount() {
        return this.gameCalculatorService.getRiichiPotCount(this.props.game);
    }

    render() {
        return (
            <div className="round-info tile">
                <div className="round-info__wind-info">
                    {this.getRoundWindInfo()}
                </div>
                <div className="round-info__riichi-count">
                    {this.getRiichiCount()}
                    <img src="/images/riichi-stick.png" />
                </div>
                <div className="round-info__honba-count">
                    {this.getHonbaCount()}
                    <img src="/images/honba-stick.png" />
                </div>
            </div>
        );
    }
}
