import { Round } from "../models/Round";
import { Seat, Seats } from "../models/Seat";
import { GameParserStrategy } from "./GameParserStrategy";

export class PointTotalCalculator extends GameParserStrategy {
    points: [number, number, number, number] = [0, 0, 0, 0];

    onRound(round: Round) {
        this.addPoints(
            round.getPaymentsList() as [number, number, number, number],
        );
    }

    addPoints(points: [number, number, number, number]) {
        this.points.forEach((current, index) => {
            this.points[index] += points[index];
        });
    }

    getPointsForSeat(seat: Seat) {
        return this.points[Seats.seatToIndex(seat)];
    }

    getTotalPoints() {
        return this.points.reduce((a, b) => a + b, 0);
    }
}
