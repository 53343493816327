import { RoundTypes } from "../../round-editor/models/RoundType";
import { assert, equals } from "../../util/Assert";
import { StringHelper } from "../../util/StringHelper";
import { DataClass } from "../DataClass";
import { Payment, Payments } from "./Payment";
import { PaymentSign, PaymentSigns, PaymentSignType } from "./PaymentSign";
import { Seat, Seats } from "./Seat";

export class Round extends DataClass<Round> {
    static createDefault() {
        return Object.assign(new Round(), {
            type: RoundTypes.RON.getType(),
            honba: 0,
            riichi: 0,
            han: 1,
            fu: 30,
            dealer: false,
            deleted: false,
            created_at: new Date(),
            payments: Payment.createList(),
        });
    }

    static createEmpty() {
        return new Round().setPayments(Payment.createList());
    }

    static createRiichi(riichiSeat: Seat) {
        return Object.assign(new Round(), {
            type: RoundTypes.RIICHI.getType(),
            honba: 0,
            riichi: 0,
            han: 0,
            fu: 0,
            dealer: false,
            deleted: false,
            created_at: new Date(),
            payments: Seats.all().map((seat) => {
                if (riichiSeat === seat) {
                    return new Payment(-1000, -1);
                } else {
                    return Payment.createDefault();
                }
            }),
        });
    }

    readonly type: string;
    readonly honba: number;
    readonly riichi: number;
    readonly han: number;
    readonly fu: number;
    readonly dealer: boolean;
    readonly deleted: boolean;
    readonly payments: Payments;
    readonly created_at: Date;

    setType(type: string) {
        RoundTypes.getRoundByType(type);
        return this.copy({ type });
    }

    setPaymentsAndSignsList(points: number[], signs: number[]) {
        return this.setPaymentsList(points).setPaymentSignsList(signs);
    }

    setPaymentsList(points: number[]) {
        const updatedPayments = this.payments.map((p, i) =>
            p.copy({ payment: points[i] }),
        );
        return this.copy({
            payments: updatedPayments as Payments,
        });
    }

    setPaymentSignsList(signs: number[]) {
        PaymentSign.assertList(signs);
        const updatedPayments = this.payments.map((p, i) =>
            p.copy({ payment_sign: signs[i] }),
        );
        return this.copy({
            payments: updatedPayments as Payments,
        });
    }

    setPayments(payments: Payments) {
        return this.copy({ payments });
    }

    getPayments() {
        return this.payments;
    }

    getPaymentsList() {
        return this.payments.map((p) => p.getPayment());
    }

    getPaymentSignsList(): PaymentSigns {
        return this.payments.map((p) => p.getPaymentSign()) as PaymentSigns;
    }

    getSeatsWithSign(sign: number) {
        PaymentSign.assertSign(sign);
        const seats: Seat[] = [];
        this.payments.forEach((payment, index) => {
            if (payment.getPaymentSign() === sign) {
                seats.push(Seats.indexToSeat(index));
            }
        });
        return seats;
    }

    getSingleSeatWithSign(sign: number) {
        const seats = this.getSeatsWithSign(sign);
        assert(
            seats.length === 1,
            `Expected a single payment with sign ${sign} but got ${JSON.stringify(
                seats,
            )}`,
        );
        return seats[0];
    }

    getType() {
        return this.type;
    }

    getHan() {
        return this.han;
    }

    setHan(han: number) {
        return this.copy({ han });
    }

    setFu(fu: number) {
        return this.copy({ fu });
    }

    getFu() {
        return this.fu;
    }

    getHonba() {
        return this.honba;
    }

    setHonba(honba: number) {
        return this.copy({ honba });
    }

    getRiichi() {
        return this.riichi;
    }

    setRiichi(riichi: number) {
        return this.copy({ riichi });
    }

    getPaymentBySeat(seat: number) {
        return this.payments[Seats.seatToIndex(seat)];
    }

    isDealer() {
        return this.dealer;
    }

    setDealer(dealer: boolean) {
        return this.copy({ dealer });
    }

    getCreatedAt() {
        return this.created_at;
    }

    setCreatedAt(date: Date) {
        return this.copy({ created_at: date });
    }

    setDeleted(deleted: boolean) {
        return this.copy({ deleted });
    }

    getDeleted() {
        return this.deleted;
    }

    getRiichiSeat() {
        equals(RoundTypes.getRoundByType(this.getType()), RoundTypes.RIICHI);
        const seats = this.getPayments()
            .map((payment, index) => ({ payment, index }))
            .filter(
                (payment) =>
                    payment.payment.getPaymentSign() === PaymentSignType.LOSS,
            )
            .map((payment) => Seats.indexToSeat(payment.index));
        assert(
            seats.length === 1,
            `Expected one player with riichi but got ${seats}`,
        );
        return seats[0];
    }

    toString() {
        return StringHelper.create(Round)
            .addField("type", this.type)
            .addField("fu", this.fu)
            .addField("han", this.han)
            .addField("riichi", this.riichi)
            .addField("dealer", this.dealer)
            .addField("honba", this.honba)
            .addField("created_at", this.created_at)
            .toString();
    }

    updatePayment(seat: Seat, updater: (payment: Payment) => Payment): Round {
        const updatedPayments = this.payments.slice() as [
            Payment,
            Payment,
            Payment,
            Payment,
        ];
        updatedPayments[Seats.seatToIndex(seat)] = updater(
            updatedPayments[Seats.seatToIndex(seat)],
        );
        return this.copy({ payments: updatedPayments });
    }
}
