import { RoundTypes } from "../../round-editor/models/RoundType";
import { Game } from "../models/Game";
import { GameParserStrategy } from "./GameParserStrategy";

export class GameParser {
    static parse<T extends GameParserStrategy>(game: Game, strategy: T): T {
        return new GameParser().parse(game, strategy);
    }

    parse<T extends GameParserStrategy>(game: Game, strategy: T): T {
        game.getRounds()
            .filter(r => !r.getDeleted())
            .forEach(round => {
                const type = RoundTypes.getRoundByType(round.getType());
                strategy.onRound(round, type);
                if (type.endsRound()) {
                    strategy.onRoundEnd(round, type);
                }
            });
        return strategy;
    }
}
