import classnames from "classnames";
import React from "react";
import { Callback } from "../../../util/Callback";

export function AutoUpdate({
    isAutoUpdate,
    setAutoUpdate,
}: {
    isAutoUpdate: boolean;
    setAutoUpdate: Callback<boolean>;
}) {
    return (
        <button
            className={classnames("auto-update-button", "btn", {
                "btn-primary": isAutoUpdate,
                "btn-default": !isAutoUpdate,
            })}
            onClick={() => setAutoUpdate(!isAutoUpdate)}
        >
            Auto On
        </button>
    );
}
