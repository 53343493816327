import React from "react";
import { FinalResults } from "../../FinalResults";

export function FinalTotalScore({
    finalResults,
}: {
    finalResults: FinalResults;
}) {
    return (
        <div className="control-group">
            <label className="control-label">Total</label>
            <div>{finalResults.getScores().reduce((a, b) => a + b, 0)}</div>
        </div>
    );
}
