import { Game } from "../games/models/Game";
import { Player } from "../games/models/Player";
import { Seats } from "../games/models/Seat";
import { Winds } from "../games/models/Winds";
import { AverageFeedHandValueStat } from "../stats/AverageFeedHandValueStat";
import { AverageHandValueStat } from "../stats/AverageHandValueStat";
import { AverageScoreStat } from "../stats/AverageScoreStat";
import { DealInStat } from "../stats/DealInStat";
import { DeathStat } from "../stats/DeathStat";
import { GameCountStat } from "../stats/GameCountStat";
import { ParticipationStat } from "../stats/ParticipationStat";
import { PlacementStat } from "../stats/PlacementStat";
import { PlacementVsStat } from "../stats/PlacementVsStat";
import { RiichiRateStat } from "../stats/RiichiRateStat";
import { RonRateStat } from "../stats/RonRate";
import { RoundCountStat } from "../stats/RoundCountStat";
import { RoundTimeStat } from "../stats/RoundTimeStat";
import { RoundWinRateStat } from "../stats/RoundWinRateStat";
import { TsumoRateStat } from "../stats/TsumoRateStat";
import { WindPlacementStat } from "../stats/WindPlacementStat";
import { YakumanStat } from "../stats/YakumanStat";
import { StatisticCollection } from "./StatisticCollection";

export class PlayerStatisticsService {
    hasGames(games: Game[], player: Player) {
        return games.some((game) => game.hasPlayer(player));
    }

    getStats() {
        return [
            new AverageScoreStat(),
            new GameCountStat(),
            new RoundCountStat(),
            new RoundWinRateStat(),
            new RiichiRateStat(),
            new DeathStat(),
            new DealInStat(),
            new AverageHandValueStat(),
            new AverageFeedHandValueStat(),
            new RonRateStat(),
            new TsumoRateStat(),
            new ParticipationStat(),
            new RoundTimeStat(),
            new YakumanStat(),
            ...Seats.all().map((s) => new PlacementStat(s)),
            ...Seats.all().map((s) => new PlacementVsStat(s)),
            ...Winds.values().map((w) => new WindPlacementStat(w)),
        ];
    }

    getSingleValueStats() {
        return this.getStats().filter((s) => s.isSingleValue());
    }

    compute(games: Game[], player: Player) {
        const stats = new StatisticCollection();
        this.getStats()
            .map((s) => s.getStat(games, player))
            .forEach((s) => stats.add(s));
        return stats;
    }
}
