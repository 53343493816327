import React, { useMemo } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import { AppAuth } from "../components/AuthRequired";
import { ErrorDisplay } from "../errors/ErrorDisplay";
import { LiveScorePage } from "../live/LiveScorePage";
import { ServiceContext } from "../services/ServiceContext";
import { Services } from "../services/Services";
import { DefaultLayout } from "./DefaultLayout";
import { Pages } from "./Pages";

export function Application() {
    const routes = Pages.list().map((p, i) => (
        <Route key={i} exact path={p.route}>
            <p.component />
        </Route>
    ));
    const services = useMemo(() => new Services(), []);

    return (
        <ServiceContext services={services}>
            <ErrorDisplay>
                <AppAuth authService={services.authService}>
                    <HashRouter basename="/">
                        <Switch>
                            <Route
                                exact
                                path="/live-score"
                                component={LiveScorePage}
                            />
                            <DefaultLayout>{routes}</DefaultLayout>
                        </Switch>
                    </HashRouter>
                </AppAuth>
            </ErrorDisplay>
        </ServiceContext>
    );
}
