import { PlayerData } from "./PlayerData";

export class Player {
    static EMPTY_PLAYER = Player.fromRest({
        id: null,
        name: "None",
    });

    static fromRest(data: PlayerData): Player {
        return new Player(data.id, data.name);
    }

    constructor(readonly id: number | null, readonly name: string) {}

    static emptyPlayer() {
        return Player.EMPTY_PLAYER;
    }

    setName(name: string) {
        return new Player(this.id, name);
    }

    getId() {
        return this.id;
    }

    getIdChecked() {
        if (this.id == null) {
            throw new Error("Id can't be null");
        }
        return this.id;
    }

    getName() {
        return this.name;
    }
}
