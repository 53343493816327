import { Game } from "../games/models/Game";
import { Player } from "../games/models/Player";
import { Placement } from "../games/Placement";
import { AbstractGamePercentStat } from "./AbstractGamePercentStat";

const PLACEMENT_NAMES = ["First", "Second", "Third", "Forth"];

export class PlacementStat extends AbstractGamePercentStat {
    constructor(private placement: number) {
        super();
        Placement.assert(placement);
    }

    getLabel() {
        return `Placement - ${PLACEMENT_NAMES[this.placement - 1]}`;
    }

    getId() {
        return `placement-${this.placement}-stat`;
    }

    getCount(games: Game[], player: Player) {
        return games.filter(g => this.isMatchingPlacement(g, player)).length;
    }

    isMatchingPlacement(game: Game, player: Player) {
        return game.getGamePlayer(player).placement === this.placement;
    }
}
