import { Game } from "../games/models/Game";
import { GameSerializer } from "../games/serializers/GameSerializer";
import { Season } from "../seasons/Season";
import { HttpService } from "./HttpService";
import { SeasonGameList } from "./SeasonGameList";

export class GamesService {
    constructor(private httpService: HttpService) {}

    async listDrafts(): Promise<SeasonGameList> {
        const games = await this.httpService.get("/api/games", { draft: true });
        return new SeasonGameList(games.map(GameSerializer.fromJSON));
    }

    async list(season: Season): Promise<SeasonGameList> {
        const games = await this.httpService.get("/api/games", {
            season_id: season.getId() || null,
        });
        return new SeasonGameList(games.map(GameSerializer.fromJSON));
    }

    async get(id: string): Promise<Game> {
        const data = await this.httpService.get(`/api/games/${id}`);
        return GameSerializer.fromJSON(data);
    }

    async create(game: Game): Promise<Game> {
        return GameSerializer.fromJSON(
            await this.httpService.post(
                `/api/games`,
                GameSerializer.toJSON(game),
            ),
        );
    }

    async update(game: Game): Promise<Game> {
        return GameSerializer.fromJSON(
            await this.httpService.post(
                `/api/games/${game.getId()}`,
                GameSerializer.toJSON(game),
            ),
        );
    }
}
