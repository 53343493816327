import React from "react";
import { AuthRequired } from "../../components/AuthRequired";
import { Season } from "../../seasons/Season";
import { FinalScoreCalculator } from "../FinalScoreCalculator";
import { Game } from "../models/Game";
import { GamePlayer } from "../models/GamePlayer";
import { Player } from "../models/Player";
import { PlayerList } from "../PlayerList";
import { EditGameLink } from "./EditGameLink";
import { GameDate } from "./GameDate";
import { GameLink } from "./GameLink";
import { PlayerLink } from "./PlayerLink";

export function GamesTableRow({
    highlightPlayer,
    game,
    players,
    gameNumber,
    season,
}: {
    highlightPlayer?: Player;
    game: Game;
    players: PlayerList;
    gameNumber: string;
    season: Season;
}) {
    function getCellClass(gamePlayer: GamePlayer) {
        if (!highlightPlayer) {
            return "";
        }
        if (gamePlayer.playerId == highlightPlayer.getId()) {
            return "info";
        }
        return "";
    }

    return (
        <tr className="games-table-row">
            <td>
                <GameLink game={game}>{gameNumber}</GameLink>
            </td>
            {game.getOrderedPlayers().map((player, index) => (
                <td key={index} className={getCellClass(player)}>
                    <PlayerLink player={players.getById(player.playerId)} />
                    <div>
                        {player.score} (
                        {new FinalScoreCalculator().getFinalScore(
                            game,
                            player,
                            season,
                        )}
                        )
                    </div>
                </td>
            ))}
            <td>
                <GameDate game={game} />
            </td>
            <td>
                <AuthRequired>
                    <EditGameLink game={game}>Edit</EditGameLink> /
                </AuthRequired>{" "}
                <GameLink game={game}>View</GameLink>
            </td>
        </tr>
    );
}
