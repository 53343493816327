import React from "react";
import { useQuery } from "../../app/QueryString";
import { usePlayers } from "../../player/Players";
import { useSeasonGames, useSeasons } from "../../seasons/SeasonsContext";
import { parseOptional } from "../../util/Numbers";
import { Ranking } from "../Ranking";

export function RankingPage() {
    const match = useQuery<{ season?: string }>();
    const seasons = useSeasons();
    const players = usePlayers();
    const [season, games] = useSeasonGames(parseOptional(String(match.season)));

    if (season == null || seasons == null || players == null || games == null) {
        return null;
    }
    return (
        <Ranking
            season={season}
            seasons={seasons}
            players={players}
            games={games}
        />
    );
}
