import React from "react";
import { Link } from "react-router-dom";
import { useData } from "../../app/Data";
import { PageTitle } from "../../common/PageTitle";
import { Panel } from "../../common/Panel";
import { Loader } from "../../components/Loader";
import { PlayerLink } from "../../games/components/PlayerLink";
import { usePlayerService } from "../../services/ServiceContext";

export function PlayersPage() {
    const playerService = usePlayerService();
    const players = useData(() => playerService.list());

    if (players == null) {
        return <Loader />;
    }

    return (
        <div className="players-page">
            <PageTitle>Players</PageTitle>
            <Panel title="Players" icon="fa-user">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Player</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {players.getAll().map((p, i) => (
                            <tr key={p.getId() || i}>
                                <td>
                                    <PlayerLink player={p} />
                                </td>
                                <td>
                                    <Link to={`/players/${p.getId()}/edit`}>
                                        Edit
                                    </Link>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Panel>
        </div>
    );
}
