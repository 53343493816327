/* eslint-disable react/no-string-refs, react/forbid-prop-types, react/require-default-props */
import classnames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { Callback } from "../../../util/Callback";

export function ButtonGroupSelector({
    mainOptions,
    secondaryOptions,
    value,
    disabled,
    disabledOptions,
    onChange,
}: {
    mainOptions: number[];
    disabledOptions?: number[];
    secondaryOptions: number[];
    value: number;
    disabled: boolean;
    onChange: Callback<number>;
}) {
    const [menuExpanded, setMenuExpanded] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        function closeClick(event: MouseEvent) {
            let target = event.target;
            do {
                if (target === dropdownRef.current) {
                    return;
                }
                target = (target as Element).parentNode;
            } while (target);
            setMenuExpanded(false);
        }
        window.addEventListener("click", closeClick);
    }, []);

    function isCurrentValue(v: number) {
        return value === v;
    }

    function isSecondaryValueActive() {
        return secondaryOptions.includes(value);
    }

    function getSecondaryLabel() {
        if (isSecondaryValueActive()) {
            return value;
        }
        return "More";
    }

    function setValue(value: number) {
        onChange(value);
        setMenuExpanded(false);
    }

    function getMainButton(value: number) {
        const className = classnames("btn", "btn-default", {
            "btn-primary": isCurrentValue(value),
        });
        return (
            <button
                key={value}
                className={className}
                onClick={() => setValue(value)}
                disabled={disabled || disabledOptions?.includes(value)}
                data-value={value}
            >
                {value}
            </button>
        );
    }

    function getDropdownButton() {
        const state = { "btn-primary": isSecondaryValueActive() };
        const className = classnames("btn btn-default dropdown-toggle", state);
        return (
            <button
                className={className}
                onClick={() => toggleMenu()}
                disabled={disabled}
            >
                {getSecondaryLabel()} <span className="caret" />
            </button>
        );
    }

    function getDropdownOption(value: number) {
        return (
            <li
                key={value}
                className={classnames({ active: isCurrentValue(value) })}
            >
                <a onClick={() => setValue(value)} data-value={value}>
                    {value}
                </a>
            </li>
        );
    }

    function toggleMenu() {
        setMenuExpanded(!menuExpanded);
    }

    return (
        <div className="btn-group">
            {mainOptions.map((value) => getMainButton(value))}
            <div
                ref={dropdownRef}
                className={classnames("btn-group", {
                    open: menuExpanded,
                })}
            >
                {getDropdownButton()}
                <ul className="dropdown-menu">
                    {secondaryOptions.map((value) => getDropdownOption(value))}
                </ul>
            </div>
        </div>
    );
}
