import {
    PaymentSign,
    PaymentSigns,
    PaymentSignType,
} from "../games/models/PaymentSign";
import { Seat, Seats } from "../games/models/Seat";

export class PaymentSignListBuilder {
    static from(paymentSigns: PaymentSigns) {
        return new PaymentSignListBuilder(paymentSigns);
    }

    paymentSigns: PaymentSigns;
    constructor(paymentSigns: PaymentSigns) {
        PaymentSign.assertList(paymentSigns);
        this.paymentSigns = paymentSigns.slice() as PaymentSigns;
    }

    resetSeatsWithSign(sign: number) {
        PaymentSign.assertSign(sign);
        this.paymentSigns = this.paymentSigns.map(s =>
            s === sign ? PaymentSignType.NEUTRAL : s,
        ) as PaymentSigns;
        return this;
    }

    setSign(seat: Seat, sign: number) {
        Seats.assertSeat(seat);
        PaymentSign.assertSign(sign);
        this.paymentSigns[Seats.seatToIndex(seat)] = sign;
        return this;
    }

    create() {
        return this.paymentSigns.slice();
    }
}
