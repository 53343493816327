import React, { useState } from "react";
import { useQuery } from "../../app/QueryString";
import { usePlayers } from "../../player/Players";
import { PlayerStatisticsService } from "../../player/PlayerStatisticsService";
import { useSeasonGames, useSeasons } from "../../seasons/SeasonsContext";
import { parseOptional } from "../../util/Numbers";
import { Compare } from "../components/Compare";

export function ComparePage() {
    const params = useQuery<{ season: string }>();
    const [stat, setStat] = useState("");

    const statOptions = new PlayerStatisticsService().getSingleValueStats();
    const seasons = useSeasons();
    const [season, games] = useSeasonGames(
        parseOptional(String(params.season)),
    );
    const players = usePlayers();

    if (seasons == null || season == null || games == null || players == null) {
        return null;
    }

    return (
        <Compare
            stat={stat}
            onSelectStat={setStat}
            statOptions={statOptions}
            currentSeason={season}
            games={games}
            seasons={seasons}
            players={players}
        />
    );
}
