import moment from "moment";

export class DateFormatter {
    static parse(dateString: string) {
        return moment(dateString).toDate();
    }

    static toString(date: Date) {
        return date.toJSON();
    }
}
