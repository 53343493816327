import React from "react";
import { Panel } from "../../common/Panel";
import { Season } from "../../seasons/Season";
import { GameInfoService } from "../GameInfoService";
import { Game } from "../models/Game";
import { Placement } from "../Placement";
import { PlayerList } from "../PlayerList";
import { PlayerLink } from "./PlayerLink";

export function GameScorePanel({
    game,
    players,
    season,
}: {
    game: Game;
    players: PlayerList;
    season: Season;
}) {
    function getPlayers() {
        return game.getPlayers().slice().sort(Placement.sorter());
    }
    const gameInfoService = new GameInfoService();

    return (
        <Panel icon="fa-list" title="Player Score">
            <table className="table table-bordered game-score-panel">
                <thead>
                    <tr>
                        <th className="game-score-panel__player">Player</th>
                        <th className="game-score-panel__placement">
                            Placement
                        </th>
                        <th className="game-score-panel__points">Points</th>
                        <th className="game-score-panel__final-score">
                            Final Score
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {getPlayers().map((p, i) => (
                        <tr key={i}>
                            <td>
                                <PlayerLink
                                    player={players.getById(p.playerId)}
                                />
                            </td>
                            <td>{p.placement}</td>
                            <td>{p.score}</td>
                            <td>
                                {gameInfoService.formatFinalScore(
                                    season,
                                    game,
                                    p,
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </Panel>
    );
}
