import { NumberStatistic } from "./NumberStatistic";

export class AverageStatistic extends NumberStatistic {
    static createAverage(
        label: string,
        id: string,
        numerator: number,
        denominator: number,
    ) {
        return new AverageStatistic(label, id, numerator, denominator);
    }

    constructor(
        label: string,
        id: string,
        numerator: number,
        denominator: number,
    ) {
        const value = denominator !== 0 ? numerator / denominator : 0;
        super(label, id, value);
    }

    formatValue() {
        return this.value.toFixed(0);
    }
}
