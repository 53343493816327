import { Game } from "../games/models/Game";
import { GamePlayerStat } from "./GamePlayerStat";

export class GameCountStat extends GamePlayerStat {
    getLabel() {
        return "Game Count";
    }

    getId() {
        return "game-count";
    }

    requiresSeat() {
        return false;
    }

    getStatForGames(games: Game[]) {
        return this.createStatistic(games.length);
    }
}
