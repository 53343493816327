import classnames from "classnames";
import React from "react";
import { GameInfoService } from "../games/GameInfoService";
import { Game } from "../games/models/Game";
import { Rotation } from "../games/models/Rotation";
import { Seat, Seats } from "../games/models/Seat";
import { Winds } from "../games/models/Winds";
import { GameCalculatorService } from "../games/parser/GameCalculatorService";
import { PlayerList } from "../games/PlayerList";

export function TableSeat({
    seat,
    game,
    players,
}: {
    seat: Seat;
    game: Game;
    players: PlayerList;
}) {
    const gameCalculatorService = new GameCalculatorService();
    const gameInfoService = new GameInfoService();
    function getClassNames() {
        return classnames("tile", "seat", getRotationSeat(), "table-seat");
    }

    function getRotationSeat() {
        const rotatedSeat = Seats.wrap(
            seat - Rotation.getRotationOrDefault(game),
        );
        switch (rotatedSeat) {
            case 1:
                return "seat--top";
            case 2:
                return "seat--left";
            case 3:
                return "seat--bottom";
            case 4:
                return "seat--right";
            default:
                throw new Error(`Invalid index ${seat}`);
        }
    }

    function getPlayerName() {
        const gamePlayer = game.getPlayerBySeat(seat);
        return players.getById(gamePlayer.playerId).getName();
    }

    function getWind() {
        const roundWind = gameCalculatorService.getSeatWind(game, null);
        const eastSeat = roundWind.getNumber();
        const seatsFromEast = Seats.wrap(seat - eastSeat + 1);
        return Winds.getBySeat(seatsFromEast).getCharacter();
    }

    function getPointTotals() {
        return gameCalculatorService.getPlayerPoints(game, seat);
    }

    function formatWin(payment: number, riichi: number) {
        if (payment > 0) {
            if (riichi > 0) {
                return [
                    <span key="0" className="text-success">
                        {payment}
                    </span>,
                    <span key="1">+{riichi}</span>,
                ];
            }
            return <span className="text-success">{payment}</span>;
        }
        return null;
    }

    function formatLoss(payment: number) {
        if (payment < 0) {
            return <span className="text-danger">{payment}</span>;
        }
        return null;
    }

    function getLastPointExchange() {
        const lastRound = gameInfoService.calculateLastRound(game);
        if (!lastRound.hasPayment(seat)) {
            return null;
        }
        return (
            <div className="last-hand">
                {formatWin(
                    lastRound.getPaymentBySeat(seat),
                    lastRound.getRiichi(seat),
                )}
                {formatLoss(lastRound.getPaymentBySeat(seat))}
            </div>
        );
    }

    return (
        <div className={getClassNames()}>
            <div className="wind">{getWind()}</div>
            <div className="player-name">{getPlayerName()}</div>
            <div className="score">{getPointTotals()}</div>
            {getLastPointExchange()}
        </div>
    );
}
