import * as Option from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/pipeable";
import { IGame } from "../../../../server/src/models/Game";
import { DateFormatter } from "../../components/DateFormatter";
import { Game } from "../models/Game";
import { GamePlayerSerializer } from "./GamePlayerSerializer";
import { RoundSerializer } from "./RoundSerializer";

export class GameSerializer {
    static toJSON(game: Game): IGame {
        return {
            id: game.id,
            season_id: game.season_id,
            draft: game.draft,
            delete_reason: game.delete_reason,
            players: game.players.map((p) => GamePlayerSerializer.toJSON(p)),
            rounds: game.rounds.map((r) => RoundSerializer.toJSON(r)),
            rotation: game.rotation,
            created_at: pipe(
                Option.fromNullable(game.created_at),
                Option.map((d) => d.toJSON()),
                Option.toNullable,
            ),
            completed_at: pipe(
                Option.fromNullable(game.completed_at),
                Option.map((d) => d.toJSON()),
                Option.toNullable,
            ),
            updated_at: pipe(
                Option.fromNullable(game.updated_at),
                Option.map((d) => d.toJSON()),
                Option.toNullable,
            ),
        };
    }
    static fromJSON(data: IGame) {
        const game = new Game().copy({
            id: data.id,
            season_id: data.season_id ?? -1,
            draft: data.draft,
            delete_reason: data.delete_reason ?? null,
            rotation: data.rotation ?? null,
            players: data.players?.map(GamePlayerSerializer.fromJSON) ?? [],
            rounds: data.rounds?.map(RoundSerializer.fromJSON) ?? [],
            completed_at: pipe(
                Option.fromNullable(data.completed_at),
                Option.map(DateFormatter.parse),
                Option.toNullable,
            ),
            created_at: pipe(
                Option.fromNullable(data.created_at),
                Option.map(DateFormatter.parse),
                Option.toNullable,
            ),
            updated_at: pipe(
                Option.fromNullable(data.updated_at),
                Option.map(DateFormatter.parse),
                Option.toNullable,
            ),
        });
        return game;
    }
}
