import lodash from "lodash";
import { Game } from "../games/models/Game";
import { GamePlayer } from "../games/models/GamePlayer";
import { Collections } from "../util/Collections";
import { checkNotNull } from "../util/Nullable";
import { Season } from "./Season";

export abstract class ScoringMethod {
    static getById(id: number) {
        return Collections.findOnly(ScoringMethods, (m) => m.getId() === id);
    }

    constructor(readonly id: number, readonly name: string) {}

    abstract getScore(
        game: Game,
        gamePlayer: GamePlayer,
        season: Season,
    ): number;

    abstract reduce(scores: number[]): number;

    abstract getInitialScore(): number;
    abstract getStartingPoints(): number;

    getId() {
        return this.id;
    }

    formatScore(game: Game, gamePlayer: GamePlayer, season: Season): string {
        return "";
    }
}

class AverageScoringMethod extends ScoringMethod {
    getScore(game: Game, gamePlayer: GamePlayer, season: Season) {
        return gamePlayer.score;
    }

    reduce(scores: number[]) {
        if (scores.length === 0) {
            return 25000;
        }
        return Math.round(lodash.sum(scores) / scores.length);
    }

    getInitialScore() {
        return 25000;
    }

    getStartingPoints() {
        return 25000;
    }
}

class StandardScoringMethod extends ScoringMethod {
    getScore(game: Game, gamePlayer: GamePlayer, season: Season) {
        return (
            this.getBaseScore(game, gamePlayer) +
            this.getBonus(season, gamePlayer)
        );
    }

    reduce(scores: number[]) {
        return lodash.sum(scores);
    }

    getInitialScore() {
        return 0;
    }

    getStartingPoints() {
        return 25000;
    }

    getBaseScore(game: Game, gamePlayer: GamePlayer) {
        if (gamePlayer.placement === 1) {
            const total = game
                .getPlayers()
                .filter((player) => gamePlayer !== player)
                .map((p) => this.toFinalScore(p.score) - 30)
                .reduce((a, b) => a + b);
            return -total;
        }
        const score = this.toFinalScore(gamePlayer.score) - 30;
        return score;
    }

    getBonus(season: Season, gamePlayer: GamePlayer) {
        return season.getBonuses()[checkNotNull(gamePlayer.placement) - 1];
    }

    toFinalScore(score: number) {
        return Math.round(score / 1000);
    }

    formatScore(game: Game, gamePlayer: GamePlayer, season: Season) {
        return `${this.getBaseScore(game, gamePlayer)} + ${this.getBonus(
            season,
            gamePlayer,
        )}`;
    }
}

class SimpleScoringMethod extends ScoringMethod {
    getScore(game: Game, gamePlayer: GamePlayer, season: Season) {
        return (
            this.getBaseScore(game, gamePlayer) +
            this.getBonus(game, season, gamePlayer)
        );
    }

    reduce(scores: number[]) {
        return lodash.sum(scores);
    }

    getInitialScore() {
        return 0;
    }

    getStartingPoints() {
        return 30000;
    }

    getBaseScore(game: Game, gamePlayer: GamePlayer) {
        if (gamePlayer.placement === 1) {
            const total = game
                .getPlayers()
                .filter((player) => gamePlayer !== player)
                .map((p) => this.toFinalScore(p.score) - 30)
                .reduce((a, b) => a + b);
            return -total;
        }
        const score = this.toFinalScore(gamePlayer.score) - 30;
        return score;
    }

    getBonus(game: Game, season: Season, gamePlayer: GamePlayer) {
        // Handle ties
        const samePlacementCount = game.players.filter(
            (p) => p.placement === gamePlayer.placement,
        ).length;
        const bonusIndex = checkNotNull(gamePlayer.placement) - 1;
        return (
            season
                .getBonuses()
                .slice(bonusIndex, bonusIndex + samePlacementCount)
                .reduce((a, b) => a + b) / samePlacementCount
        );
    }

    toFinalScore(score: number) {
        return Math.round(score / 1000);
    }

    formatScore(game: Game, gamePlayer: GamePlayer, season: Season) {
        return `${this.getBaseScore(game, gamePlayer)} + ${this.getBonus(
            game,
            season,
            gamePlayer,
        )}`;
    }
}

export const AVERAGE = new AverageScoringMethod(1, "Average");
export const STANDARD = new StandardScoringMethod(2, "Standard");
export const SIMPLE_SCORING = new SimpleScoringMethod(3, "Simple Scoring");
export const ScoringMethods = [AVERAGE, STANDARD, SIMPLE_SCORING];
