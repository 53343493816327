import { Game } from "../models/Game";
import { Round } from "../models/Round";
import { Seat, Seats } from "../models/Seat";
import { GameParser } from "./GameParser";
import { HonbaCalculator } from "./HonbaCalculator";
import { PointTotalCalculator } from "./PointTotalCalculator";
import { RiichiCalculator } from "./RiichiCalculator";
import { RoundWindCalculator } from "./RoundWindCalculator";

export class GameCalculatorService {
    getRoundWind(game: Game, round?: Round | null) {
        const calc = new RoundWindCalculator(round);
        GameParser.parse(game, calc);
        return calc.getRoundWind();
    }

    getSeatWind(game: Game, round?: Round | null) {
        const calc = new RoundWindCalculator(round);
        GameParser.parse(game, calc);
        return calc.getSeatWind();
    }

    isDealer(game: Game, seat: Seat) {
        const calc = new RoundWindCalculator(null);
        GameParser.parse(game, calc);
        return calc.getSeatWind().getSeat() === seat;
    }

    getPlayerPoints(game: Game, seat: Seat) {
        const calc = new PointTotalCalculator();
        GameParser.parse(game, calc);
        return calc.getPointsForSeat(seat);
    }

    getTotalPoints(game: Game) {
        return Seats.all()
            .map((s) => this.getPlayerPoints(game, s))
            .reduce((a, b) => a + b, 0);
    }

    getRiichiPotCount(game: Game) {
        return GameParser.parse(game, new RiichiCalculator()).getRiichiCount(
            game,
        );
    }

    getHonbaCount(game: Game) {
        return GameParser.parse(game, new HonbaCalculator()).getHonba();
    }
}
