import React from "react";
import { PageTitle } from "../../common/PageTitle";
import { Panel } from "../../common/Panel";
import { Player } from "../../games/models/Player";
import { PlayerList } from "../../games/PlayerList";
import { SeasonGameList } from "../../http/SeasonGameList";
import { PlayerStatisticsService } from "../../player/PlayerStatisticsService";
import { Season } from "../../seasons/Season";
import { SeasonSelector } from "../../seasons/SeasonSelector";
import { GamePlayerStat } from "../../stats/GamePlayerStat";
import { Callback } from "../../util/Callback";
import { PlayerStatistic } from "../PlayerStatistic";
import { CompareTable } from "./CompareTable";
import { StatSelector } from "./StatSelector";

export function Compare({
    stat,
    seasons,
    currentSeason,
    statOptions,
    players,
    games,
    onSelectStat,
}: {
    statOptions: GamePlayerStat[];
    stat: string;
    seasons: Season[];
    currentSeason: Season;
    players: PlayerList;
    games: SeasonGameList;
    onSelectStat: Callback<string>;
}) {
    const playerStatisticService = new PlayerStatisticsService();
    function calculateStats() {
        const selection =
            statOptions.find(s => s.getId() == stat) || statOptions[0];
        return players
            .getAll()
            .filter((p: Player) =>
                playerStatisticService.hasGames(games.inOrder(), p),
            )
            .map(
                (p: Player) =>
                    new PlayerStatistic(
                        p,
                        selection.getStat(games.inOrder(), p),
                    ),
            )
            .sort(PlayerStatistic.sortDescending());
    }

    return (
        <div className="compare">
            <PageTitle>Compare Stats</PageTitle>
            <Panel title="Compare Stats" icon="fa-balance-scale">
                <SeasonSelector seasons={seasons} season={currentSeason} />
                <StatSelector
                    options={statOptions}
                    selected={stat}
                    onChange={onSelectStat}
                />
                <CompareTable stats={calculateStats()} season={currentSeason} />
            </Panel>
        </div>
    );
}
