import lodash from "lodash";
import { Player } from "./models/Player";

export class PlayerRanking {
    static sortByScore(rankings: PlayerRanking[]) {
        return lodash.sortBy(rankings, r => -r.getScore());
    }

    constructor(
        private player: Player,
        private score: number,
        private count: number,
    ) {}

    getPlayer() {
        return this.player;
    }

    getScore() {
        return this.score;
    }

    getCount() {
        return this.count;
    }
}
