import React from "react";
import { Colors } from "./Colors";
import { Group } from "./Group";
import { GroupDimensions } from "./GroupDimensions";
import { Scale } from "./Scale";

export class BarGroup extends React.Component<{
    group: Group;
    scale: Scale;
    groupDimensions: GroupDimensions;
    onMouseLeave?: () => void;
    onMouseEnter?: (p: { x: number; y: number }) => void;
}> {
    renderGroup(label: string, index: number) {
        const height = Math.max(
            this.props.scale.scale(this.props.group.getValue(label)),
            1,
        );
        return (
            <rect
                className="bar-group__value"
                key={label}
                width={this.props.groupDimensions.getBarWidth()}
                height={height}
                x={this.props.groupDimensions.getX(index)}
                y={this.getMaxHeight() - height}
                fill={Colors.forIndex(index)}
            />
        );
    }

    getMaxHeight() {
        return this.props.scale.getOutputMax();
    }

    getHitAreaWidth() {
        return this.props.groupDimensions.getBarsWidth(
            this.props.group.getLabels().length,
        );
    }

    getCenter() {
        const count = this.props.group.getLabels().length;
        return this.props.groupDimensions.getCenterX(count);
    }

    onMouseEnter(e: React.MouseEvent<SVGGElement>) {
        if (this.props.onMouseEnter) {
            const rect = e.currentTarget.getBoundingClientRect();
            const position = {
                x: window.scrollX + rect.left + rect.width / 2,
                y: window.scrollY + rect.bottom,
            };
            this.props.onMouseEnter(position);
        }
    }

    onMouseLeave() {
        if (this.props.onMouseLeave) {
            this.props.onMouseLeave();
        }
    }

    renderHitArea() {
        return (
            <rect
                className="bar-group__hit-area"
                height={
                    this.getMaxHeight() +
                    this.props.groupDimensions.getLabelAreaHeight()
                }
                width={this.getHitAreaWidth()}
                x={this.props.groupDimensions.getMargin()}
            />
        );
    }

    render() {
        return (
            <g
                className="bar-group"
                onMouseEnter={e => this.onMouseEnter(e)}
                onMouseLeave={() => this.onMouseLeave()}
            >
                {this.renderHitArea()}
                {this.props.group
                    .getLabels()
                    .map((l, index) => this.renderGroup(l, index))}
                <text
                    className="bar-group__label"
                    x={this.getCenter()}
                    y={this.getMaxHeight()}
                    dy="1.5em"
                >
                    {this.props.group.getLabel()}
                </text>
            </g>
        );
    }
}
