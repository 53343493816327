import React from "react";
import { useData } from "../../app/Data";
import { usePlayers } from "../../player/Players";
import { useCurrentSeason } from "../../seasons/SeasonsContext";
import { useGamesService } from "../../services/ServiceContext";
import { GamesDraftList } from "../components/GamesDraftList";

export function DraftGamesListPage() {
    const gamesService = useGamesService();

    const players = usePlayers();
    const season = useCurrentSeason();
    const games = useData(() => gamesService.listDrafts());

    if (players == null || season == null || games == null) {
        return null;
    }

    return (
        <GamesDraftList
            players={players}
            currentSeason={season}
            games={games}
        />
    );
}
