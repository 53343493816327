import React, { useState } from "react";
import { Redirect } from "react-router";
import { TextInput } from "../../common/controls/TextInput";
import { PageTitle } from "../../common/PageTitle";
import { Panel } from "../../common/Panel";
import { Button } from "../../components/Button";
import { Player } from "../../games/models/Player";
import { usePlayerService } from "../../services/ServiceContext";

export function NewPlayerPage() {
    const [player, setPlayer] = useState(Player.emptyPlayer());
    const [updated, setUpdated] = useState(false);

    const playerService = usePlayerService();

    async function save() {
        await playerService.create(player);
        setUpdated(true);
    }

    if (!player) {
        return null;
    }
    if (updated) {
        return <Redirect to="/players" />;
    }
    return (
        <div className="new-player-page">
            <PageTitle>New Player</PageTitle>
            <Panel title="New Player" icon="fa-user">
                <div className="form">
                    <TextInput
                        value={player.getName()}
                        label="Name"
                        onChange={(n) => setPlayer(player.setName(n))}
                    />
                    <Button onClick={() => save()}>Save</Button>
                </div>
            </Panel>
        </div>
    );
}
