import { DataClass } from "../DataClass";
import { PaymentSignType } from "./PaymentSign";

export type Payments = ReadonlyArray<Payment>;

export class Payment extends DataClass<Payment> {
    static createDefault() {
        return new Payment(0, 0);
    }

    static createList(): Payments {
        return [
            Payment.createDefault(),
            Payment.createDefault(),
            Payment.createDefault(),
            Payment.createDefault(),
        ];
    }

    constructor(
        readonly payment: number,
        readonly payment_sign: PaymentSignType,
    ) {
        super();
    }

    getPayment() {
        return this.payment;
    }

    getPaymentSign(): PaymentSignType {
        return this.payment_sign;
    }
}
