import React from "react";
import { Statistic } from "../stats/Statistic";

export class LabelledStat extends React.Component<{ stat: Statistic }> {
    render() {
        return (
            <dl className="labelled-stat">
                <dt>{this.props.stat.getLabel()}</dt>
                <dd>{this.props.stat.formatValue()}</dd>
            </dl>
        );
    }
}
