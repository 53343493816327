import React from "react";
import { PlayerLink } from "../../games/components/PlayerLink";
import { Season } from "../../seasons/Season";
import { checkNotNull } from "../../util/Nullable";
import { PlayerStatistic } from "../PlayerStatistic";

export class CompareTable extends React.Component<{
    stats: PlayerStatistic[];
    season: Season;
}> {
    render() {
        return (
            <div className="compare-table">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Player</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.stats.map(stat => (
                            <tr key={checkNotNull(stat.getId())}>
                                <td>
                                    <PlayerLink
                                        player={stat.getPlayer()}
                                        season={this.props.season}
                                    />
                                </td>
                                <td>{stat.getStat().formatValue()}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }
}
