export class PointResult {
    constructor(
        public positive: number,
        public zero: number,
        public negative: number,
    ) {}

    getResultForSign(sign: number) {
        switch (sign) {
            case -1:
                return this.negative;
            case 0:
                return this.zero;
            case 1:
                return this.positive;
            default:
                throw new Error(`Invalid sign ${sign}`);
        }
    }

    addRiichi(count: number) {
        this.positive += 1000 * count;
    }
}
