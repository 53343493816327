import * as _ from "lodash";
import React from "react";
import { SelfLink } from "../../components/SelfLink";
import { Season } from "../../seasons/Season";
import { checkNotNull } from "../../util/Nullable";

interface PaginationProps {
    count: number;
    current: number;
    season: Season;
}

export class Pagination extends React.Component<PaginationProps, {}> {
    getPages() {
        return _.range(1, Math.ceil(this.props.count / 10) + 1);
    }

    getClassName(i: number) {
        if (i === this.props.current) {
            return "active";
        }
        return "";
    }

    render() {
        return (
            <div className="pagination">
                <nav>
                    <ul className="pagination">
                        <li>
                            <SelfLink
                                values={{
                                    season: checkNotNull(
                                        this.props.season.getId(),
                                    ),
                                    page: String(
                                        Math.max(1, this.props.current - 1),
                                    ),
                                }}
                            >
                                &laquo;
                            </SelfLink>
                        </li>
                        {this.getPages().map(p => (
                            <li key={p} className={this.getClassName(p)}>
                                <SelfLink
                                    values={{
                                        season: checkNotNull(
                                            this.props.season.getId(),
                                        ),
                                        page: String(p),
                                    }}
                                >
                                    {p}
                                </SelfLink>
                            </li>
                        ))}
                        <li>
                            <SelfLink
                                values={{
                                    season: checkNotNull(
                                        this.props.season.getId(),
                                    ),
                                    page: String(
                                        Math.min(
                                            this.getPages().length,
                                            this.props.current + 1,
                                        ),
                                    ),
                                }}
                            >
                                &raquo;
                            </SelfLink>
                        </li>
                    </ul>
                </nav>
            </div>
        );
    }
}
