import { stringify } from "querystring";
import React from "react";
import { Link } from "react-router-dom";

interface SelfLinkProps {
    values: { [key: string]: string | number };
}

export class SelfLink extends React.Component<SelfLinkProps, {}> {
    getLink() {
        return "?" + stringify(this.props.values);
    }

    render() {
        return <Link to={this.getLink()}>{this.props.children}</Link>;
    }
}
