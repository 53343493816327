import { ISeason, parseSeason } from "../../../../server/src/models/Season";
import { AVERAGE } from "../../seasons/ScoringMethod";
import { Season } from "../../seasons/Season";

export class SeasonSerializer {
    static toJSON(season: Season): ISeason {
        return {
            id: season.id,
            first_bonus: season.first_bonus,
            second_bonus: season.second_bonus,
            third_bonus: season.third_bonus,
            fourth_bonus: season.fourth_bonus,
            name: season.name,
            scoring_method: season.scoring_method,
            rules: season.rules.slice(),
        };
    }
    static fromJSON(data: any): Season {
        const seasonData = parseSeason(data);
        return new Season(
            seasonData.id ?? null,
            seasonData.name,
            seasonData.scoring_method ?? AVERAGE.id,
            seasonData.first_bonus ?? 0,
            seasonData.second_bonus ?? 0,
            seasonData.third_bonus ?? 0,
            seasonData.fourth_bonus ?? 0,
            seasonData.rules,
        );
    }
}
