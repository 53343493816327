import React from "react";
import { Game } from "../models/Game";

export class GameDate extends React.Component<{ game: Game }> {
    render() {
        const completed = this.props.game.getCompletedAt();
        if (completed) {
            return (
                <div>
                    <div>{completed.format("lll")}</div>({completed.fromNow()})
                </div>
            );
        }
        return <div>Date unavailable</div>;
    }
}
